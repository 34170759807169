<div class="example-box" cdkDrag>
    <div class="modal-header" style="justify-content: space-between;">
        <h4 class="modal-title">Add Saved Search to {{widgetName}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row mb-3">
            <label class="col-sm-3">Searches</label>
            <div class="col-sm-9">
                <ng-select placeholder="Select" [multiple]="true" (change)="selectionChange($event)" [items]="savedSearches" bindLabel="name"
                    bindValue="id">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="modal-footer" style="justify-content: space-between;">
        <button type="button" class="btn btn-orange" (click)="activeModal.close('Close click')">Cancel</button>
        <button type="submit" class="blue-button" (click)="save()">Save</button>
    </div>
</div>
