import * as moment from 'moment'

export class Utility {
   static getDateFromDateTime(date: Date) {
        let d = moment(date);
        let hoursDiff = - new Date().getTimezoneOffset() / 60;
        let minutesDiff = - new Date().getTimezoneOffset() % 60;
        d.set({
            hour: hoursDiff,
            minute: minutesDiff,
            second: 0,
            millisecond: 0
        });
        var v1 = d.toDate();
        return v1.toJSON();
    }
}

