import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layouts/layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { UnauthorizedComponent } from './account/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'pages',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'automations',
    redirectTo: 'workflow-definitions'
  },
  {
    path: 'workflow-definitions',
    canActivate: [AuthGuard],
    loadChildren: () => import('./automations/automations.module').then(mod => mod.AutomationsModule),
  },
  {
    path: 'workflow-instances',
    canActivate: [AuthGuard],
    loadChildren: () => import('./automations/automations.module').then(mod => mod.AutomationsModule)
  },
  {
    path: 'workflow-registry',
    canActivate: [AuthGuard],
    loadChildren: () => import('./automations/automations.module').then(mod => mod.AutomationsModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
