import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { IClient } from '../../core/models/clients.models';
import { ITask } from '../../core/models/tasks.models';
import { AddTaskModal } from '../../shared/modals/add-task/add-task.component';
import { AppService } from '../../store/app.service';
import { TasksApiService } from './tasks.apiservice';

@Injectable()
export class TasksService {

    public activeClient: IClient = {} as IClient;
    public onSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public onDelete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public onMarkAsComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public onReloadTaskData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private modalService: NgbModal,
        private appService: AppService,
        private taskApi: TasksApiService
    ) { }

    openEditTaskModal(evt: any, canEdit = true, canDelete = true) {
        const dialogRef = this.modalService.open(AddTaskModal);
        dialogRef.componentInstance.iId = evt.id;
        dialogRef.componentInstance.iCanEdit = canEdit;
        dialogRef.componentInstance.iCanDelete = canDelete;
        dialogRef.componentInstance.iClientId = evt.clientId ? evt.clientId : null;
        dialogRef.componentInstance.iCampaignId = evt.campaignId ? evt.campaignId : null;
        const dialogSubmitSubscription = dialogRef.componentInstance.oSaveClicked.subscribe((r: ITask) => {
            this.taskApi.saveTask(r)
                .subscribe((response: any) => {
                    dialogRef.dismiss();
                    this.onSave.next(true);
                });
            dialogSubmitSubscription.unsubscribe();
        });
        const dialogDeleteSubscription = dialogRef.componentInstance.oDeleteClicked.subscribe((id: string) => {
            this.taskApi.deleteTask(id)
                .subscribe((response: any) => {
                    dialogRef.dismiss();
                    this.onDelete.next(true);
                })
            dialogDeleteSubscription.unsubscribe();
        });
        const dialogMarkSubscription = dialogRef.componentInstance.oMarkAsCompleteClicked.subscribe((r: any) => {
            this.taskApi.markTask(r)
                .subscribe((response: any) => {
                    dialogRef.dismiss();
                    this.onMarkAsComplete.next(true);
                })
            dialogMarkSubscription.unsubscribe();
        });
    }

    ReloadTaskData(evt: any){
        this.onReloadTaskData.next(true);
    }
}
