import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ROLES } from '../../../core/helpers/constants';
import { IClient } from '../../../core/models/clients.models';
import { ICompany, ISalesRep } from '../../../core/models/datafeed.models';
import { DataFeedService } from '../../api/datafeed.service';
import { ClientsApiService } from '../../../pages/clients/clients.apiservice';

@Component({
  selector: 'app-merge-clients',
  templateUrl: './merge-clients.component.html',
  styleUrls: ['./merge-clients.component.scss']
})
export class MergeClientsModal implements OnInit, AfterViewInit, OnDestroy {
  step = 1;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oMergeComplete = new EventEmitter<any>();
  @Input() set clientsToMerge(value: Array<IClient>) {
    this.clients = value;
  }
  clients: Array<IClient> = [];

  allCompanies: Array<ICompany> = new Array<ICompany>();
  allSalesReps: Array<ISalesRep> = new Array<ISalesRep>();
  allDms: Array<ISalesRep> = new Array<ISalesRep>();

  addClientForm!: FormGroup;
  submitted = false;
  constructor(
    private dataFeedService: DataFeedService,
    private clientApiService: ClientsApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder) {
    this.addClientForm = this.formBuilder.group({
      id: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      brandAddress: [null, [Validators.required]],
      brandId: [null],
      dmsId: [null],
      salesRepId: [null],
      phone: [null],
      email: [null],
    });

  }
  get f() { return this.addClientForm.controls; }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.dataFeedService.getAllCompanies()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.good) {
          this.allCompanies = [...response.data];
          this.allCompanies.sort((s1, s2) => s1.name.localeCompare(s2.name));
        }
      });

    this.dataFeedService.getAllUsersWithRole(ROLES.SalesRep.Code)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.good) {
          this.allSalesReps = [...response.data];
          // this.addClientForm.patchValue({ brandId: response.data[0] });
        }
      });

    this.dataFeedService.getAllUsersWithRole(ROLES.DMS.Code)
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allDms = [...response.data];
          // this.addClientForm.patchValue({ brandId: response.data[0] });
        }
      });
  }

  /*
  setPrimaryRecord(id: string) {
    let selectedClient = this.clients.filter(f => f.id === id)[0];
    this.addClientForm.patchValue(selectedClient);
  }
  */
  setPrimaryRecord(id: string) {
    const selectedClient = this.clients.find(client => client.id === id);
    if (selectedClient) {
      this.addClientForm.patchValue({
        ...selectedClient,
        isProspect: selectedClient.isProspect // Retain the isProspect value
      });
    }
  }

  refreshValues() {
    //   this.addClientForm.get("firstname").setValue("Saurav")
  }

  /*
  onSubmit() {
    Swal.fire({
      title: 'Are you sure you want to merge these records?',
      text: "You won't be able to revert this action!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, merge them!'
    }).then((result) => {
      if (result.isConfirmed) { 

        var primaryClient = this.addClientForm?.get("id")?.value;
        var payload = this.addClientForm.value;
        var clientIdsToMerge = this.clients.map(m => m.id);

        this.clientApiService.mergeClients(payload, primaryClient, clientIdsToMerge)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            if (response.good) {
              Swal.fire(
                'Merge successful!',
                'Client details have been successfully merged!',
                'success'
              )
 
              this.activeModal.dismiss(response)
              this.oMergeComplete.next(response);
            }
          }); 
      }
    });
  }
  */
  onSubmit() {
    Swal.fire({
      title: 'Are you sure you want to merge these records?',
      text: "You won't be able to revert this action!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, merge them!'
    }).then((result) => {
      if (result.isConfirmed) { 
        const primaryClient = this.addClientForm?.get("id")?.value;
        const isProspect = this.clients.some(client => client.isProspect); // Retain if any client is a prospect
        const payload = {
          ...this.addClientForm.value,
          isProspect // Add isProspect explicitly
        };
        const clientIdsToMerge = this.clients.map(client => client.id);
  
        this.clientApiService.mergeClients(payload, primaryClient, clientIdsToMerge)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            if (response.good) {
              Swal.fire(
                'Merge successful!',
                'Client details have been successfully merged!',
                'success'
              );
              this.activeModal.dismiss(response);
              this.oMergeComplete.next(response);
            }
          }); 
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  nextStep() { this.step += 1; }
  previousStep() { this.step -= 1; }
}
