<div class="modal-box" cdkDrag>
    <form [formGroup]="addNoteForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <h4 class="modal-title">Add Note</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <label class="col-sm-3" for="title">Title</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="title"
                        [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Enter title">
                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required">title is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="description">Note</label>
                <div class="col-sm-9">
                    <textarea type="text" rows="3" class="form-control" formControlName="description"
                        [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                        placeholder="Enter note"> </textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                        <div *ngIf="f.description.errors.required">note is required</div>
                    </div>
                </div>
            </div>
            <div *ngIf="!iActiveUserExternal || iIsAdmin" class="row mb-3">
                <label class="col-sm-3" for="description">Internal Only</label>
                <div class="col-sm-9">
                    <input type="checkbox" formControlName="isInternal" class="form-check-input" />
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-sm-3" for="assignedUserId">Author</label>
                <div class="col-sm-9">
                    <span *ngIf="f.assignedUserId.value">
                        {{ assignedUserFullname }}
                    </span>

                    <select *ngIf="!(f.assignedUserId.value)"
                        [ngClass]="{ 'is-invalid': submitted && f.assignedUserId.errors }" formControlName="assignedUserId"
                        class="form-control form-select select-custom">
                        <option *ngFor="let rep of allAssignees" [ngValue]="rep.id">{{rep.firstName}} {{rep.lastName}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.assignedUserId.errors" class="invalid-feedback">
                        <div *ngIf="f.assignedUserId.errors.required">please select an author</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="assignedUserId">Attach File</label>
                <div class="col-sm-9">
                    <span>
                        <ngx-dropzone style="height: 80px;" [multiple]="false" (change)="onSelect($event)">
                            <ngx-dropzone-label>Drop or click to upload!</ngx-dropzone-label>
                        </ngx-dropzone>
                        <div class="row mb-3" style="margin: 10px;" *ngFor="let file of files">
                            <div class="col-sm-9">{{ file.name }}</div>
                            <div class="col-sm-3">
                                <a role="button" (click)="onRemove(file)">
                                    <i class="fas fa-trash-can"></i>
                                </a>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Cancel</button>
            <button type="submit" class="blue-button">Save</button>
        </div>
    </form>
</div>