import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-search-fields',
  templateUrl: './new-search-fields.component.html',
  styleUrls: ['./new-search-fields.component.scss']
})
export class NewSearchFieldsComponent implements OnDestroy {
  rows: Array<any> = [];
  @Input() formGroup!: FormGroup;
  @Input() submitted!: boolean;
  @Input() set rowsInput(value: Array<any>) {
    if (value != undefined && value.length) {
      this.rows = value;
      this.addRows();
    }
  }
  @Input() set disabled(value: boolean) {
    if (value === true) {
      this.reset();
    }
  }
  get form() { return this.formGroup.controls; }

  addRows() {
    for (var i = 0; i < this.rows.length; i++) {
      var row = this.rows[i];
      this.addFormGroupRow(row);
    }
  }

  addFormGroupRow(row: any) {
    if (this.formGroup.contains(row.formControlName)) return;
    this.formGroup.addControl(`${row.formControlName}Operation`, new FormControl(row.operator.default));
    this.formGroup.addControl(row.formControlName, new FormControl(row.value.defaultValue ?? ''));
    if(row.operator.change){
      this.formGroup.controls[`${row.formControlName}Operation`].valueChanges.subscribe(selectedValue => {
        row.operator.change(selectedValue, row);
      })
    }
  }

  addNewRow(row: any) {
    let rowNameIndex = 1;
    while (this.rows.findIndex(x => x.formControlName === `${row.formControlName}_${rowNameIndex}`) > -1)
      rowNameIndex++;
    var index: number;
    if (rowNameIndex === 1)
      index = this.rows.findIndex(x => x.formControlName === row.formControlName);
    else
      index = this.rows.findIndex(x => x.formControlName === `${row.formControlName}_${rowNameIndex - 1}`);
    var newRow = { ...row, formControlName: `${row.formControlName}_${rowNameIndex}`, deletable: true, value: { ...row.value, clickOpens: false } };
    this.rows.splice(index + 1, 0, newRow);
    this.addFormGroupRow(newRow);
  }

  removeRow(row: any) {
    this.rows = this.rows.filter(x => x.formControlName !== row.formControlName);
    this.formGroup.removeControl(`${row.formControlName}Operation`);
    this.formGroup.removeControl(row.formControlName);
  }

  ngOnDestroy(): void {
    for (var i = 0; i < this.rows.length; i++) {
      var row = this.rows[i];
      this.formGroup.removeControl(`${row.formControlName}Operation`);
      this.formGroup.removeControl(row.formControlName);
    }
  }

  reset() {
    for (var i = 0; i < this.rows.length; i++) {
      var row = this.rows[i];
      this.formGroup.controls[`${row.formControlName}Operation`]?.setValue(row.operator.default);
      this.formGroup.controls[row.formControlName]?.setValue('');
    }
  }

  isDeletable = (row: any) => row.deletable === true;
}
