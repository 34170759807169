export const Constants = {
  LS_AUTHENTICATION_KEY: 'LS_AUTH_RM',
  LS_COMPANY_KEY: 'LS_COMPANY_RM',
  LS_ROOT_KEY: '_LS_ROOT_RM'
}
export const PAGE_KEYS = {
  Dashboard: "Dashboard",
  Clients: 'Clients'
}
export const ROLES_KEY = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
export const NOTES_TYPE = {
  Campaigns: 1,
  Clients: 2,
  Leads: 3
}
export const TAGS_TYPE = {
  Campaigns: 1,
  Clients: 2,
  Products: 3
}
export const ROLES_TYPE = {
  Internal: 1,
  External: 2
}
export const CART_STEPS = {
  BundleDetails: 1,
  RequiredInfo: 2,
  Cart: 3,
  Checkout: 4,
  Completed: 5
}
export const PAGE_NAMES = {
  MyAccount: 'MyAccount',
  Shop: 'Shop',
  Home: 'Home',
  Dashboard: 'Dashboard',
  Campaigns: 'Campaigns',
  CampaignsDetails: 'CampaignsDetails',
  EditCampaign: 'EditCampaign',
  Tasks: 'Tasks',
  Reports: 'Reports',
  Subscriptions: 'Subscriptions',
  SubscriptionsDetails: 'SubscriptionsDetails',
  Orders: 'Orders',
  OrdersDetails: 'Orders Details',
  PaymentMethods: 'PaymentMethods',
  Leads: 'Leads',
  LeadDetails: 'LeadDetails',
  Clients: 'Clients',
  ClientDetails: 'ClientDetails',
  Prospects: 'Prospects',
  ProspectsDetails: 'ProspectsDetails',
  Brands: 'Brands',
  Users: 'Users',
  Companies: 'Companies',
  Searches: 'SavedSearches',
  GlobalSearch: 'GlobalSerche',
  Products: 'Products',
  ProductsDetails: 'ProductsDetails',
  Tags: 'Tags',
  EditTag: 'EditTag',
  Coupons: 'Coupons',
  EditCoupon: 'EditCoupon',
  Emails: 'Emails',
  Settings: 'Settings',
  Automations: 'Automations',
  Forms: 'Forms'
}

// DO NOT CHANGE THE Code PROPERTY, It is matched in database
export const ROLES = {
  Admin: { Code: 1, IsInternal: true },
  TechServices: { Code: 2, IsInternal: true },
  DMS: { Code: 3, IsInternal: true },
  ShopManager: { Code: 4, IsInternal: true },
  CompanyOwner: { Code: 5, IsInternal: false, Company: { Required: true } },
  MarketingManager: { Code: 6, IsInternal: false, Company: { Required: true }, Client: { Required: false } },
  ClientOwner: { Code: 7, IsInternal: false, Client: { Required: true } },
  Employee: { Code: 8, IsInternal: false, Client: { Required: true }, Campaign: { Required: false } },
  SubscriptionEmployee: { Code: 9, IsInternal: false, Client: { Required: true }, Campaign: { Required: false } },
  SalesRep: { Code: 10, IsInternal: true },
  Staff: { Code: 12, IsInternal: true }
}
export const PRODUCT_TYPES = {
  SimpleProduct: { code: 1, title: 'Simple Product' },
  SimpleSubscription: { code: 2, title: 'Simple Subscription' },
  VariableSubscription: { code: 3, title: 'Variable Subscription' }
}
export const PRODUCT_FAMILY = {
  Bundle: 0,
  DigitalMarketing: 1,
  CampaignConsulting: 2,
  SEOProgram: 3,
  HostedWebsite: 4,
  SocialMedia: 5,
  AdSpend: 6,
  AddPlatform: 7,
}
export const TASK_PRIORITY = [{
  title: 'Critical',
  value: 1,
},
{
  title: 'Essential',
  value: 2,
},
{
  title: 'Non-Essential',
  value: 3,
}
]
// https://atlastechdev.atlassian.net/browse/REAC-59 - 2. Updates to fields: *Update the possible values of Stage to:
export const CAMPAIGN_STAGES = [
  {
    title: "Tech Setup",
    value: 1
  },
  {
    title: "Campaign Set-Up",
    value: 2
  },
  {
    title: "Client Approval",
    value: 3
  },
  {
    title: "QC",
    value: 4
  },
  {
    title: "Waiting to Start",
    value: 5
  },
  {
    title: "Active",
    value: 6
  },
  {
    title: "Paused",
    value: 7
  },
  {
    title: "Inactive",
    value: 8
  },
  {
    title: "Cancelling",
    value: 9
  },
]
export const COUPON_TYPES = [
  {
    title: "Fixed Amount",
    value: 1
  },
  {
    title: "Percentage",
    value: 2
  },
]

export const RESTRICTION_TYPES = [
  {
    title: "Cart",
    value: 1
  },
  {
    title: "Product",
    value: 2
  },
]

export const TRX_STATUS = {
  TransactionApproved: 1,
  TransactionDeclined: 2,
  Error: 3,
}
export const ORDER_STATUS = [
  { title: 'Pending', value: 0 },
  { title: 'PaymentPending', value: 1 },
  { title: 'Canceled', value: 2, },
  { title: 'Completed', value: 3, },
  { title: 'Fully Refunded', value: 4, },
  { title: 'Partially Refunded', value: 5, }
]

export const COUNTRY_LIST = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands"
];


