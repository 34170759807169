import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private host = environment.apiBaseUrl;
  constructor(
    private httpClient: HttpClient,
    public router: Router
  ) { }
  get<T>(endpoint: string, header = {}) {
    let requestBody = null;
    if (!_.isEmpty(header)) {
      requestBody = this.httpClient.get<T>(`${this.host}${endpoint}`, { ...header });
    } else {
      requestBody = this.httpClient.get<T>(`${this.host}${endpoint}`);
    }
    return requestBody;
  }

  post<T>(endpoint: string, payload: any, header = {}) {
    return this.httpClient.post<T>(`${this.host}${endpoint}`, payload, {
      headers: header,
    });
  }
  put(endpoint: string, payload: any, header = {}) {
    return this.httpClient.put(`${this.host}${endpoint}`, payload, {
      headers: new HttpHeaders(header),
    });
  }
  delete(endpoint: string, header = {}) {
    return this.httpClient.delete(`${this.host}${endpoint}`, {
      headers: new HttpHeaders(header),
    });
  }

 
  async getAsBlob(endpoint: string): Promise<Blob> { 
    return await this.httpClient.get(`${this.host}${endpoint}`, {
      responseType: "blob",
    }).pipe(map((file: Blob) => {
      return file;
    })).toPromise();
  }
  async getDownload(endpoint: string): Promise<ArrayBuffer> { 
    return await this.httpClient.get(`${this.host}${endpoint}`, {
      responseType: "arraybuffer",
    }).pipe(map((file: ArrayBuffer) => {
      return file;
    })).toPromise();
  }
  async postDownload(endpoint: string, body: any): Promise<ArrayBuffer> {
    return await this.httpClient.post(`${this.host}${endpoint}`, body, {
      responseType: "arraybuffer",
    }).pipe(map((file: ArrayBuffer) => {
      return file;
    })).toPromise();
  }
  async postDownloadWithSearch(endpoint: string, body: any): Promise<ArrayBuffer> {
    return await this.httpClient.post(`${this.host}${endpoint}`, body, {
      responseType: "arraybuffer",
    }).pipe(map((file: ArrayBuffer) => {
      return file;
    })).toPromise();
  }

  // put<T>(endpoint: string, payload: any, header = {}) {
  //   return this.httpClient.put<T>(`${this.host}${endpoint}`, payload, {
  //     headers: new HttpHeaders(header),
  //   });
  // }

  // delete<T>(endpoint: string, header = {}) {
  //   return this.httpClient.delete<T>(`${this.host}${endpoint}`, {
  //     headers: new HttpHeaders(header),
  //   });
  // } 
}
