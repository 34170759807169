import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createGuid } from '../../../core/helpers/utility';
import { INotes } from '../../../core/models/campaigns.model';
import { ISalesRep } from '../../../core/models/datafeed.models';
import { ITag } from '../../../core/models/tags.models';
import { TagsApiService } from '../../../pages/tags/tags.apiservice';
import { DataFeedService } from '../../api/datafeed.service';

@Component({
  selector: 'modal-assign-tag',
  templateUrl: './assign-tag.component.html',
  styleUrls: ['./assign-tag.component.scss']
})
export class AssignTagModal implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oSaveClicked = new EventEmitter<any>();

  allTags: Array<ITag> = new Array<ITag>();

  assignTagForm!: FormGroup;
  submitted = false;

  @Input() iClientId: string = '';
  @Input() iCampaignId: string = '';
  @Input() iProductId: string = '';
  @Input() iType: number = 1;
  @Input() iAssignedTags: Array<string> = new Array<string>();


  constructor(
    private dataFeedService: DataFeedService,
    private tagService: TagsApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder) {
    this.assignTagForm = this.formBuilder.group({
      id: [null],
      type: [null],
      campaignId: [null],
      clientId: [null],
      productId: [null],
      tagIds: ['', [Validators.required]],
    });
  }
  get f() { return this.assignTagForm.controls; }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.tagService.getAllTags(null)
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allTags = [...response.data.filter((x: any) => this.iAssignedTags.indexOf(x.id) == -1)];
        }
      });
  }
  onSubmit() {
    this.submitted = true;
    if (this.assignTagForm.invalid)
      return;
    if (this.f.id.value === null)
      this.f.id.setValue(createGuid())
    this.assignTagForm.patchValue({ campaignId: this.iCampaignId, clientId: this.iClientId, productId: this.iProductId, type: this.iType })

    this.oSaveClicked.emit(this.assignTagForm.value);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}