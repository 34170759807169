import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { PAGE_NAMES } from '../../core/helpers/constants';
import { IClient } from '../../core/models/clients.models';
import { AddClientModal } from '../../shared/modals/add-client/add-client.component';
import { AppService } from '../../store/app.service';
import { ClientsApiService } from './clients.apiservice';

@Injectable()
export class ClientsService {

    public activeClient: IClient = {} as IClient;

    constructor(
        private modalService: NgbModal,
        private appService: AppService,
        private clientService: ClientsApiService,
        private router: Router
    ) {

    }

    openEditClientModal(evt: any, request: any) {
        const dialogRef = this.modalService.open(AddClientModal, { size: 'lg' });
        if (evt.id === -1) {
            var d = this.appService.getActiveRoute$().value.url.split('/');
            evt.id = d[d.length - 1]
        }
        dialogRef.componentInstance.iId = evt.id;
        const dialogSubmitSubscription = dialogRef.componentInstance.oSaveClicked.subscribe((r: IClient) => {

            this.clientService.saveClient(r)
                .subscribe((response: any) => {
                    dialogRef.dismiss();
                    const sub = this.appService.getActivePage$()
                        .subscribe(page => {
                            if (page === PAGE_NAMES.Clients)
                                this.appService.fetchAllClients(request);
                            else if (page === PAGE_NAMES.ClientDetails)
                                this.appService.setRefreshSignal$(PAGE_NAMES.ClientDetails, r.id);
                            else if (page === PAGE_NAMES.ProspectsDetails)
                                this.appService.setRefreshSignal$(PAGE_NAMES.ProspectsDetails, r.id);
                        });
                        sub.unsubscribe();
                })
            dialogSubmitSubscription.unsubscribe();
        })
    }



    deleteClient() {
        var d = this.appService.getActiveRoute$().value.url.split('/');
        var cId = d[d.length - 1];
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.clientService.deleteClient([cId])
                    .subscribe((r: any) => {
                        if (r.good) {
                            this.router.navigate(['/pages/clients']);
                        }
                    })
            }
        })
    }

    deleteProspect() {
        var d = this.appService.getActiveRoute$().value.url.split('/');
        var cId = d[d.length - 1];
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.clientService.deleteClient([cId])
                    .subscribe((r: any) => {
                        if (r.good) {
                            this.router.navigate(['/pages/prospects']);
                        }
                    })
            }
        })
    }

}
