<form [formGroup]="formGroup" style="margin-top: 25px; margin-bottom: 25px;">
    <div class="row mb-3" style="align-items: center;" *ngFor="let row of rows">
        <label class="col-sm-2" style="margin-bottom: 0;" for="title">{{row.name}}</label>
        <div class="col-sm-2">
            <select [formControlName]="row.formControlName + 'Operation'" class="form-control form-select select-custom">
                <option *ngFor="let p of row.operator.options" [ngValue]="p.value">{{p.name}}</option>
            </select>
        </div>
        <div class="col-sm-3">
            <input *ngIf="row.value.type=='text'" type="text" class="form-control"
                [formControlName]="row.formControlName">
            <select *ngIf="row.value.type=='select'" [formControlName]="row.formControlName"
                class="form-control form-select select-custom">
                <option *ngFor="let p of row.value.options" [ngValue]="p.value">{{p.name}}</option>
            </select>
            <input *ngIf="row.value.type=='datePicker'" mwlFlatpickr [mode]="row.value.mode" [altInput]="true"
                [convertModelValue]="false" [altInputClass]="'datetime'" [clickOpens]="row.value.clickOpens"
                [formControlName]="row.formControlName" class="form-control" />

        </div>
        <div class="col-sm-1 add-row">
            <button *ngIf="!isDeletable(row)" (click)="addNewRow(row)" type="button"
                class="btn btn-outline-secondary btn-sm">
                +
            </button>
            <button *ngIf="isDeletable(row)" type="button" (click)="removeRow(row)"
                class="btn btn-outline-danger btn-sm">
                x
            </button>
        </div>
    </div>
</form>