import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { ReportsApiService } from '../reports.apiservice';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  reportUrlString = '';
  reportUrl = '' as SafeResourceUrl;

  constructor(private route: ActivatedRoute, private domSanitizer: DomSanitizer, private reportsService: ReportsApiService,) {
  }

  ngOnInit(): void {
    let accountID = this.route.snapshot.paramMap.get('accountID');
    let campaignID = this.route.snapshot.paramMap.get('campaignID');
    let platform = this.route.snapshot.paramMap.get('platform');
    let dateStart = this.route.snapshot.paramMap.get('dateStart');
    let dateStop = this.route.snapshot.paramMap.get('dateStop');
    let reachCampaignID = this.route.snapshot.paramMap.get('reachCampaignID');

    //console.log('form');   

    this.reportUrlString = `/Dashboard/AdsAccountNew?Dashboard=true`;
    // let url: string = 'https://reachme.co/AdsReports/AdsAccount?';
    this.reportUrlString += '&';
    if(reachCampaignID){
      this.reportUrlString += `ReachCampaignID=${reachCampaignID}`;
    }
    if (accountID) {
      accountID = accountID.replace(/\ /g, '').replace(/\-/g, '');
      if (reachCampaignID) { this.reportUrlString += '&'; }
      this.reportUrlString += `AccountID=${accountID}`;
    }
    if (campaignID) {
      if (reachCampaignID || accountID) { this.reportUrlString += '&'; }
      this.reportUrlString += `CampaignID=${campaignID}`;
    }
    if (platform) {
      if (reachCampaignID || accountID || campaignID) { this.reportUrlString += '&'; }
      this.reportUrlString += `Platform=${platform}`;
    }
    if (dateStart) {
      if (reachCampaignID || accountID || campaignID || platform) { this.reportUrlString += '&'; }
      this.reportUrlString += `DateStart=${dateStart}`;
    }
    if (dateStop) {
      if (reachCampaignID || accountID || campaignID || platform || dateStart) { this.reportUrlString += '&'; }
      this.reportUrlString += `DateStop=${dateStop}`;
    }

    console.log(this.reportUrlString);
    this.reportsService.getReportContentByUrl(this.reportUrlString)
      .subscribe(responseUrl => {
        console.log(responseUrl);
        document.querySelector('iframe')?.setAttribute("src", responseUrl);
        this.loadCompleted = true;
        //this.reportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(responseUrl.toString())
      });

    //this.reportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.reportUrlString);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadCompleted = false;
  onFrameLoadComplete() {
    if (this.reportUrl) {
      // console.log('Loading Complete');
      this.loadCompleted = true;
    }
    else {
      // console.log('Loading starting.');
    }
  }

  // @ViewChild('myIframe') iframe: any;
  printIframe() {
    console.log(this.reportUrlString);
    var printWindow = window.open(this.reportUrlString, '_blank');
    printWindow?.addEventListener('load', () => {
      // The new window has finished loading. You can now do something here, such as
      // accessing the DOM of the new window, or closing the window.

      printWindow?.print();
      printWindow?.close();

    }); 
  }

}

