import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NOTES_TYPE } from '../../../core/helpers/constants';
import { INotes, ICampaignCheck } from '../../../core/models/campaigns.model';
import { DataFeedService } from '../../api/datafeed.service';
import { AddNoteModal } from '../../modals/add-note/add-note.component';
import { AddCampaignCheckModal } from '../../modals/add-campaign-check/add-campaigncheck.component';
import { AppService } from '../../../store/app.service';
import { CampaignsApiService } from '../../../pages/campaigns/campaigns.apiservice';
import { DomSanitizer } from '@angular/platform-browser';
import { TasksService } from '../../../pages/tasks/tasks.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  //new
  pageNumber = 1;
  pageSize = 10;
  searchString = '';

  notesData: Array<INotes>;
  tempNotesData: Array<INotes>;
  permNotesData: Array<INotes>;
  @Input() iParentId: string = undefined!;
  @Input() iType: number = undefined!;
  @Input() iCanWrite: boolean = false;
  @Input() iCanDelete: boolean = false;
  @Input() iIsExternal: boolean = true;
  @Input() iIsAdmin: boolean = true;
  @Input() iHideActionColName: boolean = false;
  @Input() isGooglePlatform: boolean = false;


  constructor(
    private appService: AppService,
    private router: Router,
    private modalService: NgbModal,
    private campaignService: CampaignsApiService,
    private sanitizer: DomSanitizer,
    private tasksService: TasksService,
    private dataFeedService: DataFeedService
  ) {
    this.notesData = new Array<INotes>();
    this.tempNotesData = new Array<INotes>();
    this.permNotesData = new Array<INotes>();
  }

  SafeHtml(str: string) {
    return this.sanitizer.bypassSecurityTrustHtml(str);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.iParentId || changes.iType) && (this.iParentId && this.iType)) {
      this.appService.fetchAllNotes(this.iParentId, this.iType);
    }
  }

  ngOnInit(): void {

    this.appService.getAllNotes$()
      .pipe(
        takeUntil(this.destroyed$),
        //filter((d: any) => !!d && d.type === this.iType)
      )
      .subscribe((response: Array<INotes>) => {
        this.notesData = response;
        this.permNotesData = response;
        this.tempNotesData = this.notesData.slice(0, this.pageSize);
      });

  }

  isClientNotes() {
    return this.iType === NOTES_TYPE.Clients;
  }

  onOpenNote(
    id?: string) {
    const dialogRef = this.modalService.open(AddNoteModal);
    dialogRef.componentInstance.iActiveUserExternal = this.iIsExternal;
    dialogRef.componentInstance.isIsAdmin = this.iIsAdmin;
    if (this.iType == NOTES_TYPE.Campaigns)
      dialogRef.componentInstance.iCampaignId = this.iParentId;
    else if (this.iType == NOTES_TYPE.Clients)
      dialogRef.componentInstance.iClientId = this.iParentId;
    else if (this.iType == NOTES_TYPE.Leads)
      dialogRef.componentInstance.iLeadId = this.iParentId;
    dialogRef.componentInstance.iType = this.iType

    if (id !== null) {
      const thisNote = this.notesData.find(x => x.id === id);
      dialogRef.componentInstance.iNote = thisNote;
    }
    const dialogSubmitSubscription = dialogRef.componentInstance.oSaveClicked.subscribe((r: INotes) => {

      this.dataFeedService.saveNote(r)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          dialogRef.dismiss();
          this.appService.fetchAllNotes(this.iParentId, this.iType);
        })
      dialogSubmitSubscription.unsubscribe();
    })
    const dialogDisregard = dialogRef.componentInstance.oDisregardClicked.subscribe(() => {          
      dialogRef.dismiss();
      dialogDisregard.unsubscribe();
    });
  }

  onCampaignCheck(){
    const dialogRef = this.modalService.open(AddCampaignCheckModal);
    dialogRef.componentInstance.iActiveUserExternal = this.iIsExternal;
    dialogRef.componentInstance.isIsAdmin = this.iIsAdmin;
    dialogRef.componentInstance.iCampaignId = this.iParentId;
    if (this.iType == NOTES_TYPE.Campaigns)
      dialogRef.componentInstance.iCampaignId = this.iParentId;
    else if (this.iType == NOTES_TYPE.Clients)
      dialogRef.componentInstance.iClientId = this.iParentId;
    else if (this.iType == NOTES_TYPE.Leads)
      dialogRef.componentInstance.iLeadId = this.iParentId;
    dialogRef.componentInstance.iType = this.iType
    dialogRef.componentInstance.isGooglePlatform = this.isGooglePlatform;

    const dialogSubmitSubscription = dialogRef.componentInstance.oSaveClicked.subscribe((r: ICampaignCheck) => {

      this.dataFeedService.saveCampaignCheck(r)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          dialogRef.dismiss();
          this.appService.fetchAllNotes(this.iParentId, this.iType);
          this.tasksService.ReloadTaskData(null);
        })
      dialogSubmitSubscription.unsubscribe();
    })
  }

  onDeleteNote(id: string) {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        this.dataFeedService.deleteNote(id)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            this.appService.fetchAllNotes(this.iParentId, this.iType);
          })
      }
    })
  }

  onClientRowClick(evt: any) {
    this.router.navigate(['/pages/client-details', evt.id]);
  }

  getAttachmentUrl(id: any, filename: any) {
    this.dataFeedService.getNoteFileUrl(id, filename)
            .subscribe((response: any) => {
                if (response.good) {
                  this.downloadFile(response.data);
                }
            });
  }
  downloadFile(url: any) {
    const e = document.createElement('a');
    e.href = url;
    e.download = url.substr(url.lastIndexOf('/') + 1);
    document.body.appendChild(e);
    e.click();
    document.body.removeChild(e);
  }
  ngAfterViewInit() {

  }

  //new
  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.tempNotesData = this.notesData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
  }

  doNoteSearch() {
    var newSearchedNotesData = new Array<INotes>();
    this.permNotesData.forEach((note) => {
      if (note.assignedUserName != null) {
        if ((note.assignedUserName.toLowerCase().includes(this.searchString.toLowerCase()))) {
          newSearchedNotesData.push(note);
          return;
        }
      }
      if (note.description != null) {
        if ( (note.description.toLowerCase().includes(this.searchString.toLowerCase()))) {
          newSearchedNotesData.push(note);
          return;
        }
      }
      if (note.title != null) {
        if ((note.title.toLowerCase().includes(this.searchString.toLowerCase()))) {
          newSearchedNotesData.push(note);
          return;
        }
      }
      if (note.campaignName != null) {
        if ((note.campaignName.toLowerCase().includes(this.searchString.toLowerCase()))) {
          newSearchedNotesData.push(note);
          return;
        }
      }

    });
    this.notesData = newSearchedNotesData;
    this.tempNotesData = this.notesData.slice(0, this.pageSize);
  }
}