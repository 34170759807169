import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppService } from './store/app.service';
import { environment } from '../environments/environment';
import { GoogleAnalyticsService } from './analytics/googleAnalytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private appService: AppService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        appService.setActiveRoute$(val);
      }
    });
  }
  ngOnInit()
  {
    if (environment.production) {
      this.googleAnalyticsService.initialize();
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }
  title = 'ReachMe';
}
