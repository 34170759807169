<form [formGroup]="newSearchForm" style="margin-top: 25px; margin-bottom: 25px;">
    <div class="row mb-3" style="align-items: center;">
        <label class="col-sm-1" style="margin-bottom: 0;" for="title">Title</label>
        <div class="col-sm-4">
            <input type="text" class="form-control" formControlName="title"
                [ngClass]="{ 'is-invalid': submitted && form.title.errors }" placeholder="Enter Search Title">
            <div *ngIf="submitted && form.title.errors" class="invalid-feedback">
                <div *ngIf="form.title.errors.required">Title is required</div>
            </div>
        </div>
    </div>
    <div class="row mb-3" style="align-items: center;">
        <label class="col-sm-1" style="margin-bottom: 0;" for="resultType">Result Type</label>
        <div class="col-sm-4">
            <select [ngClass]="{ 'is-invalid': submitted && form.resultType.errors }"
                (change)="onResultTypeChange($any($event.target).value)" formControlName="resultType"
                class="form-control form-select select-custom">
                <option *ngFor="let p of allResultTypes">{{p}}</option>
            </select>
            <div *ngIf="submitted && form.resultType.errors" class="invalid-feedback">
                <div *ngIf="form.resultType.errors.required">Result Type is required</div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-sm-5">
            <div style="display: flex;">
                <div style="flex: 1;">
                </div>
                <div style="padding-right: 10px;">
                    <button type="button" class="btn btn-primary" style="width: 110px;"
                        (click)="saveClicked()">Save</button>
                </div>
                <div>
                    <button type="button" class="btn btn-secondary" style="width: 110px;"
                        (click)="cancelClicked()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="dv-custom-navs">
    <ul ngbNav #nav="ngbNav" style="flex-wrap: nowrap;" [destroyOnHide]="false" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1" [disabled]="campaignDisabled" *ngIf="campaignVisible">
            <a ngbNavLink>
                <i-feather name="globe"></i-feather><label [ngStyle]="{'color': (campaignDisabled) ? 'grey' : 'black'}">Campaign</label>
            </a>
            <ng-template ngbNavContent>
                <app-new-search-fields [formGroup]="newSearchForm" [rowsInput]="campaignColumns" [submitted]="submitted"
                    [disabled]="campaignDisabled"></app-new-search-fields>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" [disabled]="clientDisabled" *ngIf="clientVisible">
            <a ngbNavLink>
                <i-feather name="user"></i-feather><label [ngStyle]="{'color': (clientDisabled) ? 'grey' : 'black'}">Client</label>
            </a>
            <ng-template ngbNavContent>
                <app-new-search-fields [formGroup]="newSearchForm" [rowsInput]="clientsColumns" [submitted]="submitted"
                    [disabled]="clientDisabled"></app-new-search-fields>
            </ng-template>
        </li>
        <li [ngbNavItem]="3" [disabled]="leadDisabled" *ngIf="leadVisible">
            <a ngbNavLink>
                <i-feather name="bar-chart-2"></i-feather><label [ngStyle]="{'color': (leadDisabled) ? 'grey' : 'black'}">Lead</label>
            </a>
            <ng-template ngbNavContent>
                <app-new-search-fields [formGroup]="newSearchForm" [rowsInput]="leadColumns" [submitted]="submitted"
                    [disabled]="leadDisabled"></app-new-search-fields>
            </ng-template>
        </li>
        <li [ngbNavItem]="4" [disabled]="userDisabled" *ngIf="userVisible">
            <a ngbNavLink>
                <i-feather name="server"></i-feather><label [ngStyle]="{'color': (userDisabled) ? 'grey' : 'black'}">User</label>
            </a>
            <ng-template ngbNavContent>
                <app-new-search-fields [formGroup]="newSearchForm" [rowsInput]="userColumns" [submitted]="submitted"
                    [disabled]="userDisabled"></app-new-search-fields>
            </ng-template>
        </li>
        <li [ngbNavItem]="5" [disabled]="companyDisabled" *ngIf="companyVisible">
            <a ngbNavLink>
                <i-feather name="briefcase"></i-feather><label [ngStyle]="{'color': (companyDisabled) ? 'grey' : 'black'}">Company</label>
            </a>
            <ng-template ngbNavContent>
                <app-new-search-fields [formGroup]="newSearchForm" [rowsInput]="companyColumns" [submitted]="submitted"
                    [disabled]="companyDisabled"></app-new-search-fields>
            </ng-template>
        </li>
        <li [ngbNavItem]="6" [disabled]="prospectDisabled" *ngIf="prospectVisible">
            <a ngbNavLink>
                <i-feather name="server"></i-feather><label [ngStyle]="{'color': (prospectDisabled) ? 'grey' : 'black'}">Prospect</label>
            </a>
            <ng-template ngbNavContent>
                <app-new-search-fields [formGroup]="newSearchForm" [rowsInput]="prospectColumns" [submitted]="submitted"
                    [disabled]="prospectDisabled"></app-new-search-fields>
            </ng-template>
        </li>
        <li [ngbNavItem]="7" [disabled]="tagDisabled" *ngIf="tagVisible">
            <a ngbNavLink>
                <i-feather name="tag"></i-feather><label [ngStyle]="{'color': (tagDisabled) ? 'grey' : 'black'}">Tag</label>
            </a>
            <ng-template ngbNavContent>
                <app-new-search-fields [formGroup]="newSearchForm" [rowsInput]="tagColumns" [submitted]="submitted"
                    [disabled]="tagDisabled"></app-new-search-fields>
            </ng-template>
        </li>
        <li [ngbNavItem]="8" [disabled]="taskDisabled" *ngIf="tagVisible">
            <a ngbNavLink>
                <i-feather name="list"></i-feather><label [ngStyle]="{'color': (taskDisabled) ? 'grey' : 'black'}">Task</label>
            </a>
            <ng-template ngbNavContent>
                <app-new-search-fields [formGroup]="newSearchForm" [rowsInput]="taskColumns" [submitted]="submitted"
                    [disabled]="taskDisabled"></app-new-search-fields>
            </ng-template>
        </li>

    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>