<form [formGroup]="reportForm">
    <div class="row g-3 align-items-center">
        <div class="col-auto">
            <label class="mb-2">Please select report period:</label>
        </div>
        <div class="col-auto">
            <select class="form-select mb-2" aria-label="Please select period." (change)="onReportPeriodChange(t.value)"
                #t>
                <option selected disabled>Please select period.</option>
                <option type="button" ngbDropdownItem *ngFor="let p of reportPeriods"
                    [selected]="selectedReportPeriod==p.key">
                    {{ p.key }}
                </option>
            </select>
        </div>
        <div class="col-auto">
            <label class="sr-only" for="startDate">Start</label>
            <input type="date" [readonly]="datesReadonly" formControlName="startDate"
                class="form-control form-date date-custom mb-2"
                [ngClass]="{ 'is-invalid': reportformsubmitted && reportForm.controls.startDate.errors }" />

            <div *ngIf="reportformsubmitted && reportForm.controls.startDate.errors" class="invalid-feedback">
                <div *ngIf="reportForm.controls.startDate.errors.required">Please select period.</div>
            </div>
        </div>
        <div class="col-auto">
            <label class="mb-2">-</label>
        </div>
        <div class="col-auto">
            <label class="sr-only" for="stopDate">End</label>
            <input type="date" [readonly]="datesReadonly" formControlName="stopDate"
                class="form-control form-date date-custom mb-2"
                [ngClass]="{ 'is-invalid': reportformsubmitted && reportForm.controls.stopDate.errors }"
                formControlName="stopDate" />
            <div *ngIf="reportformsubmitted && reportForm.controls.stopDate.errors" class="invalid-feedback">
                <div *ngIf="reportForm.controls.stopDate.errors.required">Please select period.</div>
            </div>
        </div>
    </div>
</form>
<br/>
<h4>Will open a new tab to display raw campaigns data.</h4>
<app-special-datatable [iCanRead]="doWhat.canRead" [iCanWrite]="doWhat.canWrite" [iCanDelete]="doWhat.canDelete"
    [iColumns]="columns" [iData$]="campaignsData" [iShowActions]="true" [iShowExternalAction]="true"
    [iShowExport]="false" [iShowSelect]="false" [iShowEditAction]="false"
    [iShowDeleteAction]="false" (oShowExternal)="viewReportOnNewTab($event)">
</app-special-datatable>