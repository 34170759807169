<div class="modal-box" cdkDrag>
    <form [formGroup]="addLeadForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <h4 class="modal-title">{{ id === '' ? 'Add' :'Edit'}} Lead</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <label class="col-sm-3" for="firstName">First Name</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="firstName"
                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="Enter first name">
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">first name is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="lastName">Last Name</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="lastName"
                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" placeholder="Enter last name">
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">last name is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="email">Email</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="email"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Enter email address">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">email is required</div>
                        <div *ngIf="f.email.errors.email">email is invalid</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="campaignId">Campaign</label>
                <div class="col-sm-9">
                    <ng-select [ngClass]="{ 'is-invalid': submitted && f.campaignId.errors }" formControlName="campaignId">
                        <ng-option *ngFor="let x of allCampaigns" [value]="x.id">{{x.name}}</ng-option>
                    </ng-select>
                    <div *ngIf="submitted && f.campaignId.errors" class="invalid-feedback">
                        <div *ngIf="f.campaignId.errors.required">Campaign is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="phone">Phone</label>
                <div class="col-sm-9">
                    <input type="tel" class="form-control" formControlName="phone"
                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="Enter phone number">
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">phone number is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="referralId">Referral Id</label>
                <div class="col-sm-9">
                    <textarea type="text" class="form-control" rows="3" formControlName="referralId"
                        [ngClass]="{ 'is-invalid': submitted && f.referralId.errors }" placeholder="enter referral id"> </textarea>
                    <div *ngIf="submitted && f.referralId.errors" class="invalid-feedback">
                        <div *ngIf="f.referralId.errors.required">referral id is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="referralSource">Referral Source</label>
                <div class="col-sm-9">
                    <textarea type="text" class="form-control" rows="3" formControlName="referralSource"
                        [ngClass]="{ 'is-invalid': submitted && f.referralSource.errors }" placeholder="enter referral source"> </textarea>
                    <div *ngIf="submitted && f.referralSource.errors" class="invalid-feedback">
                        <div *ngIf="f.referralSource.errors.required">referral source is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; align-items: center;" cdkDragHandle>
            <button type="button" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Cancel</button>
            <button type="submit" class="blue-button">SAVE</button>
        </div>
    </form>
</div>