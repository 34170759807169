import { Injectable } from '@angular/core';
import { ICompany } from '../../core/models/datafeed.models';
import { HttpService } from '../../shared/http/base.http';
@Injectable()
export class CompanyApiService extends HttpService {
   getAllCompanies(savedSearchId: any) {
      const query = savedSearchId ? `?savedSearchId=${savedSearchId}` : '';
      return this.get<Array<ICompany>>('/companies/getAllCompanies' + query);
   }
   getCompanyDetails(id: any) {
      return this.get<ICompany>('/companies/getCompanyDetails?id=' + id)
   }
   saveCompany(data: ICompany) {
      return this.post('/companies/saveCompany', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   deleteCompany(data: Array<string>) {
      return this.post('/companies/deleteCompany', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
}
