import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrdersApiService } from '../../orders/orders.apiservice';

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})

export class OrderCompleteComponent implements OnInit {
  orderNumber: string = '';
  orderGood: boolean = true;
  orderId: string = '';
  clientEmail: string = '';
  nextPay: Date = {} as Date;
  constructor(
    private route: ActivatedRoute,
    private orderApiService: OrdersApiService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.orderGood = Boolean(JSON.parse(params.g));
      this.orderNumber = params.n;
      this.orderId = params.o;
      this.clientEmail = params.e; 
      this.nextPay = params.d; 
    });
  }

  async downloadInvoice() {
    this.orderApiService.getOrderInvoice(
        this.orderId
    ).then((x: any) => {
      var newBlob = new Blob([x], { type: "application/pdf" });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = "orderinvoice.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }

}
