import { DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { PagesModule } from './pages/pages.module';
import { LayoutsModule } from './layouts/layouts.module';

import { AuthGuard } from './core/guards/auth.guard';
import { JwtModule } from '@auth0/angular-jwt';
import * as util from '../app/core/helpers/utility';
import { ErrorService } from './core/helpers/app.error.service';
import { JwtInterceptor } from './core/guards/jwt.interceptor';
import { SortablejsModule } from 'ngx-sortablejs'
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule } from "ngx-ui-loader";
import { UnauthorizedComponent } from './account/unauthorized/unauthorized.component';
import { UtilityService } from './core/helpers/utility.service';
import { environment } from '../environments/environment';
import { ToastService } from './core/helpers/toast.service';
import { ToastsContainer } from './core/helpers/toasts-container.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { ProductCheckoutComponent } from './pages/shop/product-checkout/product-checkout.component';
import { CurrencyPipe } from '@angular/common';
import { NgxMatTagInputModule } from 'ngx-mat-tag-input';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#000000',
  pbColor: '#000000',
  fastFadeOut: true,
};
@NgModule({
  declarations: [AppComponent, UnauthorizedComponent, ToastsContainer],
  imports: [
    NgbModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: util.getAuthToken,
        allowedDomains: ['localhost:5001'],
        disallowedRoutes: [],
      },
    }),
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    LayoutsModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    //NgxUiLoaderRouterModule, //
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true, exclude: [
        `${environment.baseUrl}/api/GmailClient/Mailboxes?Detailed=true`,
        `${environment.baseUrl}/api/GmailClient/Archived/Summary`,
        `${environment.baseUrl}/api/products/getProductMedia`,
      ],
      excludeRegexp: [
        ".*\\/widget\\/value\\?widgetId=.*"
      ]
    }
    ),
    NgxSpinnerModule,
    NgxMatTagInputModule,
    MatChipsModule,
    MatIconModule,
    SortablejsModule.forRoot({ animation: 150 }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorService },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    AuthGuard,
    UtilityService,
    ToastService,
    CurrencyPipe
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
