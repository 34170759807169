<ng-template #popContent style="padding-top: 0.5rem;">
    <div class="row pb-1">
        <div class="col">
            <select class="form-control form-select select-custom" #selectedValue>
                <option value="" disabled selected>
                    {{ (CampaignOptions$ | async)?.length == 0 ? 'No options for selected platform and report type' : '' }}
                </option>
                <option *ngFor="let option of (CampaignOptions$ | async)" [value]="option.value">
                    {{ option.text }}
                </option>
            </select> 
        </div>
    </div>
    <div class="row pb-1">
        <div class="col">
            <a class="a-btn btn btn-outline-secondary" style="margin-right: 10px; cursor: pointer;"
                (click)="onSubmit(selectedValue.value); pg.close();">
                Add
            </a>
        </div>
    </div>  
</ng-template>

<a style="text-decoration: none; font-weight: bold; cursor: pointer; margin-right: 10px;" placement="top"
    [ngbPopover]="popContent" [popoverTitle]="'Select ' + Platform + (ReportType == 'Advanced' ? ' Campaign' : ' Platform Account')" [autoClose]="'outside'"
    #pg="ngbPopover">
    {{ Platform }}<i-feather name="plus"></i-feather>
</a> 
 

 