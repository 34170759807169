<!-- <div class="la-ball-spin-clockwise la-dark la-3x" *ngIf="!loadCompleted">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>    -->
  
<iframe #iframe style="height: 99vh;" width="100%" (load)="onFrameLoadComplete()"></iframe>  

<!-- <button *ngIf="loadCompleted" (click)="printIframe()">Print</button> -->
