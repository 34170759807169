import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ICompany } from '../../core/models/datafeed.models';
import { AddCompanyModal } from '../../shared/modals/add-company/add-company.component';
import { AppService } from '../../store/app.service';
import { CompanyApiService } from './company.apiservice';

@Injectable()
export class CompanyService {

    constructor(
        private modalService: NgbModal,
        private appService: AppService,
        private compService: CompanyApiService,
    ) {

    }

    openEditCompanyModal(evt: any) {
        const dialogRef = this.modalService.open(AddCompanyModal, { size: 'lg' });
        // if (evt.id === -1) {
        //     var d = this.appService.getActiveRoute$().value.url.split('/');
        //     evt.id = d[d.length - 1]
        // }
        dialogRef.componentInstance.iId = evt.id;
        const dialogSubmitSubscription = dialogRef.componentInstance.oSaveClicked.subscribe((r: ICompany) => {

            this.compService.saveCompany(r)
                .subscribe((response: any) => {
                    dialogRef.dismiss();
                    this.appService.fetchAllCompanies(null);
                })
            dialogSubmitSubscription.unsubscribe();
        })
    }

}
