<div fxLayout="column" fxLayoutAlign="space-between center" style="height: 100%">
    <div fxLayout="column" fxLayoutAlign="space-between center">
        <div style="height: 100px" fxLayout="row" fxLayoutAlign="center center">
            <img [src]="sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + product.media.blob)" style="height: inherit;">
        </div>
        <div style="margin-top:10px">
            <h4>{{product.display}}</h4>
        </div>
        <div>
            <p>{{product.description}}</p>
        </div>

        <div style="width: 100%" fxLayout="column" fxLayoutAlign="start start">
            <div *ngIf="product.type === PRODUCT_TYPES.SimpleSubscription.code">
                <label style="font-weight: bold" *ngIf="product.isGoogle && product.isGoogle">Includes Google</label>
            </div>
            <div *ngIf="product.type === PRODUCT_TYPES.SimpleSubscription.code">
                <label style="font-weight: bold" *ngIf="product.isFacebook && product.isFacebook">Includes Facebook</label>
            </div>
            <div *ngIf="product.type === PRODUCT_TYPES.SimpleSubscription.code">
                <label style="font-weight: bold" *ngIf="product.isTikTok && product.isTikTok">Includes TikTok</label>
            </div>
        </div>
    </div>
    <div>
        <strong *ngIf="product.type === PRODUCT_TYPES.SimpleProduct.code">{{product?.data?.price | currency: 'USD' : 'symbol' :
            '1.0-0'}}</strong>
        <strong *ngIf="product.type === PRODUCT_TYPES.SimpleSubscription.code">From {{product?.data?.price | currency:'USD' :
            'symbol' : '1.0-0'}} /{{product.data.duration}}</strong>
    </div>
</div>