import { Injectable } from '@angular/core';
import { ICoupon } from '../../core/models/coupon.models';
import { HttpService } from '../../shared/http/base.http';

@Injectable()
export class CouponsApiService extends HttpService {
   getAllCoupons(savedSearchId: any) {
      const query = savedSearchId ? `?savedSearchId=${savedSearchId}` : '';
      return this.get<Array<ICoupon>>('/coupons/getAllCoupons' + query);
   }
   getCouponDetails(id: any) {
      return this.get<ICoupon>('/coupons/getCouponDetails?id=' + id)
   }
   saveCoupon(data: ICoupon) {
      return this.post('/coupons/saveCoupon', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   deleteCoupon(ids: Array<string>) {
      return this.post('/coupons/deleteCoupon', ids, { 'Content-Type': 'application/json; charset=utf-8' });
   }
}
