<mat-card class="card" [id]="widget.id">
    <mat-card-title>
        <div>
            <div class="header">
                <div class="title">
                    <p *ngIf="!renameVisible">{{widget.name}}</p>
                    <input *ngIf="renameVisible" type="text" [(ngModel)]="newName">
                </div>
                <div class="seperator"></div>
                <div class="widget-actions" *ngIf="!editVisible">
                    <div *ngIf="!changeLayoutStarted">
                        <a role="button" (click)="edit()">
                            <i class="fas fa-edit fa-xs"></i>
                        </a>
                        <a role="button" (click)="refresh()">
                            <i class="fas fa-sync-alt fa-xs"></i>
                        </a>
                        <a role="button" (click)="removeWidget()">
                            <i class="fas fa-times fa-xs"></i>
                        </a>
                    </div>
                    <div *ngIf="!changeLayoutStarted">
                        <a role="button" (click)="renameWidget()" *ngIf="!renameVisible"
                            class="btn btn-xs btn-link text-info font-13" style="font-size: xx-small;">Rename</a>
                        <a role="button" (click)="completeRename()" *ngIf="renameVisible"
                            class="btn btn-xs btn-link text-info font-13" style="font-size: xx-small;">Save</a>
                        <a role="button" (click)="cancelRename()" *ngIf="renameVisible"
                            class="btn btn-xs btn-link text-info font-13" style="font-size: xx-small;">Cancel</a>
                    </div>
                    <div *ngIf="changeLayoutStarted">
                        <i class="fas fa-bars fa-xs draghandler" style="cursor: move;"></i>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div [sortablejs]="widget.items" [sortablejsOptions]="getSortableOptions(widget)" style="padding-top: 15px;">
            <ng-container *ngFor="let item of widget.items;">
                <div [id]='item.id'>
                    <hr style="margin-top: 5px;">
                    <div class="item" [class.item-delete]="isItemToBeDeleted(item.id)">
                        <p (click)="itemClicked(item)" class="name">{{item.name}}</p>
                        <p class="namevalueseperator"></p>
                        <i class="fa-solid fa-spinner fa-spin" *ngIf="item.value === ''" style="font-size: 17px;"></i>
                        <p (click)="itemClicked(item)" *ngIf="item.value !== ''" class="value">{{item.value}}</p>
                        <a role="button" *ngIf="editVisible" (click)="addItemToRemoveList(item.id)">
                            <i class="fas fa-trash-alt fa-xs"></i>
                        </a>
                        <div class="moveitem" *ngIf="changeLayoutStarted">
                            <i class="fas fa-bars fa-xs itemdraghandler" style="cursor: move;"></i>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="d-flex justify-content-end" *ngIf="!editVisible">
            <a (click)="addItem()" role="button" class="btn btn-xs btn-link text-info font-13"
                style="font-size: xx-small;">Add Another Stat</a>
        </div>
        <div class="d-flex justify-content-end">
            <a role="button" (click)="completeEdit()" *ngIf="editVisible" class="btn btn-xs btn-link text-info font-13"
                style="font-size: xx-small;">Save</a>
            <a role="button" (click)="cancelEdit()" *ngIf="editVisible" class="btn btn-xs btn-link text-info font-13"
                style="font-size: xx-small;">Cancel</a>
        </div>
    </mat-card-content>
</mat-card>