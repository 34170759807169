import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartWizardComponent } from './cart-wizard/cart-wizard.component';
import { OrderCompleteComponent } from './order-complete/order-complete.component';
import { ProductsListComponent } from './products-list/products-list.component';



const routes: Routes = [
  {
    path: '',
    component: ProductsListComponent
  },
  {
    path: 'cart/:id',
    component: CartWizardComponent
  },
  {
    path: 'ordercomplete/:g/:n/:o',
    component: OrderCompleteComponent
  },
  {
    path: 'ordercomplete/:g/:n/:o/:e/:d',
    component: OrderCompleteComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
