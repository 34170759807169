<form [formGroup]="reportForm" (submit)="onFormSubmit()">
    <div *ngIf="!isEmptyCompanyGuid" class="row g-3 align-items-center mb-3">
        <label class="col-2 mb-2">Company Name</label>
        <div class="col-8">{{ companyData.name }}</div>
    </div>
    <div class="row g-3 align-items-center mb-3">
        <label class="col-2 mb-2">Please select report period</label>
        <div class="col-auto">
            <select class="form-select mb-2" aria-label="Please select period." (change)="onReportPeriodChange(t.value)"
                #t>
                <option selected disabled>Please select period.</option>
                <option type="button" ngbDropdownItem *ngFor="let p of reportPeriods"
                    [selected]="selectedReportPeriod==p.key">
                    {{ p.key }}
                </option>
            </select>
        </div>
        <div class="col-auto">
            <label class="sr-only" for="startDate">Start</label>
            <input type="date" [readonly]="datesReadonly" formControlName="startDate"
                class="form-control form-date date-custom mb-2"
                [ngClass]="{ 'is-invalid': reportformsubmitted && reportForm.controls.startDate.errors }" />

            <div *ngIf="reportformsubmitted && reportForm.controls.startDate.errors" class="invalid-feedback">
                <div *ngIf="reportForm.controls.startDate.errors.required">Please select period.</div>
            </div>
        </div>
        <div class="col-auto">
            <label class="mb-2">-</label>
        </div>
        <div class="col-auto">
            <label class="sr-only" for="stopDate">End</label>
            <input type="date" [readonly]="datesReadonly" formControlName="stopDate"
                class="form-control form-date date-custom mb-2"
                [ngClass]="{ 'is-invalid': reportformsubmitted && reportForm.controls.stopDate.errors }"
                formControlName="stopDate" />
            <div *ngIf="reportformsubmitted && reportForm.controls.stopDate.errors" class="invalid-feedback">
                <div *ngIf="reportForm.controls.stopDate.errors.required">Please select period.</div>
            </div>
        </div>
    </div>
    <div class="row g-3 align-items-center mb-3">
        <label class="col-2 mb-2">Platforms</label>
        <div class="col-auto">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" value="All" name="platforms" formControlName="platforms"
                    id="AllPlatforms" checked (change)="handlePlatformsChange($event, 'All')">
                <label class="form-check-label" for="AllPlatforms">
                    All
                </label>
            </div>
            <div *ngFor="let platform of platforms" class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [value]="platform" name="platforms"
                    formControlName="platforms" id="platform_{{ platform }}"
                    (change)="handlePlatformsChange($event, platform)">
                <label class="form-check-label" for="platform_{{ platform }}">
                    {{ platform }}
                </label>
            </div>
        </div>
    </div>
    <div class="row g-3 align-items-center mb-3">
        <label class="col-2 mb-2">Aggregation</label>
        <div class="col-auto">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" value="All" name="aggregation"
                    formControlName="aggregation" id="AggregateAll" checked
                    (change)="handleAggregateChange($event, false)">
                <label class="form-check-label" for="AggregateAll">
                    All
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" value="SelectCampaigns" name="aggregation"
                    formControlName="aggregation" id="SelectCampaigns" (change)="handleAggregateChange($event, true)">
                <label class="form-check-label" for="SelectCampaigns">
                    Select Campaigns
                </label>
            </div>
        </div>
    </div>
    <div class="row g-3 align-items-center mb-3">
        <label class="col-2 mb-2">Report format</label>
        <div class="col-auto">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" value="html" name="reportFormat"
                    formControlName="reportFormat" id="reportFormatHtml" (change)="handleReportFormatChange($event, 'html')">
                <label class="form-check-label" for="reportFormatHtml">
                    Html
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" value="pdf" name="reportFormat"
                    formControlName="reportFormat" id="reportFormatPdf" (change)="handleReportFormatChange($event, 'pdf')">
                <label class="form-check-label" for="reportFormatPdf">
                    Pdf
                </label>
            </div>
        </div>
    </div>
    <div class="row g-3 align-items-center mb-3">
        <label class="col-2 mb-2"></label>
        <div class="col-auto">
            <button type="submit" class="blue-button">View Rollup Report</button>
        </div>
        <div class="col-auto" *ngIf="dataLoadCompleted">
            <a (click)="openHtmlReportNewWindow()" class="btn btn-info">Open report on new page <i class="fas fa-external-link-alt ps-2"></i></a>
        </div>
        <div class="col-auto" *ngIf="dataLoadCompleted">
            <a (click)="openPdfReportNewWindow()" class="btn btn-info">Open report as pdf <i class="fas fa-external-link-alt ps-2"></i></a>
        </div>
    </div>
</form>
<div *ngIf="SelectCampaignsError" class="text-danger">
    {{SelectCampaignsError}}
</div>
<div class="dv-custom-navs">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" (activeIdChange)="onTabChange($event)">
        <li [ngbNavItem]="1">
            <a ngbNavLink>
                <i-feather name="check"></i-feather><label>Campaigns</label>
            </a>
            <ng-template ngbNavContent>
                <app-special-datatable [iShowDropdownAdd]="false" [iShowSelect]="(bShowSelect$ | async) ?? false"
                    [iShowActions]="false" [iShowExport]="false" [iColumns]="columns" [iCanRead]="doWhat.canRead"
                    [iCanWrite]="doWhat.canWrite" [iData$]="(filteredCampaignsData | async) ?? []"
                    (oCBClick)="onSelectCheckboxChange($event)"></app-special-datatable>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" *ngIf="rollupReportDataResponse?.orderId">
            <a ngbNavLink>
                <i-feather name="file-text"></i-feather><label>Progress</label>
            </a>
            <ng-template ngbNavContent>
                <app-reports-rollup-report-view-progress (oDataLoadCompleted)="DataLoadCompleted($event)"
                    [iRollupReportDataResponse]="rollupReportDataResponse">
                </app-reports-rollup-report-view-progress>
            </ng-template>
        </li>
        <li [ngbNavItem]="3" *ngIf="rollupReportDataResponse?.orderId && dataLoadCompleted">
            <a ngbNavLink>
                <i-feather name="file-text"></i-feather><label>Report View</label>
            </a>
            <ng-template ngbNavContent>
                <app-reports-rollup-report-view [iReportFormat]="reportFormat.value"
                    [iRollupReportDataResponse]="rollupReportDataResponse">
                </app-reports-rollup-report-view>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" style="background-color: white; padding: 8px;"></div>
</div>