import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { DatatableModule } from '../../shared/components/datatable/datatable.module';
import { SpecialDatatableModule } from '../../shared/components/special-datatable/special-datatable.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportViewComponent } from './report-view/report-view.component';
import { ReportsMainComponent } from './reports-main/reports-main.component';
import { ReportsRawCampaignsDataComponent } from './reports-raw-campaigns-data/reports-raw-campaigns-data.component';
import { ReportsRollupReportViewComponent } from './reports-rollup-report-view/reports-rollup-report-view.component';
import { ReportsRollupCompanyCampaignsComponent } from './reports-rollup-company-main/reports-rollup-company-main.component';
import { ReportsSimpleCampaignsComponent } from './reports-simple-campaigns/reports-simple-campaigns.component';
import { ReportsApiService } from './reports.apiservice';
import { WeeklyReportsComponent } from './weekly-reports/weekly-reports.component';
import { ReportsRollupReportViewProgressComponent } from './reports-rollup-report-view-progress/reports-rollup-report-view-progress.component';
import { ReportsRollupMainComponent } from './reports-rollup-main/reports-rollup-main.component';
import { CampaignDatatableModule } from '../../shared/components/campaign-datatable/campaign-datatable.module';
import { ReportsCmoDashboardComponent } from './reports-cmo-report/reports-cmo-report.component';

@NgModule({
  declarations: [ 
    ReportsMainComponent,
    ReportViewComponent,
    WeeklyReportsComponent,
    WeeklyReportsComponent, 
    ReportsRollupMainComponent,
    ReportsCmoDashboardComponent,
    ReportsRollupCompanyCampaignsComponent,
    ReportsSimpleCampaignsComponent,
    ReportsRollupReportViewComponent,
    ReportsRawCampaignsDataComponent, 
    ReportsRollupReportViewProgressComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DatatableModule,
    SpecialDatatableModule,
    CampaignDatatableModule,
    ReportsRoutingModule,
    DragDropModule, 
  ],
  providers:[ 
    ReportsApiService,
  ]
})
export class ReportsModule { }
