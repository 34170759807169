import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGE_NAMES, ROLES, ROLES_TYPE } from '../../../core/helpers/constants';
import { getRolesForPage } from '../../../core/helpers/utility';
import { IPermission } from '../../../core/models/auth.models';
import { AppService } from '../../../store/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-main',
  templateUrl: './reports-main.component.html',
  styleUrls: ['./reports-main.component.scss']
})
export class ReportsMainComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;

  roleOptions: any = {};
  isAdmin: boolean = false;
  public userRoles: Array<any> = [];
  isExternal: boolean = false;
  hasRollupReportsAccess = true;
  hasCmoDashboardAccess = true;

  private rollupReportsRoles: Array<any>
    = [ROLES.Admin, ROLES.DMS, ROLES.TechServices, ROLES.ShopManager, ROLES.SalesRep, ROLES.CompanyOwner, ROLES.MarketingManager, ROLES.ClientOwner];

  private cmoDashboardRoles: Array<any>
    = [ROLES.Admin, ROLES.DMS, ROLES.TechServices, ROLES.ShopManager, ROLES.SalesRep, ROLES.CompanyOwner, ROLES.MarketingManager];

  active: any = "";
  tabs = ['campaign-report', 'rollup-report', 'weekly-reports', 'cmo-dashboard'];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private appService: AppService, private location: Location, private activatedRoute: Router,) {
    this.userRoles = this.appService.getActiveUserRoles$().value
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Dashboard');
    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isExternal = u.type == ROLES_TYPE.External
        var r = getRolesForPage(u.roles, PAGE_NAMES.Reports);
        this.isAdmin = r.isAdmin;
        if (r.options)
          this.roleOptions = JSON.parse(r.options!);
      });

    const urlSnapshot = this.activatedRoute;
    const found = this.tabs.filter(e => urlSnapshot.url.includes(e));
    if (found.length >= 1) {
      const url = found[0];
      const tabIndex = this.tabs.findIndex(x => x.toLowerCase() === url.toLowerCase());
      if (tabIndex > -1) {
        this.active = (tabIndex + 1);
      }
    }

    if (!this.active) {
      // console.log('no active.');
      this.active = 1;
    }  

    this.hasRollupReportsAccess = this.userRoles.some(r => this.rollupReportsRoles.some(w => w.Code == r.code)); 
    this.hasCmoDashboardAccess = this.userRoles.some(r => this.cmoDashboardRoles.some(w => w.Code == r.code)); 
  }


  onTabChange(event: number) {
    // console.log(event);
    const tabIndex = event - 1;
    this.location.go('/pages/reports/' + this.tabs[tabIndex]);
  }

  navigateTo(tab: string) {
    this.activatedRoute.navigate(['/pages/reports', tab]);
  }
}