<div class="modal-box" cdkDrag>
    <form [formGroup]="addForm" (ngSubmit)="onSubmit()">
        <div class="parent">
            <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
                <h4 class="modal-title">Add order to subscription</h4>
                <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <label class="col-sm-3" for="subscriptionId">Subscription</label>
                    <div class="col-sm-9">
                        <ng-select formControlName="subscriptionId"
                            [ngClass]="{ 'is-invalid': submitted && f.subscriptionId.errors }" bindLabel="value" bindValue="id"
                            [items]="allSubscriptions">
                        </ng-select>
                        <div *ngIf="submitted && f.subscriptionId.errors" class="invalid-feedback">
                            <div *ngIf="f.subscriptionId.errors.required">subscription is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3">Period</label>
                    <div class="col-sm-9">
                        <input mwlFlatpickr [altInputClass]="'datetime'" mode="range" [altInput]="true"
                            [convertModelValue]="false" formControlName="period" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.period.errors }" placeholder="select period">
                        <div *ngIf="submitted && f.period.errors" class="invalid-feedback">
                            <div *ngIf="f.period.errors.required">period is required</div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3">Amount</label>
                    <div class="col-sm-9">
                        <div class="input-group input-group-sm mb-1">
                            <span class="input-group-text border-0 bg-none">$ </span>
                            <input type="text" class="form-control" (blur)="transformAmount()"
                                formControlName="amount" [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"
                                placeholder="enter amount">
                            <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                                <div *ngIf="f.amount.errors.required">amount is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3">Paid</label>
                    <div class="col-sm-9">
                        <input tabindex="-1" class="form-check-input" type="checkbox" formControlName="isPaid" id="paid"
                            style="margin-right: 10px;">
                        <label class="form-check-label" for="paid">
                            Mark as paid
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" class="btn btn-orange" (click)="activeModal.close('Close click')">Cancel</button>
            <button type="submit" class="blue-button">Save</button>
        </div>
    </form>
</div>