import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { IWidget } from '../../../core/models/widget.model';
import { AppService } from '../../../store/app.service';
import { DashboardApiService } from '../dashboard.apiservice';

@Component({
  selector: 'app-widget-item',
  templateUrl: './add-widget-item.component.html',
  styleUrls: ['./add-widget-item.component.scss']
})

export class AddWidgetItemComponent implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() widgetId!: string;
  @Input() widgetName!: string;

  savedSearches!: any[];
  selectedSavedSearches: string[] = [];
  constructor(private appService: AppService,
    private dashboardService: DashboardApiService,
    public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {
    this.dashboardService.getWidgetSavedSearches().subscribe((response: any) => {
      if (response.good) {
        this.savedSearches = response.savedSearch;
      }
    })
  }

  selectionChange(evt: any) {
    this.selectedSavedSearches = evt.map((x: any) => x.id);
  }

  save() {
    const request = { WidgetId: this.widgetId, SavedSearchesIds: this.selectedSavedSearches };
    this.dashboardService.addWidgetItems(request).subscribe((response: any) => {
      if (response.good) {
        this.appService.updateWidget$(response.data);
        this.activeModal.close();
      }
    });
  }
}