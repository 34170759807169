import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { IPermission } from '../../../core/models/auth.models';
import { ISearch } from '../../../core/models/search.model';
import { AppService } from '../../../store/app.service';
import { SearchesApiService } from '../searches.apiservice';

@Component({
  selector: 'app-saved-searches',
  templateUrl: './saved-searches.component.html',
  styleUrls: ['./saved-searches.component.scss']
})
export class SavedSearchesComponent implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  doWhat: IPermission = {} as IPermission;
  searches!: ISearch[];
  columns = [
    { title: 'Name', field: 'name', sortable: true, searchable: true },
    { title: 'Type', field: 'resultType', sortable: true, searchable: true },
  ];
  constructor(private appService: AppService,
    private searchApi: SearchesApiService,
    private router: Router) { }

  ngOnInit(): void {
    this.appService.fetchSearches();
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Searches);
    this.appService.getAllSearches$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: ISearch[]) => {
        this.searches = response;
      });
  }

  onSearchDelete(event: any) {
    this.searchApi.deleteUserSearch(event[0])
    .pipe(takeUntil(this.destroyed$))
    .subscribe((response: any) => {
      this.appService.fetchSearches();
    })
  }

  onSearchEdit(event: any) {
    const selectSearch: ISearch = event;
    this.router.navigate(['/pages/searches/', selectSearch.id]);
  }

  getRowLink(row: any) {
    const selectSearch: ISearch = row;
    const query = `?savedsearchid=${selectSearch.id}`;
    if (selectSearch.resultType.toLowerCase() === 'user')
    return `/pages/users${query}`;

    else if (selectSearch.resultType.toLowerCase() === 'campaign')
      return `/pages/campaigns${query}`;

    else if (selectSearch.resultType.toLowerCase() === 'company')
      return `/pages/companies${query}`;

    else if (selectSearch.resultType.toLowerCase() === 'client')
      return `/pages/clients${query}`;

    else if (selectSearch.resultType.toLowerCase() === 'tag')
      return `/pages/tags${query}`;

    else if (selectSearch.resultType.toLowerCase() === 'lead')
      return `/pages/leads${query}`;

    else if (selectSearch.resultType.toLowerCase() === 'task')
      return `/pages/tasks${query}`;

    else if (selectSearch.resultType.toLowerCase() === 'prospect')
      return `/pages/prospects${query}`;

    return null;
  }
}
