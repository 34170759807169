import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ROLES, ROLES_TYPE, PAGE_NAMES } from '../../../core/helpers/constants';
import { createGuid, getRolesForPage, matchingPasswords } from '../../../core/helpers/utility';
import { IRole, IUser } from '../../../core/models/auth.models';
import { IClient } from '../../../core/models/clients.models';
import { ClientsApiService } from '../../../pages/clients/clients.apiservice';
import { CompanyApiService } from '../../../pages/companies/company.apiservice';
import { DataFeedService } from '../../api/datafeed.service';
import { AppService } from '../../../store/app.service';

@Component({
  selector: 'modal-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyModal implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oSaveClicked = new EventEmitter<any>();

  allCompanyOwners: Array<IUser> = new Array<IUser>();
  allMarketingManagers: Array<IUser> = new Array<IUser>();

  addCompanyForm!: FormGroup;
  submitted = false;

  isAdmin: boolean = false;
  isExternal: boolean = false;
  canDelete: boolean = false;
  canRead: boolean = false;
  canWrite: boolean = false;
  canCreate: boolean = false;

  @Input() set iId(value: number) {
    this.id = value;
    if (value !== null) {
      this.companyApiService.getCompanyDetails(value)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.addCompanyForm.patchValue(response.data);
          if (response.data.companyOwner) {
            this.addCompanyForm.patchValue(
              {
                companyOwnerId: response.data.companyOwner.id,
                contactFirstName: response.data.companyOwner.firstName,
                contactLastName: response.data.companyOwner.lastName,
                contactPhone: response.data.companyOwner.phone,
                contactEmail: response.data.companyOwner.email,
              });
          }
          this.addCompanyForm.patchValue(
            {
              marketingManagers: response.data.marketingManagers.map((x: any) => x.id)
            });
        });
    }
  }
  id: number = -1;

  constructor(
    private dataFeedService: DataFeedService,
    private companyApiService: CompanyApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private appService: AppService) {
    this.addCompanyForm = this.formBuilder.group({
      id: [null],
      name: ['', [Validators.required]],
      contactFirstName: [''],
      contactLastName: [''],
      contactEmail: [''],
      contactPhone: [''],
      companyOwnerId: [null],
      marketingManagers: [null]
    });
  }
  get f() { return this.addCompanyForm.controls; }


  updateData(data: IClient) {
    this.addCompanyForm.patchValue(data);
  }

  ngOnInit() {
    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isExternal = u.type == ROLES_TYPE.External;
        var r = getRolesForPage(u.roles, PAGE_NAMES.Companies);
        this.isAdmin = r.isAdmin;
        this.canRead = r.canRead;
        this.canWrite = r.canWrite;
        this.canDelete = r.canDelete;
        this.canCreate = r.canCreate;
      })

    this.dataFeedService.getAllUsersWithRole(ROLES.CompanyOwner.Code)
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allCompanyOwners = [...response.data].map(i => { i.fullName = i.firstName + ' ' + i.lastName; return i; });

        }
      });
    this.dataFeedService.getAllUsersWithRole(ROLES.MarketingManager.Code)
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allMarketingManagers = [...response.data].map(i => { i.fullName = i.firstName + ' ' + i.lastName; return i; })
          //   this.addClientForm.patchValue({ salesRep: response.data[0] });
        }
      });

    this.f.companyOwnerId.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        if (val == null) {
          this.f.contactFirstName.setValidators([]);
          this.f.contactLastName.setValidators([]);
          this.f.contactPhone.setValidators([]);
          this.f.contactEmail.setValidators([]);
          this.addCompanyForm.patchValue(
            {
              contactFirstName: null,
              contactLastName: null,
              contactPhone: null,
              contactEmail: null,
            });
        }
        else {
          const sub = this.dataFeedService.getUserContactInfo(val)
            .subscribe(response => {
              this.addCompanyForm.patchValue(
                {
                  contactFirstName: response.data.firstName,
                  contactLastName: response.data.lastName,
                  contactPhone: response.data.phone,
                  contactEmail: response.data.email,
                });

              sub.unsubscribe();
            });
        }
        this.f.contactFirstName.setValidators([Validators.required]);
        this.f.contactLastName.setValidators([Validators.required]);
        this.f.contactPhone.setValidators([Validators.required]);
        this.f.contactEmail.setValidators([Validators.required, Validators.email]);
      });
  }
  ngAfterViewInit() {

  }

  onSubmit() {
    this.submitted = true;
    if (this.addCompanyForm.invalid)
      return;
    if (this.f.id.value === null)
      this.f.id.setValue(createGuid())

    let formValues = this.addCompanyForm.value;

    formValues = this.trimObjectStrings(formValues);

    if (formValues.marketingManagers)
      formValues.marketingManagers = formValues.marketingManagers.map((obj: string) => ({ id: obj }))
    else
      formValues.marketingManagers = [];
    if (this.f.companyOwnerId.value) {
      let owner = {
        id: this.f.companyOwnerId.value,
        firstName: this.f.contactFirstName.value,
        lastName: this.f.contactLastName.value,
        email: this.f.contactEmail.value,
        phone: this.f.contactPhone.value,
      };
      formValues.companyOwner = owner
    }
    this.oSaveClicked.emit(formValues);
  }

  // This function will recursively iterate through the object
  trimObjectStrings(obj: any): any {
    // Iterate over each property of the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        
        if (typeof value === 'string') {
          // Trim the string if the value is a string
          obj[key] = value.trim();
        } else if (typeof value === 'object' && value !== null) {
          // Recursively call the function if the value is an object (or array)
          this.trimObjectStrings(value);
        }
      }
    }
    return obj;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
