import { Injectable } from '@angular/core';
import { IUser } from '../core/models/auth.models';
import { HttpService } from '../shared/http/base.http';
@Injectable()
export class AccountService extends HttpService {
   login(user: IUser) {
      return this.post<any>('/auth/login', user, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   signup(user: IUser) {
      return this.post<any>('/auth/signup', user, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   sendEmail(userId: number) {
      return this.get<any>('/auth/sendEmail?id=' + userId);
   }
   verifyUser(userId: string, token: string) {
      return this.post<any>('/auth/verifyEmail', { id: userId, token: token }, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   verifyInvitation(token: string) {
      return this.put(`/auth/verifyInvitation/${encodeURIComponent(token)}`, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   requestResetPassword(email: string) {
      const hostname = window.location.origin;
      return this.get<any>(`/auth/requestResetPassword?email=${email}&hostname=${hostname}`, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   confirmResetPassword(resetPasswordModel: any) {
      return this.post<any>('/auth/confirmResetPassword', resetPasswordModel, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   getEmailFromToken(token: string) {
      return this.get<any>('/auth/getEmailFromToken?token=' + encodeURIComponent(token), { 'Content-Type': 'application/json; charset=utf-8' });
   }
}
