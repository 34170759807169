import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { createGuid } from '../../../core/helpers/utility';
import { ICampaign } from '../../../core/models/campaigns.model';
import { IReportingConfig, IReportingConfigCCEmail, IReportingConfigCampaign } from '../../../core/models/reporting.models';

@Component({
  selector: 'app-client-reporting-update',
  templateUrl: './client-reporting-update.component.html',
  styleUrls: ['./client-reporting-update.component.scss']
})
export class ClientReportingUpdateModalComponent implements OnInit {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oSaveClicked = new EventEmitter<any>();
  @Input() CampaignsData: Array<ICampaign> = [];
  @Input() ReportingConfig = {} as IReportingConfig;

  editForm: FormGroup;
  get f() { return this.editForm.controls; }
  get rmReportingConfigCcemails() { return this.editForm.get('rmReportingConfigCcemails') as FormArray; }
  get rmReportingConfigCampaigns() { return this.editForm.get('rmReportingConfigCampaigns') as FormArray; }

  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,) {
    this.editForm = this.fb.group({
      id: [null],
      clientId: [null, [Validators.required]],
      isRollup: [false],
      reportType: [null, [Validators.required]],
      toEmail: [null, [Validators.required, Validators.email]],
      rmReportingConfigCcemails: this.fb.array([]),
      rmReportingConfigCampaigns: this.fb.array([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.updateReportingConfigData();
  }

  updateReportingConfigData() {
    // console.log(this.ReportingConfig); 

    this.f.id.setValue(this.ReportingConfig.id);
    this.f.clientId.setValue(this.ReportingConfig.clientId);
    this.f.toEmail.setValue(this.ReportingConfig.toEmail);
    this.f.isRollup.setValue(this.ReportingConfig.isRollup);
    this.f.reportType.setValue(this.ReportingConfig.reportType);

    if (this.ReportingConfig.rmReportingConfigCcemails) {
      this.ReportingConfig.rmReportingConfigCcemails
        .forEach((cc: IReportingConfigCCEmail) => {
          this.rmReportingConfigCcemails.push(
            this.fb.group({
              id: [cc.id],
              reportingConfigId: [cc.reportingConfigId],
              ccEmail: [cc.ccemail, [Validators.required, Validators.email]]
            })
          );
        });
    }

    if (this.ReportingConfig.rmReportingConfigCampaigns) {
      this.ReportingConfig.rmReportingConfigCampaigns.sort((s1, s2) => {
        if (s1.campaign?.platform == s2.campaign?.platform) return (s1.campaign?.name ?? '') < (s2.campaign?.name ?? '') ? -1 : 1;
        return (s1.campaign?.platform ?? '') < (s2.campaign?.platform ?? '') ? -1 : 1;
      });
      this.ReportingConfig.rmReportingConfigCampaigns
        .forEach((cc: IReportingConfigCampaign) => {
          this.rmReportingConfigCampaigns.push(
            this.fb.group({
              id: [cc.id],
              reportingConfigId: [cc.reportingConfigId],

              campaignId: [cc.campaignId, [Validators.required]],
              campaignName: [cc.campaign?.name],
              platform: [cc.campaign?.platform],
              adAccounts: [cc.campaign?.campaignAdAccounts],
              platformCampaignId: [cc.campaign?.platformCampaignId],
              platformAccountId: [cc.campaign?.platformAccountId],
            })
          );
        });
    }
  }

  removeReportingCC(i: number) {
    // console.log(i);
    this.rmReportingConfigCcemails.removeAt(i);
  }

  removeReportingConfigCampaign(i: number) {
    // console.log(i);
    this.rmReportingConfigCampaigns.removeAt(i);
  }

  clearEditFormCampaigns() {
    this.rmReportingConfigCampaigns.clear();
  }

  editReportingCC(i: number) {
    console.log(i);
    // this.rmReportingConfigCcemails.removeAt (cc);
  }

  addNewCC(): void {
    this.submitted = false;
    //console.log(this.f.value);
    this.rmReportingConfigCcemails.push(this.fb.group({
      id: [createGuid()],
      reportingConfigId: [this.ReportingConfig?.id],
      ccEmail: [null, [Validators.required, Validators.email]]
    })
    );
  }

  addCampaignToConfig(selectedCampaignIds: Array<string>): void {
    this.submitted = false;

    for (let selectedCampaignId of selectedCampaignIds) {
      if (selectedCampaignId !== null && selectedCampaignId !== '') {
        let selectedCampaign = this.CampaignsData.find(f => f.id === selectedCampaignId);

        let newRow = this.fb.group({
          id: [createGuid()],
          campaignId: [selectedCampaign?.id, [Validators.required]],
          campaignName: [selectedCampaign?.name, [Validators.required]],
          platform: [selectedCampaign?.platform, [Validators.required]],
          adAccounts: [selectedCampaign?.campaignAdAccounts],
          platformCampaignId: [selectedCampaign?.platformCampaignId],
          platformAccountId: [selectedCampaign?.platformAccountId],
        });

        this.rmReportingConfigCampaigns.push(newRow);
      }
    }
  }

  getCCsErrors(i: number) {
    if (!this.submitted) return;
    if (!this.rmReportingConfigCcemails) return;

    return (this.rmReportingConfigCcemails.controls[i] as FormGroup).controls?.ccEmail?.errors;
  }

  onSubmit() {
    this.submitted = true;

    //console.log(this.editForm);
    //console.log(this.editForm.errors);

    if (this.editForm.invalid)
      return;

    var updatedForm = this.editForm.value;
    if (!(updatedForm.id) || updatedForm.id === '')
      updatedForm.id = createGuid();

    updatedForm.rmReportingConfigCcemails.forEach((cc: IReportingConfigCCEmail) => {
      if (!(cc.id) || cc.id === '')
        cc.id = createGuid();

      if (!(cc.reportingConfigId) || cc.reportingConfigId === '')
        cc.reportingConfigId = updatedForm.id;
    });

    updatedForm.rmReportingConfigCampaigns.forEach((cCamp: IReportingConfigCampaign) => {
      if (!(cCamp.id) || cCamp.id === '')
        cCamp.id = createGuid();

      if (!(cCamp.reportingConfigId) || cCamp.reportingConfigId === '')
        cCamp.reportingConfigId = updatedForm.id;
    });

    this.oSaveClicked.emit(updatedForm);
    //console.log(updatedForm);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getEditFormCampaignsByPlatform(platform: string): Array<any> {
    if (!platform) return [];
    return this.editForm.value.rmReportingConfigCampaigns.filter((f: any) => f.platform.toLowerCase() === platform.toLowerCase());
  }
}
