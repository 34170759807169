<div class="row" [ngClass]="{'readonly-all': !isAdmin && !canWrite}">
    <div class="col-md-12">
        <div class="card card-custom">
            <div class="card-body">
                <form [formGroup]="editCouponForm" (ngSubmit)="onSubmit()">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <h4>Coupon Info</h4>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label" for="code">Coupon Code</label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" formControlName="code"
                                        [ngClass]="{ 'is-invalid': submitted && f.code.errors }"
                                        placeholder="Enter coupon code">
                                    <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                                        <div *ngIf="f.code.errors.required">Code is required</div>
                                        <div *ngIf="f.code.errors.maxLength">Maximum length is 50 characters</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label" for="description">Description</label>
                                <div class="col-sm-7">
                                    <textarea rows="3" class="form-control" formControlName="description"
                                        [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                                        placeholder="Enter description"></textarea>
                                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                        <div *ngIf="f.description.errors.required">Description is required </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5" for="name">Can be used with other coupons</label>
                                <div class="col-sm-7" style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start;">
                                    <input type="checkbox" formControlName="usageWithOtherCoupons"
                                        class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label" for="type">Discount Type</label>
                                <div class="col-sm-7">
                                    <select formControlName="type" class="form-control form-select">
                                        <option *ngFor="let p of COUPON_TYPES" [ngValue]="p.value">{{p.title}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                                        <div *ngIf="f.type.errors.required">Discount type is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label" for="amount">{{couponAmountTitle}}</label>
                                <div class="col-sm-7">
                                    <input type="number" (keypress)="decimelFilter($event)" class="form-control"
                                        formControlName="amount"
                                        [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"
                                        placeholder="Enter coupon amount">
                                    <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                                        <div *ngIf="f.amount.errors.required">Coupon Amount is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label">Start Date</label>
                                <div class="col-md-7">
                                    <input
                                        [ngClass]="{ 'is-invalid': submitted && f.startDate.errors, 'disable-calender': !isAdmin && !canWrite }"
                                        mwlFlatpickr [altInputClass]="'datetime'" [altInput]="true"
                                        [convertModelValue]="true" formControlName="startDate" class="form-control" />
                                    <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                                        <div *ngIf="f.startDate.errors.required">required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label">Expiry Date</label>
                                <div class="col-md-7">
                                    <input
                                        [ngClass]="{ 'is-invalid': submitted && f.expiryDate.errors, 'disable-calender': haveNoEndDate || (!isAdmin && !canWrite) }"
                                        mwlFlatpickr [altInputClass]="'datetime'" [altInput]="true"
                                        [convertModelValue]="true" formControlName="expiryDate" class="form-control" />
                                    <div *ngIf="submitted && f.expiryDate.errors" class="invalid-feedback">
                                        <div *ngIf="f.expiryDate.errors.required">required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="row">
                                <label class="col-sm-5 col-form-label" for="name">No End Date</label>
                                <div class="col-sm-7" style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start;">
                                    <input formControlName="cbNoExpiryDate" (change)="onNoEndDateChange($event)"
                                        type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <hr />
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <h4>Usage Limits</h4>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label" for="usageLimit">Total Usage Limit</label>
                                <div class="col-sm-7">
                                    <input type="number" (change)="validateUsageLimit()" class="form-control"
                                        formControlName="usageLimit"
                                        [ngClass]="{ 'is-invalid': submitted && f.usageLimit.errors }"
                                        placeholder="Enter usage limit">
                                        <span style="font-size:10px">leave blank for unlimited</span>
                                    <div *ngIf="submitted && f?.usageLimit?.errors" class="invalid-feedback">
                                        <div *ngIf="f.usageLimit.errors?.max">Cannot be greater than 999999</div>
                                        <div *ngIf="f.usageLimit.errors?.min">Minmum allowed number is {{minUsage}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label" for="usageLimitSubscriptions">Subscription Usage Limit</label>
                                <div class="col-sm-7">
                                    <input type="number" (change)="validateUsageLimit()" class="form-control"
                                        formControlName="usageLimitSubscriptions"
                                        [ngClass]="{ 'is-invalid': submitted && f.usageLimitSubscriptions.errors }"
                                        placeholder="Enter usage limit">
                                        <span style="font-size:10px">leave blank for unlimited</span>
                                    <div *ngIf="submitted && f?.usageLimitSubscriptions?.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.usageLimitSubscriptions.errors?.required">Usage limit is required
                                        </div>
                                        <div *ngIf="f.usageLimitSubscriptions.errors?.max">Cannot be greater than 999999
                                        </div>
                                        <div *ngIf="f.usageLimitSubscriptions.errors?.min">Minmum allowed number is
                                            {{minUsage}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label" for="usageLimitClients">Clients Usage Limit</label>
                                <div class="col-sm-7">
                                    <input type="number" (change)="validateUsageLimit()" class="form-control"
                                        formControlName="usageLimitClients"
                                        [ngClass]="{ 'is-invalid': submitted && f.usageLimitClients.errors }"
                                        placeholder="Enter usage limit">
                                        <span style="font-size:10px">leave blank for unlimited</span>
                                    <div *ngIf="submitted && f?.usageLimitClients?.errors" class="invalid-feedback">
                                        <div *ngIf="f.usageLimitClients.errors?.required">Usage limit is required</div>
                                        <div *ngIf="f.usageLimitClients.errors?.max">Cannot be greater than 999999</div>
                                        <div *ngIf="f.usageLimitClients.errors?.min">Minmum allowed number is
                                            {{minUsage}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div *ngIf="isAdmin || canWrite" class="col-md-7">
                            <button style="margin-left:10px;float:right" (click)="addUsageWithSubscription()"
                                class="btn btn-page">Add Subscription</button>
                            <button style="float:right" (click)="addUsageWithClient()" class="btn btn-page">Add
                                Client</button>
                        </div> -->


                    <!-- <div style="border: 0.5px solid #f5eaea; padding-top:20px" formArrayName="usageWithSubscriptions">
                        <div class="d-flex align-items-center justify-content-center"
                            *ngIf="usageWithSubscriptions.controls.length === 0">
                            <p>No subscription limits found.</p>
                        </div>
                        <div class="mb-3" *ngFor="let c of usageWithSubscriptions.controls; let i=index">
                            <div [formGroupName]="i">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="row">
                                            <label class="col-sm-5 col-form-label">Subscription</label>
                                            <input type="hidden" formControlName="id" />
                                            <div [ngClass]="{ 'col-sm-7' : true }">
                                                <ng-select
                                                    [ngClass]="{ 'is-invalid': submitted && usageWithSubscriptions.at(i).get('subscriptionId')?.errors }"
                                                    formControlName="subscriptionId">
                                                    <ng-option *ngFor="let r of subscriptionsFiltered[i]"
                                                        [value]="r.id">
                                                        {{r.email}}</ng-option>
                                                </ng-select>
                                                <div *ngIf="submitted && usageWithSubscriptions.at(i).get('subscriptionId')?.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="usageWithSubscriptions.at(i).get('subscriptionId')?.errors?.required">
                                                        Please select a subscription</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="row">
                                            <label class="col-sm-5" for="usageLimit">Usage Limit</label>
                                            <div class="col-sm-7">
                                                <input type="number" (change)="onSubLimitChange($event,i)" class="form-control" formControlName="usageLimit"
                                                    [ngClass]="{ 'is-invalid': submitted && usageWithSubscriptions.at(i).get('usageLimit')?.errors }"
                                                    placeholder="Enter usage limit">
                                                <div *ngIf="submitted && usageWithSubscriptions.at(i).get('usageLimit')?.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="usageWithSubscriptions.at(i)?.get('usageLimit')?.errors?.required">
                                                        Usage limit is required
                                                    </div>
                                                    <div *ngIf="usageWithSubscriptions.at(i)?.get('usageLimit')?.errors?.max">
                                                        Cannot be greater than 999999
                                                    </div>
                                                    <div *ngIf="usageWithSubscriptions.at(i)?.get('usageLimit')?.errors?.min">
                                                        Minmum allowed number is 1
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-1">
                                        <button *ngIf="isAdmin || canWrite" type="button" class="btn btn-outline-danger btn-sm"
                                            (click)="deleteUsageWithSubscription(i)">
                                            X
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-top:10px; border: 0.5px solid #f5eaea; padding-top:20px" formArrayName="usageWithClients">
                        <div class="d-flex align-items-center justify-content-center"
                            *ngIf="usageWithClients.controls.length === 0">
                            <p>No clients limits found.</p>
                        </div>
                        <div class="mb-3" *ngFor="let c of usageWithClients.controls; let i=index">
                            <div [formGroupName]="i">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="row">
                                            <label class="col-sm-5 col-form-label">Clients</label>
                                            <input type="hidden" formControlName="id" />
                                            <div [ngClass]="{ 'col-sm-7' : true }">
                                                <ng-select
                                                    [ngClass]="{ 'is-invalid': submitted && usageWithClients.at(i).get('clientId')?.errors }"
                                                    formControlName="clientId">
                                                    <ng-option *ngFor="let r of clientsFiltered[i]" [value]="r.id">
                                                        {{r.firstName}} {{r.lastName}}</ng-option>
                                                </ng-select>
                                                <div *ngIf="submitted && usageWithClients.at(i).get('clientId')?.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="usageWithClients.at(i).get('clientId')?.errors?.required">
                                                        Please select a client</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="row">
                                            <label class="col-sm-5" for="usageLimit">Usage Limit</label>
                                            <div class="col-sm-7">
                                                <input type="number" (change)="onSubLimitChange($event,i)" class="form-control" formControlName="usageLimit"
                                                    [ngClass]="{ 'is-invalid': submitted && usageWithClients.at(i).get('usageLimit')?.errors }"
                                                    placeholder="Enter usage limit">
                                                <div *ngIf="submitted && usageWithClients.at(i).get('usageLimit')?.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="usageWithClients.at(i)?.get('usageLimit')?.errors?.required">
                                                        Usage limit is required
                                                    </div>
                                                    <div *ngIf="usageWithClients.at(i)?.get('usageLimit')?.errors?.max">
                                                        Cannot be greater than 999999
                                                    </div>
                                                    <div *ngIf="usageWithClients.at(i)?.get('usageLimit')?.errors?.min">
                                                        Minmum allowed number is 1
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-1">
                                        <button *ngIf="isAdmin || canWrite" type="button" class="btn btn-outline-danger btn-sm"
                                            (click)="deleteUsageWithClients(i)">
                                            X
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <hr />
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <h4>Restrictions</h4>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label">Type</label>
                                <div class="row col-md-7" style="padding-top: 8px;">
                                    <div class="col-md-4" style="margin-right: 8px;">
                                        <div class="form-check form-check-right">
                                            <input class="form-check-input" [value]="1" id="formRadiosRight1" type="radio"
                                                formControlName="restrictionType" >
                                            <label class="form-check-label" for="formRadiosRight1">
                                                Cart
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-check form-check-right">
                                            <input class="form-check-input" [value]="2" id="formRadiosRight2" type="radio"
                                                formControlName="restrictionType" >
                                            <label class="form-check-label" for="formRadiosRight2">
                                                Product
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div *ngIf="f.restrictionType.value == 2" class="row mb-3">
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label">Include Products</label>
                                <div class="col-md-7">
                                    <ng-select [multiple]="true"
                                        [ngClass]="{ 'is-invalid': submitted && f.includeProductsIds?.errors }"
                                        formControlName="includeProductsIds">
                                        <ng-option *ngFor="let r of productsIncludeSource" [value]="r.id">
                                            {{r.name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.includeProductsIds?.errors" class="invalid-feedback">
                                        <div *ngIf="f.includeProductsIds?.errors?.required">Required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <label class="col-sm-5 col-form-label">Exclude Products</label>
                                <div class="col-md-7">
                                    <ng-select [multiple]="true"
                                        [ngClass]="{ 'is-invalid': submitted && f.excludeProductsIds?.errors }"
                                        formControlName="excludeProductsIds">
                                        <ng-option *ngFor="let r of productsExcludeSource" [value]="r.id">
                                            {{r.name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.excludeProductsIds?.errors" class="invalid-feedback">
                                        <div *ngIf="f.excludeProductsIds?.errors?.required">Required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isAdmin || canWrite" class="row mb-3">
                        <div class="col-sm-12 d-flex flex-row justify-content-between align-items-center">
                            <a [routerLink]="['/pages/coupons']" class="btn btn-orange" type="button">Cancel</a>
                            <button class="blue-button" style="margin-right:20px" type="submit">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>