import { NgModule } from "@angular/core";
import { FeatherModule } from "angular-feather";
import { NotesComponent } from "./notes.component";
import { allIcons } from 'angular-feather/icons';
import {RouterModule} from '@angular/router';
import { SharedModule } from "../../shared.module";
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        NotesComponent
    ],
    imports: [
        FeatherModule.pick(allIcons),
        RouterModule,
        SharedModule,
        CommonModule,
    ],
    exports:[NotesComponent],
    providers: []
})
export class NotesModule { }