<div *ngIf="orderGood" fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
    <div fxLayout="row" style="border-bottom: 2px solid black; margin-top: 20px; min-width:95%" fxLayoutAlign="space-between">
        <h1 style="color: black;">Order Confirmation</h1>
        <h1 style="color: black;">ORDER# {{orderNumber}}</h1>
    </div>
    <h1 style="color: black; margin-top: 40px;">Congratulations!</h1>
    <p style="color: black; margin-top: 20px; font-size: 20px; margin-left: 20vw; margin-right: 20vw; text-align: center;">Your order has been confirmed! We have sent a digital receipt to {{clientEmail}}. <br/>We will be in contact with you shortly for next steps!</p>
    <mat-icon style="width: 100px; font-size: 100px; height: 100px; color: black;">newspaper</mat-icon>
    <button type="button" style="margin-top: 20px; margin-bottom: 20px; text-decoration: underline;" (click)="downloadInvoice()">Download Your Receipt</button>
    <p *ngIf="(nextPay | P_AppDateFormat) !== 'Invalid date'" style="color: black; margin-top: 20px; font-size: 20px;">Next Payment: {{nextPay | P_AppDateFormat}}</p>
</div>

<div *ngIf="!orderGood" fxLayout="column" fxLayoutAlign="space-around center" style="height: 100%">
    <div fxLayout="column" fxLayoutAlign="center center" style="background-color: transparent; min-height: 300px; min-width:500px"
        class="card card-body">
        <div class="alert alert-danger" role="alert">
            There was an error processing your payments, please visit <a href="pages/orders" class="link-primary">My Orders</a> page to retry payment
        </div>
        <div class="alert alert-success" role="alert">
            Order saved successfully with ORDER# {{orderNumber}}
        </div>
    </div>
</div>
