import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createGuid } from '../../../core/helpers/utility';
import { AppService } from '../../../store/app.service';
import { TagsApiService } from '../tags.apiservice';
@Component({
  selector: 'app-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.scss']
})
export class EditTagComponent implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  editTagForm!: FormGroup;
  submitted = false;

  //new
  returnPath: string = '../tags/';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private appService: AppService,
    private tagsApiService: TagsApiService) {

    this.editTagForm = this.formBuilder.group({
      id: [null],
      name: ['', [Validators.required]],
      description: ['', Validators.required],
      categories: ['', [Validators.required]],
      clientId: [null],
      campaignId: [null]
    });
  }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    if (id != null) {
      this.returnPath = '../../tags/';
    }
    this.appService.setPageTitle$(`Tags - ${id ? 'Edit' : 'Add New Tag'}`);
    if (id) {
      this.tagsApiService.getTagDetails(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.editTagForm.patchValue(response.data);
        });
    }

  }
  get f() { return this.editTagForm.controls; }

  onClientRowClick(evt: any) {
    //this.router.navigate(['/pages/tag-details', evt.id]);
  }

  onSubmit() {
    this.submitted = true;
    if (this.editTagForm.invalid)
      return;
    if (this.f.id.value === null)
      this.f.id.setValue(createGuid())

    var request = this.editTagForm.value;
    request = this.trimObjectStrings(request);

    this.tagsApiService.saveTag(request)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.router.navigate(['/pages/tags']);
      });
  }

  // This function will recursively iterate through the object
  trimObjectStrings(obj: any): any {
    // Iterate over each property of the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        
        if (typeof value === 'string') {
          // Trim the string if the value is a string
          obj[key] = value.trim();
        } else if (typeof value === 'object' && value !== null) {
          // Recursively call the function if the value is an object (or array)
          this.trimObjectStrings(value);
        }
      }
    }
    return obj;
  }

  ngAfterViewInit() {

  }

}
