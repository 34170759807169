<div class="card" style="background-color: transparent !important;">
   <div class="header" *ngIf="doWhat.isAdmin">
      <p class="seperator"></p>
      <p><input type="checkbox" id="showDeleted" [(ngModel)]="showDeleted" (change)="onShowDeletedChange()"
                  value="Show Only My Tasks"><label for="showMyTasksCheckbox">Show Deleted Campaigns</label></p>
   </div>
   <app-campaign-datatable [iShowDropdownAdd]="true" [iColumns]="columns" [iCanRead]="doWhat.canRead" [iCanWrite]="doWhat.canWrite"
      [iCanDelete]="doWhat.canDelete" [iRowLinkHandler]="getRowLink" (oDeleteRows)="deleteRows($event)" [iShowMerge]="doWhat.isAdmin || userRoles.includes(ROLES.TechServices.Code)"
      (oMergeRecords)="handleMergeRecordsClicked($event)" (oEditRow)="editRow($event)" [iData$]="campaignsData" [iIsServerLoading]="true" [iTotalCount]="dataTotalCount" 
      (oLoadDataFromServer)="onLoadingData($event)" (oCustomDataExport)="export()" [iIsCustomDataExport]="true"></app-campaign-datatable>
</div>