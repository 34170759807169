<div class="modal-box" cdkDrag>
    <form [formGroup]="assignTagForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <h4 class="modal-title">Assign Tag(s)</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <label class="col-sm-3" for="title">Select Tags</label>
                <div class="col-sm-9">
                    <ng-select [ngClass]="{ 'is-invalid': submitted && f.tagIds.errors }" [multiple]="true" formControlName="tagIds">
                        <ng-option *ngFor="let tag of allTags" [value]="tag.id">{{tag.name}}</ng-option>
                    </ng-select>
                    <div *ngIf="submitted && f.tagIds.errors" class="invalid-feedback">
                        <div *ngIf="f.tagIds.errors.required">please select tag(s)</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Close</button>
            <button type="submit" class="blue-button">Save</button>
        </div>
    </form>
</div>