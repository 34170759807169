import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/http/base.http';
import { LoadDataFromServer } from '../../shared/components/datatable/datatable.model';

@Injectable()
export class OrdersApiService extends HttpService {

   async exportOrderData(request: any) {
      //return await this.postDownloadWithSearch('/order/exportOrder?ordersearch=${orders}' );
      return await this.postDownload  ('/order/exportOrder',request);
   }
   getAllOrders() {
      return this.get<Array<any>>('/order/');
   }

   payOrder(orderId: string) {
      return this.get(`/order/pay?orderId=${orderId}`);
   }

   cancelOrder(orderStatus: any){
      return this.post('/order/updateorderstatus', orderStatus);
   }

   addOrder(data: any) {
      return this.post('/order', data);
   }

   addOrderToSubscription(data: any) {
      return this.post('/order/addorder', data);
   }

   processOrderPayment(data: any) {
      return this.post('/order/processOrderPayment', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }

   processOrderRefund(data: any) {
      return this.post('/order/processOrderRefund', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }

   // SHOP //
   getAllShopOrders() {
      return this.get<Array<any>>('/order/getAllShopOrders');
   }

   getAllShopOrdersByFilter(request: any) {
      return this.post<Array<any>>('/order/GetAllShopOrdersByFilter', request);
   }

   getShopOrderDetails(id: string) {
      return this.get(`/order/getShopOrderDetails?id=${id}`);
   }
   async getOrderInvoice(id: string) {
      return await this.getDownload(`/order/orderInvoice?id=${id}`);
   }

   // SHOP //
}
