import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ProductsApiService } from '../../pages/products/products-api.service';
import { ProductsService } from '../../pages/products/products.service';
import { ROLES_KEY } from './constants';
import { getAuthToken } from './utility';
@Injectable()
export class UtilityService {
    constructor(
        private jwtHelperService: JwtHelperService,
        private productApiService: ProductsApiService,
        private sanitizer: DomSanitizer
    ) {
    }
    getUserRoles() {
        var token = getAuthToken();
        if (token && !this.jwtHelperService.isTokenExpired(token)) {
            var tokenData = this.jwtHelperService.decodeToken(token);
            var roles = tokenData[ROLES_KEY];
            try {
                if (typeof roles === 'string')
                    return [JSON.parse(roles).code];
                else
                    return [...roles].map(x => +JSON.parse(x).code);
            }
            catch (ex) {
                console.error(ex);
            }
        }
        return [];
    }
    getProdMediaLink(productId: any) {
        return this.productApiService.getProductMedia(productId);
    }
    byteToImageSrc(byte: any) {
        return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + byte)
    }
}
