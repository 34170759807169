import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { SortablejsModule } from 'ngx-sortablejs'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { LeadsMainComponent } from './leads-main/leads-main.component';
import { LeadsApiService } from './leads.apiservice';
import { DatatableModule } from '../../shared/components/datatable/datatable.module';
import { P_AppDateFormat } from '../../shared/shared.pipe';
import { NotesModule } from '../../shared/components/notes/notes.module';
import { LeadsDetailsComponent } from './leads-details/leads-details.component';
import { SharedModule } from '../../shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CampaignDatatableModule } from "../../shared/components/campaign-datatable/campaign-datatable.module";

@NgModule({
    declarations: [
        LeadsMainComponent,
        LeadsDetailsComponent
    ],
    providers: [LeadsApiService, P_AppDateFormat],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        DragDropModule,
        MatCardModule,
        SortablejsModule,
        MatCheckboxModule,
        NgSelectModule,
        DatatableModule,
        NotesModule,
        SharedModule,
        NgxSpinnerModule,
        CampaignDatatableModule
    ]
})
export class LeadsModule { }
