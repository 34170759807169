import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGE_NAMES, ROLES, ROLES_TYPE } from '../../../core/helpers/constants';
import { getRolesForPage } from '../../../core/helpers/utility';
import { UtilityService } from '../../../core/helpers/utility.service';
import { IPermission } from '../../../core/models/auth.models';
import { ILead } from '../../../core/models/lead.models';
import { P_AppDateFormat } from '../../../shared/shared.pipe';
import { AppService } from '../../../store/app.service';
import { LeadsApiService } from '../leads.apiservice';
@Component({
  selector: 'app-leads-details',
  templateUrl: './leads-details.component.html',
  styleUrls: ['./leads-details.component.scss']
})
export class LeadsDetailsComponent implements OnInit, OnDestroy {

  public ROLES = ROLES;
  doWhat: IPermission = {} as IPermission;

  leadData: ILead
  active = 1;
  roleOptions: any = {};
  isExternal: boolean = false;
  public userRoles: Array<number> = [];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private uService: UtilityService,
    private route: ActivatedRoute,
    private appService: AppService,
    private leadsApiService: LeadsApiService,
    private datePipe: P_AppDateFormat) {
    this.leadData = {} as ILead;
    this.userRoles = [...this.uService.getUserRoles()];
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Leads');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Leads);
    this.route.params.subscribe(params => {
      const id = params.id;
      if (id) {
        this.leadsApiService.getLead(id)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            this.leadData = response.lead;
          });
      }
    });
    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isExternal = u.type == ROLES_TYPE.External
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'MM/DD/YYYY HH:mm');
  }
}
