import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { IPermission } from '../../../core/models/auth.models';
import { ICampaign } from '../../../core/models/campaigns.model';
import { IReportingPeriod } from '../../../core/models/reporting.models';
import { LoadDataFromServer } from '../../../shared/components/datatable/datatable.model';
import { AppService } from '../../../store/app.service';
import { ReportsApiService } from '../reports.apiservice';

@Component({
  selector: 'app-reports-simple-campaigns',
  templateUrl: './reports-simple-campaigns.component.html',
  styleUrls: ['./reports-simple-campaigns.component.scss']
})
export class ReportsSimpleCampaignsComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;
  columns = [
    { title: 'Name', field: 'name', sortable: true, searchable: true },
    { title: 'Client', field: 'clientName', sortable: true, searchable: true },
    { title: 'Platform', field: 'platform', sortable: true, searchable: true },
    { title: 'DMS', field: 'dmsName', sortable: true, searchable: true },
    { title: 'Stage', field: 'stageId', sortable: true, pipe: 'P_CampaignStageTitle', searchable: true },
    { title: 'Active Subscription', field: 'subscriptionActive', pipe: 'P_Boolean', searchable: true },
  ]
  campaignsData: Array<ICampaign>
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private fb: FormBuilder,
    private reportsService: ReportsApiService,) {
    this.campaignsData = new Array<ICampaign>();
  }

  reportForm: FormGroup = this.fb.group({
    key: [null, [Validators.required]],
    startDate: [null, [Validators.required]],
    stopDate: [null, [Validators.required]],
  });
  get startDate() { return this.reportForm.get('startDate') as FormControl; }
  get stopDate() { return this.reportForm.get('stopDate') as FormControl; }

  ngOnInit(): void {
    this.appService.setPageTitle$('Campaign Reports');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Reports);
    this.appService.getAllCampaigns$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: {good:boolean; data:Array<ICampaign>; totalCount:number}) => {
        // console.log(response);  
        this.campaignsData = [...response.data]; 
        this.dataTotalCount = response.totalCount;
      });
      this.savedSearchId = this.route.snapshot.queryParams['savedsearchid']; 

    this.getSupportedReportingPeriods();
  }
 
  private loadEvent!: LoadDataFromServer;
  dataTotalCount=0;
  savedSearchId!: string;
  onLoadingData(event: LoadDataFromServer) {
    this.loadEvent = event;
    this.appService.fetchAllCampaigns({
      SavedSearchId: this.savedSearchId,
      ...this.loadEvent
    });
  }

  viewReport(evt: any) {
    this.validateFormAndOpen(evt, false);
  }

  viewReportOnNewTab(evt: any) {
    this.validateFormAndOpen(evt, true);
  }

  reportformsubmitted = false;
  DateStart = '' as string | null;
  DateStop = '' as string | null;
  validateFormAndOpen(evt: any, isExternal: boolean): void {
    this.reportformsubmitted = true;
    if (this.reportForm.invalid)
      return;

    if (this.reportForm.touched == true) {
      this.DateStart = this.startDate.value;
      this.DateStop = this.stopDate.value;
    }

    let campaign = this.campaignsData.find(f => f.id == evt.id);

    let AccountID = campaign?.platformAccountId;
    let CampaignID = campaign?.platformCampaignId;
    let Platform = campaign?.platform;
    let reachCampaignID = campaign?.id;

    if (AccountID) {
      AccountID = AccountID.replace(/\ /g, '').replace(/\-/g, '');
    }
    else{
      AccountID = '';
    }

    if (CampaignID) {
      CampaignID = CampaignID.replace(/\ /g, '').replace(/\-/g, '');
    }
    else{
      CampaignID = '';
    }

    // console.log(this.DateStart, this.DateStop);
    // console.log(this.reportForm);
    
    //if (AccountID || CampaignID) {
      if (isExternal) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/pages/reports/view-report', reachCampaignID, AccountID, CampaignID, Platform, this.DateStart, this.DateStop])
        );
        window.open(url, '_blank');
      }
      else {
        this.router.navigate(['/pages/reports/view-report', reachCampaignID, AccountID, CampaignID, Platform, this.DateStart, this.DateStop]);
      }
    // }
    // else {
    //   Swal.fire(
    //     'Cannot view report!',
    //     'Please update selected campaign and set either platform campaign/account id.',
    //     'error'
    //   )
    // }
  }

  FormatDate(iDate: Date | undefined): string | null {
    if (iDate) {
      var datePipe = new DatePipe('en-US');
      let transformed = datePipe.transform(iDate, 'yyyy-MM-dd', 'UTC', 'en-US');

      return transformed
    }

    return null;
  }

  reportPeriods: Array<IReportingPeriod> = [];
  getSupportedReportingPeriods() {
    this.reportsService.getSupportedReportingPeriods()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: Array<IReportingPeriod>) => {
        // console.log(response);
        this.reportPeriods = response;

        this.onReportPeriodChange("Past Week");
      });
  }

  get selectedReportPeriod(): string | undefined | null { return this.reportForm?.controls?.key?.value; }
  get datesReadonly(): boolean { return !(this.selectedReportPeriod == 'Custom'); }
  onReportPeriodChange(selectedPeriod: string) {
    let period = this.reportPeriods.find(f => f.key == selectedPeriod);

    // console.log(period);

    this.DateStart = period?.startDate as string;
    this.DateStop = period?.stopDate as string;

    this.reportForm = this.fb.group({
      key: [selectedPeriod, [Validators.required]],
      startDate: [this.DateStart, [Validators.required]],
      stopDate: [this.DateStop, [Validators.required]],
    });
  }
}
