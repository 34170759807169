import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { IPermission } from '../../../core/models/auth.models';
import { ITag } from '../../../core/models/tags.models';
import { AppService } from '../../../store/app.service';
import { TagsApiService } from '../tags.apiservice';
@Component({
  selector: 'app-tags',
  templateUrl: './tags-main.component.html',
  styleUrls: ['./tags-main.component.scss']
})
export class TagsMainComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;
  columns = [
    { title: 'Name', field: 'name', sortable: true, searchable: true },
    { title: 'Description', field: 'description', sortable: true, searchable: true },
    { title: 'Categories', field: 'categories', searchable: true },
  ]
  tagsData: Array<ITag>
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tagService: TagsApiService,
    private appService: AppService) {
    this.tagsData = new Array<ITag>();
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Tags');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Tags);
    this.appService.getAllTags$()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: Array<ITag>) => {
        this.tagsData = [...response];
      });
    const savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
    this.appService.fetchAllTags(savedSearchId);
  }

  editRow(evt: any) {
    this.router.navigate(['/pages/edit-tag', evt.id]);
  }
  deleteRows(evt: any) {
    this.tagService.deleteTag(evt)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        const savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
        this.appService.fetchAllTags(savedSearchId);
      })
  }
  onClientRowClick(evt: any) {
    //this.router.navigate(['/pages/tag-details', evt.id]);
  }

  ngAfterViewInit() {

  }

}
