import { Constants, ROLES, ROLES_KEY } from "./constants";
import * as _ from 'underscore'
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { BehaviorSubject } from "rxjs";

const typeCache: { [label: string]: boolean } = {};
export function type<T>(label: T | ''): T {
  if (typeCache[label as string]) {
    throw new Error(`Action type "${label}" is not unqiue"`);
  }

  typeCache[label as string] = true;
  return label as T;
}

export function getAuthToken() {
  if (Constants.LS_AUTHENTICATION_KEY in localStorage) {
    var user = JSON.parse(localStorage.getItem(Constants.LS_AUTHENTICATION_KEY) || '')
    if (user)
      return user.authToken;
  }
  return '';
}
export function getActiveUser() {
  if (Constants.LS_AUTHENTICATION_KEY in localStorage) {
    var user = JSON.parse(localStorage.getItem(Constants.LS_AUTHENTICATION_KEY) || '')
    if (user)
      return user;
  }
  return null;
}

export function numberMaxLengthValidator(number: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null)
      return null;
    const forbidden = control.value.toString().length > number;
    return forbidden ? { maxLength: { value: control.value } } : null;
  };
}
export function numberMinLengthValidator(number: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null)
      return null;
    const forbidden = control.value.toString().length < number;
    return forbidden ? { minLength: { value: control.value } } : null;
  };
}

export function createGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
export function createEmptyGuid() {
  return '00000000-0000-0000-0000-000000000000';
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  return (group: FormGroup) => {
    let password = group.controls[passwordKey];
    let passwordConfirmation = group.controls[passwordConfirmationKey];
    if ((password.value !== null && passwordConfirmation.value != null) && (password.value !== "" && passwordConfirmation.value != "") && password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({ mismatchedPasswords: true })
    }
  }
}

export function getRolesForPage(p: any, pageName: string) {
  let r = { isAdmin: false, canCreate: false, canRead: false, canWrite: false, canDelete: false, options: null }
  r.isAdmin = hasAdminRole(p)
  for (var role of p) {

    var pagePerms = role.perms.find((x: any) => x.pageName === pageName);
    if (pagePerms) {
      r.canRead = r.canRead || pagePerms.canRead;
      r.canCreate = r.canCreate || pagePerms.canCreate;
      r.canWrite = r.canWrite || pagePerms.canWrite;
      r.canDelete = r.canDelete || pagePerms.canDelete;
      r.options = pagePerms.options;
    }
  }
  return r;
}
export function hasAdminRole(r: any) {
  return r.find((x: any) => x.code == ROLES.Admin.Code) ? true : false;
}

export function hasShopManagerRole(r: any) {
  return r.find((x: any) => x.code == ROLES.ShopManager.Code) ? true : false;
}


export const atleasOneTrue: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const google = control.get('isGoogle');
  const fb = control.get('isFacebook');
  const tt = control.get('isTikTok');

  if (google?.value === fb?.value === tt?.value === null)
    return { atleasOneTrue: false }

  return (google && google.value) || (fb && fb.value) || (tt && tt.value) ? null : { atleasOneTrue: false };
};
export function ordinalSuffixOf(i: number | undefined) {
  if (i === undefined)
    return '';
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}
