import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PAGE_NAMES, ROLES_TYPE } from '../../../core/helpers/constants';
import { getRolesForPage } from '../../../core/helpers/utility';
import { UtilityService } from '../../../core/helpers/utility.service';
import { IClient } from '../../../core/models/clients.models';
import { IAdsReportEmailModel, IReportingConfig } from '../../../core/models/reporting.models';
import { AppService } from '../../../store/app.service';
import { ReportsApiService } from '../reports.apiservice';

@Component({
  selector: 'app-weekly-reports',
  templateUrl: './weekly-reports.component.html',
  styleUrls: ['./weekly-reports.component.scss']
})
export class WeeklyReportsComponent implements OnInit {
  active = 1;
  
  roleOptions: any = {};
  isAdmin: boolean = false;
  public userRoles: Array<number> = [];
  isExternal: boolean = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    public uService: UtilityService,
    private appService: AppService,
    private reportsService: ReportsApiService) {
    this.userRoles = [...this.uService.getUserRoles()];
    //this.campaignsData = new Array<ICampaign>();
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Weekly Reports');
    this.appService.setRefreshSignal$(PAGE_NAMES.ClientDetails, '');

    this.getWeeklyReportsData();

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isExternal = u.type == ROLES_TYPE.External
        var r = getRolesForPage(u.roles, PAGE_NAMES.Clients);
        this.isAdmin = r.isAdmin;
        if (r.options)
          this.roleOptions = JSON.parse(r.options!);
      })
  }

  //adsReportsTableData: Array<IReportingConfig> = [];
  adsReportsTableColumns: Array<any> = [
    { title: 'Client', field: 'client', searchable: true },
    /* { title: 'Email', field: 'toEmail', searchable: true }, */
    { title: 'Company', field: 'company', searchable: true },
    { title: 'Type', field: 'reportType', searchable: true },
    { title: 'Rollup', field: 'isRollup' , searchable: true},
    { title: 'Platforms', field: 'platforms', searchable: true },
    { title: 'Campaigns', field: 'campaigns', searchable: true },
  ];
  adsReportsTableData = new Array<{ reportingConfig: IReportingConfig, id: string, reportType: string, isRollup: boolean, platforms: string, campaigns: string, toEmail: string }>();

  getWeeklyReportsData(): void {
    this.adsReportsTableData = []; 

    this.reportsService.getWeeklyReportsModels()
      .subscribe(res => {
        let configs = new Array<any>();

        let clientData = res.data as Array<IClientEmailModel>;
        clientData
          .forEach(client => {

            client.adsReports.forEach(report => {

              let c = {
                id: report.id /* Need this column as part of table so that when on delete we can access the list of IDs that were selected*/,
                client: client.fullName,
                company: client.brand,
                reportType: report.isAdvancedReport ? 'Advanced' : 'Simple',
                isRollup: report.isRollup === true ? 'yes' : 'no',
                platforms: report.campaigns.filter((n: any, i: any) => report.campaigns.indexOf(n) === i).map(m => m.platform).join(', \r\n'),
                campaigns: report.campaigns.filter((n: any, i: any) => report.campaigns.indexOf(n) === i).map(m => m.name).join(', \r\n'),
                toEmail: report.toEmail ?? client.email,
                reportEmailModel: report,
              };
              configs.push(c);
            });

          });
        this.adsReportsTableData = configs;

      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ToEmails: string = ''; // 'denzil@atlastech.dev';
  CCEmails: string = '';
  Error: string = '';

  @ViewChild('toEmailElement') toEmailElement: ElementRef = {} as ElementRef;
  oSendEmail(event: any) {
    this.Error = '';
    if (!this.ToEmails || this.ToEmails == '') {
      this.toEmailElement.nativeElement.focus();
      this.toEmailElement.nativeElement.select();
      this.Error = "*** Please enter a valid to email for test run.";
      return;
    }

    var reportEmailModel = event.reportEmailModel as IAdsReportEmailModel;
    // console.log(reportEmailModel);

    let reportModels = [reportEmailModel];

    // let subject = 'Custom subject';

    let x: { to: string, ccs?: string, subject?: string, reportModels: Array<IAdsReportEmailModel> };
    x = { to: this.ToEmails, ccs: this.CCEmails, reportModels: reportModels };

    this.reportsService.sendSingleWeeklyReport(x)
      .subscribe(
        (res: any) => {
          let errors = res.data ? res.data : '';
          Swal.fire(
            `Report(s) sent. ${res.data ? 'With Errors:' : ''}`,
            errors,
            'success',
          );
        },
        (error: HttpErrorResponse) => {
          console.error(error.error);

          this.toEmailElement.nativeElement.focus();
          this.toEmailElement.nativeElement.select();
          this.Error = error.error?.data;
        }
      );
  }

} 

export interface IClientEmailModel extends IClient {
  adsReports: Array<IAdsReportEmailModel>,
}
