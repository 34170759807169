import { NgModule } from "@angular/core";
import { TasksDatatableComponent } from "./tasks-datatable.component";
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from "@angular/router";
import { tasksDataTableSortableDirective } from "./tasks-datatable-sortable.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FlatpickrModule } from 'angularx-flatpickr';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
      TasksDatatableComponent,
      tasksDataTableSortableDirective,
    ],
    imports: [
        CommonModule,
        FeatherModule.pick(allIcons),
        RouterModule,
        NgbModule,
        FormsModule,
        MatIconModule,
        ReactiveFormsModule,
        FlatpickrModule.forRoot(),
    ],
    exports: [TasksDatatableComponent]
  })
  export class TasksDatatableModule { }