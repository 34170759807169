import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { INotes } from '../core/models/campaigns.model';
import { IClient } from '../core/models/clients.models';
import { ISearch } from '../core/models/search.model';
import { ITag } from '../core/models/tags.models';
import { IWidget } from '../core/models/widget.model';
import { CampaignsApiService } from '../pages/campaigns/campaigns.apiservice';
import { ClientsApiService } from '../pages/clients/clients.apiservice';
import { DashboardApiService } from '../pages/dashboard/dashboard.apiservice';
import { TagsApiService } from '../pages/tags/tags.apiservice';
import { TasksApiService } from '../pages/tasks/tasks.apiservice';
import { DataFeedService } from '../shared/api/datafeed.service';
import { SearchesApiService } from '../pages/searches/searches.apiservice';
import { P_AppDateFormat, P_Boolean, P_CampaignStageTitle, P_CouponExpiry, P_CouponTypesTitle, P_OrderStatus, P_ProducType, P_RolesName_Filter } from '../shared/shared.pipe';
import { IPermission, IRole, IRoleCreation, IUser } from '../core/models/auth.models';
import { UsersApiService } from '../pages/users/users.apiservice';
import { ICompany, ISubscription } from '../core/models/datafeed.models';
import { CompanyApiService } from '../pages/companies/company.apiservice';
import { LeadsApiService } from '../pages/leads/leads.apiservice';
import { RefreshSignals } from '../layouts/sidebar/menu.model';
import { getRolesForPage } from '../core/helpers/utility';
import { CouponsApiService } from '../pages/coupons/coupon.apiservice';
import { IProductMain } from '../core/models/products.models';
import { SubscriptionApiService } from '../pages/subscriptions/subscription.apiservice';
import { CurrencyPipe } from '@angular/common';
import { OrdersApiService } from '../pages/orders/orders.apiservice';
import { PaymentMethodApiService } from '../pages/my-account/payment-method/payment-method.apiservice';
import { ProductsApiService } from '../pages/products/products-api.service';
import { ShopApiService } from '../pages/shop/shop.apiservice';
import { IShopOrder } from '../core/models/shop.models';
import { ICoupon } from '../core/models/coupon.models';
@Injectable({ providedIn: 'root' })
export class AppService {

    private _pageTitleRelay = new BehaviorSubject<string>('');
    private _activePage: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private _refreshSignals: { [key: string]: BehaviorSubject<RefreshSignals> } = {};
    //Store // TODO: we will move these to index db for caching in future
    private _activeUserInfo: BehaviorSubject<IUser> = new BehaviorSubject<IUser>({} as IUser);
    private _activeUserRoles: BehaviorSubject<Array<any>> = new BehaviorSubject(new Array<any>());
    private _allClientsData: BehaviorSubject<any> = new BehaviorSubject({ data: [], totalCount: 0 });
    private _allProspectClientsData: BehaviorSubject<any> = new BehaviorSubject({ data: [], totalCount: 0 });

    private _widgetsData: BehaviorSubject<Array<IWidget>> = new BehaviorSubject(new Array<IWidget>());
    private _changeLayout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _allCampaignsData: BehaviorSubject<any> = new BehaviorSubject({ data: [], totalCount: 0 });
    private _allNotes: BehaviorSubject<Array<INotes>> = new BehaviorSubject(new Array<INotes>());
    private _allTagsData: BehaviorSubject<Array<ITag>> = new BehaviorSubject(new Array<ITag>());
    private _allTasks: BehaviorSubject<any> = new BehaviorSubject({ data: [], totalCount: 0 });
    private _forceLoadingTasks: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _allSearches: BehaviorSubject<Array<ISearch>> = new BehaviorSubject(new Array<ISearch>());
    private _allUsers: BehaviorSubject<Array<IUser>> = new BehaviorSubject(new Array<IUser>());
    private _allCompanies: BehaviorSubject<Array<ICompany>> = new BehaviorSubject(new Array<ICompany>());
    private _allLeads: BehaviorSubject<any> = new BehaviorSubject({});
    private _allOrders: BehaviorSubject<any> = new BehaviorSubject({});
    private _allShopOrders: BehaviorSubject<Array<IShopOrder>> = new BehaviorSubject(new Array<IShopOrder>());
    private _allShopOrdersFiltered: BehaviorSubject<any> = new BehaviorSubject({ data: [], totalCount: 0 });
    private _allCouponsData: BehaviorSubject<Array<ICoupon>> = new BehaviorSubject(new Array<ICoupon>());
    private _allProductsMain: BehaviorSubject<Array<IProductMain>> = new BehaviorSubject(new Array<IProductMain>());
    private _allUserSubscriptions: BehaviorSubject<any> = new BehaviorSubject([]);
    private _allUserPaymentmethods: BehaviorSubject<any> = new BehaviorSubject([]);
    private _cartProductDetails: BehaviorSubject<IProductMain> = new BehaviorSubject({} as IProductMain);

    //datafeed
    private _roleRestrictions: BehaviorSubject<Array<IRoleCreation>> = new BehaviorSubject(new Array<IRoleCreation>());
    private _allClients: BehaviorSubject<Array<IClient>> = new BehaviorSubject(new Array<IClient>());
    private _allProducts: BehaviorSubject<Array<IProductMain>> = new BehaviorSubject(new Array<IProductMain>());
    private _allSubscriptions: BehaviorSubject<Array<ISubscription>> = new BehaviorSubject(new Array<ISubscription>());
    private _allRoles: BehaviorSubject<Array<IRole>> = new BehaviorSubject(new Array<IRole>());
    //Store


    private _activeRoute: BehaviorSubject<any> = new BehaviorSubject('');

    constructor(
        private router: Router,
        private clientsApiService: ClientsApiService,
        private taskService: TasksApiService,
        private campaignApiService: CampaignsApiService,
        private couponApiService: CouponsApiService,
        private productApiService: ProductsApiService,
        private datafeedService: DataFeedService,
        private tagApiService: TagsApiService,
        private dashboardApiService: DashboardApiService,
        private searchesApiService: SearchesApiService,
        private usersApiService: UsersApiService,
        private companyApiService: CompanyApiService,
        private leadsApiService: LeadsApiService,
        private subscriptionApiService: SubscriptionApiService,
        private ordersApiService: OrdersApiService,
        private shopApiService: ShopApiService,
        private paymentMethodsApiService: PaymentMethodApiService
    ) { }

    getRoleRestrictions$() {
        return this._roleRestrictions;
    }
    setRoleRestrictions$(r: any) {
        this._roleRestrictions.next(r)
    }
    fetchRoleRestrictions() {
        this.datafeedService.getRoleRestrictions()
            .subscribe((response: any) => {
                if (response.good) {
                    this.setRoleRestrictions$(response.data);
                }
            });
    }

    getAllClientsFeed$() {
        return this._allClients;
    }
    setAllClientsFeed$(r: any) {
        this._allClients.next(r)
    }
    fetchAllClientsFeed() {
        this.datafeedService.getAllClients()
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllClientsFeed$(response.data);
                }
            });
    }

    getAllProductsFeed$() {
        return this._allProducts;
    }
    setAllProductsFeed$(r: any) {
        this._allProducts.next(r)
    }
    fetchAllProductsFeed() {
        this.datafeedService.getAllProducts()
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllProductsFeed$(response.data);
                }
            });
    }

    getAllSubscriptionsFeed$() {
        return this._allSubscriptions;
    }
    setAllSubscriptionsFeed$(r: any) {
        this._allSubscriptions.next(r)
    }
    fetchAllSubscriptionsFeed() {
        this.datafeedService.getAllSubscriptions()
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllSubscriptionsFeed$(response.data);
                }
            });
    }

    getAllRoles$() {
        return this._allRoles;
    }
    setAllRoles$(r: any) {
        this._allRoles.next(r)
    }
    fetchAllRoles() {
        this.datafeedService.getAllRoles()
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllRoles$(response.data);
                }
            });
    }



    getActivePage$() {
        return this._activePage;
    }
    setActivePage$(r: any) {
        this._activePage.next(r)
    }

    getRefreshSignal$(key: string) {
        if (key in this._refreshSignals)
            return this._refreshSignals[key];
        return null;
    }
    setRefreshSignal$(key: string, id: string) {
        if (!(this._refreshSignals[key]))
            this._refreshSignals[key] = new BehaviorSubject<RefreshSignals>({} as RefreshSignals);
        this._refreshSignals[key].next({ id: id } as RefreshSignals);
    }


    getActiveUser$() {
        return this._activeUserInfo;
    }
    setActiveUser$(r: any) {
        this._activeUserInfo.next(r)
    }

    getActiveUserRoles$() {
        return this._activeUserRoles;
    }
    setActiveUserRoles$(r: any) {
        this._activeUserRoles.next(r)
    }

    getUserRolesForPage(pageName: string): IPermission {
        var doWhat: IPermission = {} as IPermission;
        this.getActiveUser$()
            .subscribe(u => {
                var r = getRolesForPage(u.roles, pageName);
                doWhat.isAdmin = r.isAdmin;
                doWhat.canRead = r.canRead || r.isAdmin;
                doWhat.canWrite = r.canWrite || r.isAdmin;
                doWhat.canDelete = r.canDelete || r.isAdmin;
                if (r.options)
                    doWhat.roleOptions = JSON.parse(r.options!);
            });
        return doWhat;
    }


    getActiveRoute$() {
        return this._activeRoute;
    }
    setActiveRoute$(route: any) {
        this._activeRoute.next(route)
    }

    getAllClients$() {
        return this._allClientsData;
    }
    getAllProspectClients$() {
        return this._allProspectClientsData;
    }
    setAllClients$(data: any) {
        this._allClientsData.next(data)
    }
    setAllProspectClients$(data: any) {
        this._allProspectClientsData.next(data)
    }


    fetchAllClients(request: any) {
        this.clientsApiService.getAllClients(request)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllClients$(response);
                }
            });
    }

    fetchAllProspectClients(request: any) {
        this.clientsApiService.getAllProspectClients(request)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllProspectClients$(response);
                }
            });
    }

    getAllTasks$() {
        return this._allTasks;
    }
    setAllTasks$(data: any) {
        this._allTasks.next(data)
    }

    getForceLoadingTasks = () => this._forceLoadingTasks;

    setForceLoadingTasks = (force: boolean) => this._forceLoadingTasks.next(force);

    fetchAllTasks(request: any) {
        this.taskService.getAllTasks(request)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllTasks$(response);
                }
            });
    }

    


    getWidgets$() {
        return this._widgetsData;
    }
    setWidgets$(data: any) {
        this._widgetsData.next(data)
    }
    updateWidget$(data: any) {
        const currentValue = this._widgetsData.value;
        const updatedValue = currentValue.map((item) => {
            if (item.id === data.id) {
                return { ...data };
            }
            return item;
        })
        this._widgetsData.next(updatedValue)
    }

    fetchWidgets() {
        this.dashboardApiService.getUserWidgets()
            .subscribe((response: any) => {
                if (response.good) {
                    this.setWidgets$(response.data);
                }
            });
    }

    fetchWidgetValues(widgetId: string) {
        this.dashboardApiService.getWidgetValues(widgetId)
            .subscribe((response: any) => {
                if (response.good) {
                    this.updateWidget$(response.data);
                }
            });
    }

    removeWidget$(widgetId: string) {
        this.dashboardApiService.deleteWidget(widgetId)
            .subscribe((response: any) => {
                if (response.good) {
                    const currentValue = this._widgetsData.value;
                    const updatedValue = currentValue.filter((item) => item.id !== widgetId);
                    this._widgetsData.next(updatedValue);
                }
            });
    }

    renameWidget$(widgetId: string, newName: string) {
        this.dashboardApiService.renameWidget(widgetId, newName)
            .subscribe((response: any) => {
                if (response.good) {
                    this.updateWidget$(response.data);
                }
            });
    }

    editWidgetItems$(data: any) {
        this.dashboardApiService.editWidgetItems(data)
            .subscribe((response: any) => {
                if (response.good) {
                    this.updateWidget$(response.data);
                }
            });
    }

    addWidget$() {
        this.dashboardApiService.addWidget()
            .subscribe((response: any) => {
                if (response.good) {
                    this.fetchWidgets();
                }
            });
    }

    reorderWidget(widgetId: string, newOrder: number) {
        this.dashboardApiService.reorderWidget(widgetId, newOrder)
            .subscribe((response: any) => {
                if (response.good) {
                    const currentValue = this._widgetsData.value;
                    const updatedValue = currentValue.map((item) => {
                        var newOrder = response.widgetsOrder.find((x: any) => x.key === item.id).value;
                        return { ...item, order: newOrder };
                    })
                    this._widgetsData.next(updatedValue);
                }
            });
    }

    reorderWidgetItem(itemId: string, newOrder: number) {
        this.dashboardApiService.reorderWidgetItem(itemId, newOrder)
            .subscribe((response: any) => {
                if (response.good) {
                    const currentValue = this._widgetsData.value;
                    const widgetIndex = currentValue.findIndex(x => x.items.find(y => y.id === itemId));
                    const updatedWidget = currentValue[widgetIndex].items.map((item) => {
                        var newOrder = response.widgetsOrder.find((x: any) => x.key === item.id).value;
                        return { ...item, order: newOrder };
                    })
                    const updatedValue = currentValue.map(w => {
                        if (w.id === currentValue[widgetIndex].id)
                            return { ...w, items: updatedWidget };
                        else
                            return { ...w };
                    })
                    this._widgetsData.next(updatedValue);
                }
            });
    }

    changeLayout(value: boolean) {
        this._changeLayout.next(value);
    }

    getChangeLayoutValue() {
        return this._changeLayout;
    }

    getAllCampaigns$() {
        return this._allCampaignsData;
    }
    setAllCampaigns$(data: any) {
        this._allCampaignsData.next(data)
    }

    fetchAllCampaigns(request: any) {
        this.campaignApiService.getAllCampaigns(request)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllCampaigns$(response);
                }
            });
    }

    getAllCoupons$() {
        return this._allCouponsData;
    }
    setAllCoupons$(data: any) {
        this._allCouponsData.next(data)
    }

    fetchAllCoupons(savedSearchId: any) {
        this.couponApiService.getAllCoupons(savedSearchId)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllCoupons$(response.data);
                }
            });
    }

    getAllProductsMain$() {
        return this._allProductsMain;
    }
    setAllProductsMain$(data: any) {
        this._allProductsMain.next(data)
    }

    fetchAllProductsMain(savedSearchId: any) {
        this.productApiService.getAllProducts(savedSearchId)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllProductsMain$(response.data);
                }
            });
    }


    getAllNotes$() {
        return this._allNotes;
    }
    setAllNotes$(data: any) {
        this._allNotes.next(data)
    }

    fetchAllNotes(id: string, type: number) {
        this.datafeedService.getNotes(id, type)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllNotes$(response.data);
                }
            });
    }

    getAllTags$() {
        return this._allTagsData;
    }
    setAllTags$(data: any) {
        this._allTagsData.next(data)
    }

    fetchAllTags(savedSearchId: any) {
        this.tagApiService.getAllTags(savedSearchId)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllTags$(response.data);
                }
            });
    }
    fetchAssociatedTags(id: string, type: number) {
        this.tagApiService.getAssociatedTags(id, type)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllTags$(response.data);
                }
            });
    }

    getPipeByName(pipeName: string) {

        switch (pipeName) {
            case 'P_AppDateFormat':
                return new P_AppDateFormat();
            case 'P_CampaignStageTitle':
                return new P_CampaignStageTitle();
            case 'P_RolesName_Filter':
                return new P_RolesName_Filter();
            case 'P_Boolean':
                return new P_Boolean();
            case 'P_CouponTypesTitle':
                return new P_CouponTypesTitle();
            case 'P_AppDateFormat':
                return new P_AppDateFormat();
            case 'P_CouponExpiry':
                return new P_CouponExpiry();
            case 'CurrencyPipe':
                return new CurrencyPipe('en-us');
            case 'P_ProductType':
                return new P_ProducType();
            case 'P_OrderStatus':
                return new P_OrderStatus();
            case 'Currency':
                return new CurrencyPipe('en-US');

            default:
                return;
        }
    }

    getPageTitle$() {
        return this._pageTitleRelay;
    }
    setPageTitle$(title: string) {
        this._pageTitleRelay.next(title)
    }

    fetchSearches() {
        this.searchesApiService.getUserSearches()
            .subscribe((response: any) => {
                if (response.good) {
                    this.setSearches$(response.savedSearch);
                }
            });
    }

    setSearches$(data: any) {
        this._allSearches.next(data)
    }

    getAllSearches$() {
        return this._allSearches;
    }

    addSearch$(search: any) {
        this.searchesApiService.addEditSearch(search)
            .subscribe((response: any) => {
                if (response.good) {
                    this.fetchSearches();
                }
            });
    }

    getAllUsers$() {
        return this._allUsers;
    }
    setAllUsers$(data: any) {
        this._allUsers.next(data)
    }

    fetchAllUsers(savedSearchId: any) {
        this.usersApiService.getAllUsers(savedSearchId)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllUsers$(response.data);
                }
            });
    }

    getAllCompanies$() {
        return this._allCompanies;
    }
    setAllCompanies$(data: any) {
        this._allCompanies.next(data)
    }

    fetchAllCompanies(savedSearchId: any) {
        this.companyApiService.getAllCompanies(savedSearchId)
            .subscribe((response: any) => {
                if (response.good) {
                    this.setAllCompanies$(response.data);
                }
            });
    }

    getAllLeads$() {
        return this._allLeads;
    }

    fetchAllLeads(request: any) {
        this.leadsApiService.getAllLeads(request)
            .subscribe((response: any) => {
                if (response.good) {
                    this._allLeads.next(response);
                }
            });
    }

    getAllUserSubscription() {
        return this._allUserSubscriptions;
    }

    fetchAllUserSubscriptions() {
        this.subscriptionApiService.getAllSubscriptions(null, null)
            .subscribe((response: any) => {
                if (response.good) {
                    this._allUserSubscriptions.next(response.subscriptions)
                }
            });
    }

    getAllUserOrders() {
        return this._allOrders;
    }

    fetchAllUserOrders() {
        this.ordersApiService.getAllOrders()
            .subscribe((response: any) => {
                if (response.good) {
                    this._allOrders.next(response.orders)
                }
            });
    }

    getAllShopOrders() {
        return this._allShopOrders;
    }

    getAllShopOrdersFiltered(){
        return this._allShopOrdersFiltered;
    }

    fetchAllShopOrders() {
        this.ordersApiService.getAllShopOrders()
            .subscribe((response: any) => {
                if (response.good) {
                    this._allShopOrders.next(response.data)
                }
            });
    }

    fetchAllShopOrdersByFilter(request: any) {
        this.ordersApiService.getAllShopOrdersByFilter(request)
            .subscribe((response: any) => {
                if (response.good) {
                    this._allShopOrdersFiltered.next(response)
                }
            });
    }

    getCartProductDetails$() {
        return this._cartProductDetails;
    }
    clearCartProductDetails$() {
        this._cartProductDetails.next({} as IProductMain);
    }

    fetchCartProductDetails(id: string) {
        this.shopApiService.getProductDetails(id)
            .subscribe((response: any) => {
                if (response.good) {
                    this._cartProductDetails.next(response.data)
                }
            });
    }

    fetchAllUserPaymentMethods() {
        this.paymentMethodsApiService.getAllPaymentMethods()
            .subscribe((response: any) => {
                if (response.good) {
                    this._allUserPaymentmethods.next(response.paymentMethods)
                }
            });
    }

    getAllUserPaymentMethods() {
        return this._allUserPaymentmethods;
    }

    clearData() {
        this._pageTitleRelay = new BehaviorSubject<string>('');
        this._activePage = new BehaviorSubject<string>('');
        this._refreshSignals = {};
        //Store // TODO: we will move these to index db for caching in future
        this._activeUserInfo = new BehaviorSubject<IUser>({} as IUser);
        this._activeUserRoles = new BehaviorSubject(new Array<any>());
        this._allClientsData = new BehaviorSubject({ data: [], totalCount: 0 });
        this._widgetsData = new BehaviorSubject(new Array<IWidget>());
        this._changeLayout = new BehaviorSubject<boolean>(false);
        this._allCampaignsData = new BehaviorSubject({ data: [], totalCount: 0 });
        this._allShopOrdersFiltered = new BehaviorSubject({ data: [], totalCount: 0 });
        this._allNotes = new BehaviorSubject(new Array<INotes>());
        this._allTagsData = new BehaviorSubject(new Array<ITag>());
        this._allTasks = new BehaviorSubject({ data: [], totalCount: 0 });
        this._forceLoadingTasks = new BehaviorSubject<boolean>(false);
        this._allSearches = new BehaviorSubject(new Array<ISearch>());
        this._allUsers = new BehaviorSubject(new Array<IUser>());
        this._allCompanies = new BehaviorSubject(new Array<ICompany>());
        this._allLeads = new BehaviorSubject({});
        //datafeed
        this._roleRestrictions = new BehaviorSubject(new Array<IRoleCreation>());
        this._allClients = new BehaviorSubject(new Array<IClient>());
        this._allRoles = new BehaviorSubject(new Array<IRole>());
    }
}

