import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { LAYOUT_MODE } from "../layouts.model";
import { PAGE_NAMES, ROLES } from '../../core/helpers/constants';
import { AppService } from '../../store/app.service';
import { ClientsService } from '../../pages/clients/clients.service';
import { TasksService } from '../../pages/tasks/tasks.service';
import { UsersService } from '../../pages/users/users.service';
import { CompanyService } from '../../pages/companies/company.service';
import { IPermission, IUser } from '../../core/models/auth.models';
import { getRolesForPage, hasAdminRole, hasShopManagerRole } from '../../core/helpers/utility';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddLeadModal } from '../../shared/modals/add-lead/add-lead.component';
import { CampaignsService } from '../../pages/campaigns/campaigns.service';
import { ProductsService } from '../../pages/products/products.service';
import { LeadsService } from '../../pages/leads/leads.service';
import { CouponsService } from '../../pages/coupons/coupon.service';
import { AddOrderModal } from '../../shared/modals/add-order/add-order.component';
import { EditSubscriptionModal } from '../../pages/subscriptions/edit-subscription/edit-subscription.component';
import { AddPaymentMethodModal } from '../../shared/modals/add-payment-method/add-payment-method.component';
import { LoadDataFromServer } from '../../shared/components/datatable/datatable.model';
import { UtilityService } from '../../core/helpers/utility.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar Component
 */
export class TopbarComponent implements OnInit {

  mode: string | undefined;
  element: any;
  cookieValue: any;
  countryName: any;
  public activePage: string = '';
  changeLayoutStarted = false;
  searchKeyword: any = '';
  PAGE_NAMES = PAGE_NAMES;
  @Output() oAddClientClick = new EventEmitter();

  //new
  toggleLogo: boolean = true;
  public userRoles: Array<number> = [];
  externalUser: boolean = false;

  savedSearchId!: string;
  private loadEvent!: LoadDataFromServer;

  activeUser: IUser = {} as IUser;
  public isAdmin: boolean = false;
  public isShopManager: boolean = false;
  public permissions: { [key: string]: IPermission } = {};

  constructor(
    private modalService: NgbModal,
    private router: Router,
    public _cookiesService: CookieService,
    public route: ActivatedRoute,
    public translate: TranslateService,
    public appService: AppService,
    private cService: ClientsService,
    private campaignService: CampaignsService,
    private couponService: CouponsService,
    private tasksService: TasksService,
    private uService: UsersService,
    private compService: CompanyService,
    private leadService: LeadsService,
    private productsService: ProductsService,
    public utilService: UtilityService
  ) {
    this.userRoles = [...this.utilService.getUserRoles()];
    this.appService.getActiveUser$()
      .subscribe(u => {
        this.activeUser = u;
        this.isAdmin = hasAdminRole(u.roles);
        this.isShopManager = hasShopManagerRole(u.roles);
        var r = getRolesForPage(u.roles, PAGE_NAMES.Campaigns);
        this.permissions[PAGE_NAMES.Campaigns] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canCreate: r.canCreate,
          canDelete: r.canDelete,
        }
        r = getRolesForPage(u.roles, PAGE_NAMES.Clients);
        this.permissions[PAGE_NAMES.Clients] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        }
        r = getRolesForPage(u.roles, PAGE_NAMES.Prospects);
        this.permissions[PAGE_NAMES.Prospects] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        }
        r = getRolesForPage(u.roles, PAGE_NAMES.Users);
        this.permissions[PAGE_NAMES.Users] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        };
        r = getRolesForPage(u.roles, PAGE_NAMES.Leads);
        this.permissions[PAGE_NAMES.Leads] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        };
        r = getRolesForPage(u.roles, PAGE_NAMES.Companies);
        this.permissions[PAGE_NAMES.Companies] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        };
        r = getRolesForPage(u.roles, PAGE_NAMES.Coupons);
        this.permissions[PAGE_NAMES.Coupons] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        };
        r = getRolesForPage(u.roles, PAGE_NAMES.MyAccount);
        this.permissions[PAGE_NAMES.MyAccount] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        };
        r = getRolesForPage(u.roles, PAGE_NAMES.Subscriptions);
        this.permissions[PAGE_NAMES.Subscriptions] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        };
        r = getRolesForPage(u.roles, PAGE_NAMES.SubscriptionsDetails);
        this.permissions[PAGE_NAMES.SubscriptionsDetails] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        };
        r = getRolesForPage(u.roles, PAGE_NAMES.Orders);
        this.permissions[PAGE_NAMES.Orders] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        };
        r = getRolesForPage(u.roles, PAGE_NAMES.Tags);
        this.permissions[PAGE_NAMES.Tags] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        };
        r = getRolesForPage(u.roles, PAGE_NAMES.Products);
        this.permissions[PAGE_NAMES.Products] =
        {
          canRead: r.canRead,
          canWrite: r.canWrite,
          canDelete: r.canDelete,
          canCreate: r.canCreate,
        };
      })
    this.appService.getActiveRoute$()
      .subscribe((route: any) => { //TODO: Find a better solution
        if (route.url.indexOf('dashboard') > -1)
          this.activePage = PAGE_NAMES.Dashboard;
        else if (route.url.indexOf('clients') > -1)
          this.activePage = PAGE_NAMES.Clients;
        else if (route.url.indexOf('client-details') > -1)
          this.activePage = PAGE_NAMES.ClientDetails;
        else if (route.url.indexOf('prospects') > -1)
          this.activePage = PAGE_NAMES.Prospects;
        else if (route.url.indexOf('prospect-details') > -1)
          this.activePage = PAGE_NAMES.ProspectsDetails;
          else if (route.url.indexOf('lead-details') > -1)
          this.activePage = PAGE_NAMES.LeadDetails;
        else if (route.url.indexOf('rollup-campaigns') > -1)
          this.activePage = PAGE_NAMES.Reports;
        else if (route.url.indexOf('campaigns') > -1)
          this.activePage = PAGE_NAMES.Campaigns;
        else if (route.url.indexOf('campaign-details') > -1)
          this.activePage = PAGE_NAMES.CampaignsDetails;
        else if (route.url.indexOf('edit-campaign') > -1)
          this.activePage = PAGE_NAMES.EditCampaign;
        else if (route.url.indexOf('tags') > -1)
          this.activePage = PAGE_NAMES.Tags;
        else if (route.url.indexOf('edit-tag') > -1)
          this.activePage = PAGE_NAMES.EditTag;
        else if (route.url.indexOf('global-search') > -1)
          this.activePage = PAGE_NAMES.GlobalSearch;
        else if (route.url.indexOf('task') > -1)
          this.activePage = PAGE_NAMES.Tasks;
        else if (route.url.indexOf('users') > -1)
          this.activePage = PAGE_NAMES.Users;
        else if (route.url.indexOf('companies') > -1)
          this.activePage = PAGE_NAMES.Companies;
        else if (route.url.indexOf('searches') > -1)
          this.activePage = PAGE_NAMES.Searches;
        else if (route.url.indexOf('reports') > -1)
          this.activePage = PAGE_NAMES.Reports;
        else if (route.url.indexOf('leads') > -1)
          this.activePage = PAGE_NAMES.Leads;
        else if (route.url.indexOf('products/') > -1)
          this.activePage = PAGE_NAMES.ProductsDetails;
        else if (route.url.indexOf('products') > -1)
          this.activePage = PAGE_NAMES.Products;
        else if (route.url.indexOf('coupons') > -1)
          this.activePage = PAGE_NAMES.Coupons;
        else if (route.url.indexOf('edit-coupon') > -1)
          this.activePage = PAGE_NAMES.EditCoupon;
        else if (route.url.endsWith('subscriptions') || route.url.endsWith('subscriptions/'))
          this.activePage = PAGE_NAMES.Subscriptions;
        else if (route.url.indexOf('subscriptions') > -1)
          this.activePage = PAGE_NAMES.SubscriptionsDetails;
        else if (route.url.indexOf('emails') > -1)
          this.activePage = PAGE_NAMES.Emails;
        else if (route.url.indexOf('myaccount') > -1)
          this.activePage = PAGE_NAMES.MyAccount;
        else if (route.url.indexOf('orders') > -1)
          this.activePage = PAGE_NAMES.Orders;
        else if (route.url.indexOf('shop') > -1)
          this.activePage = PAGE_NAMES.Shop;
        this.appService.setActivePage$(this.activePage);

        // console.log(route.url);
        // console.log(route.url.indexOf('emails'));
      });

    this.tasksService.onSave.subscribe((x: boolean) => {
      if (x) {
        this.appService.setForceLoadingTasks(true);
      }
    })

    if (!this.userRoles.includes(1) && !this.userRoles.includes(10) && !this.userRoles.includes(2) && !this.userRoles.includes(3) && !this.userRoles.includes(4) && !this.userRoles.includes(12)) {
      this.externalUser = true;
    }
  }

  onEditClick(action: any) {
    this.cService.openEditClientModal({ id: action === 'add' ? null : -1 }, {});
  }
  onDeleteClientClick() {
    this.cService.deleteClient();
  }
  onDeleteCampaignClick() {
    this.campaignService.deleteCampaign();
  }

  onDeleteProspectClick() {
    this.cService.deleteProspect();
  }

  onDeleteCouponClick() {
    this.couponService.deleteCoupon();
  }

  onAddTaskClick(action: any) {
    this.tasksService.openEditTaskModal({ id: action === 'add' ? null : -1 }, true, true);
  }

  onUserClick(action: any) {
    if (!this.isAdmin) {
      if (this.activeUser.roles.some(r => [ROLES.MarketingManager.Code].includes(r.code))) {
        if (this.activeUser.marketingManagerOf.length === 0) {
          Swal.fire('Users ', "You need to be assigned to a company first", 'warning');
          return;
        }
      }
      else if (this.activeUser.roles.some(r => [ROLES.CompanyOwner.Code].includes(r.code))) {
        if (this.activeUser.companyOwnerOf.length === 0) {
          Swal.fire('Users ', "You need to be assigned to a company first", 'warning');
          return;
        }
      }
      else if (this.activeUser.roles.some(r => [ROLES.ClientOwner.Code].includes(r.code))) {
        if (this.activeUser.clientOwnerOf.length === 0) {
          Swal.fire('Users ', "You need to be assigned to a client first", 'warning');
          return;
        }
      }
    }
    this.uService.openEditUserModal({ id: action === 'add' ? null : -1 });
  }

  onCompanyClick(action: any) {
    this.compService.openEditCompanyModal({ id: action === 'add' ? null : -1 });
  }

  onLeadsClick() {
    const dialogRef = this.modalService.open(AddLeadModal, { size: 'lg' });
    dialogRef.componentInstance.oSaveClicked.subscribe(() => {
      this.appService.fetchAllLeads({
        SavedSearchId: this.savedSearchId,
        PageNumber: 1,
        PageSize: 50,
        ...this.loadEvent
      });
    })
  }

  onLeadDeleteClick(){
    this.leadService.deleteLeads();
  }

  onSubscriptionAddClick() {
    this.router.navigate(['pages/shop/']);
  }

  onOrderAddClick() {
    var modal = this.modalService.open(AddOrderModal, { size: 'lg' });
    modal.componentInstance.oSaveClicked.subscribe(() => {
      this.appService.fetchAllUserOrders();
    });
  }

  onAddNewPaymentMethod() {
    const dialogRef = this.modalService.open(AddPaymentMethodModal, { size: 'lg' });
    dialogRef.componentInstance.oSaveClicked.subscribe(() => {
      this.appService.fetchAllUserPaymentMethods();
    })
  }

  onSubscriptionEditClick() {
    var subscriptionId = this.router.url.split('/').slice(-1).pop();
    var modal = this.modalService.open(EditSubscriptionModal, { size: 'lg' });
    modal.componentInstance.iId = subscriptionId;
    modal.componentInstance.oSaveClicked.subscribe(() => {
      //this.appService.fetchAllUserSubscriptions();
      this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => this.router.navigate(['/pages/subscriptions/' + subscriptionId]));
    });
  }

  onReportClick(action: any) {
    console.log('Add Report Clicked.');
    // this.compService.openEditCompanyModal({ id: action === 'add' ? null : -1 });
  }

  onProductsAddClick(action: any) {
    this.productsService.openEditModal({ id: action === 'add' ? null : -1 });
  }
  onProductsEditClick(action: any) {
    this.productsService.openEditModal({ id: action === 'add' ? null : -1 });
  }
  onProductsDeleteClick() {
    this.productsService.deleteProductForm();
  }

  /**
   * Language Listing
   */

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  layoutMode!: string;

  ngOnInit(): void {
    this.layoutMode = LAYOUT_MODE;

    this.element = document.documentElement;
    // Cookies wise Language set
    this.cookieValue = this._cookiesService.get('lang');
  }

  /**
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.cookieValue = lang;
    // this.languageService.setLanguage(lang);
  }

  /**
   * Topbar Light-Dark Mode Change
   */
  changeMode(mode: string) {
    this.layoutMode = mode;
    this.mode = mode;
    //  this.eventService.broadcast('changeMode', mode);
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    this.toggleLogo = !this.toggleLogo;
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  changeLayoutText() { return this.changeLayoutStarted ? 'Finish Change Layout' : 'Change Layout'; }

  changeLayoutClick() {
    this.changeLayoutStarted = !this.changeLayoutStarted;
    this.appService.changeLayout(this.changeLayoutStarted);
  }
  addWidgetClick() {
    this.appService.addWidget$();
  }

  search() {
    if (this.searchKeyword && this.searchKeyword.trim() !== '')
      this.router.navigate(['pages/global-search/' + this.searchKeyword]);
    this.searchKeyword = '';
  }
}
