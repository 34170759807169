import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { IPermission } from '../../../core/models/auth.models';
import { SubscriptionApiService } from '../../../pages/subscriptions/subscription.apiservice';
import { AppService } from '../../../store/app.service';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { getRolesForPage } from '../../../core/helpers/utility';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-subscription-shared',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})

export class SubscriptionSharedComponent implements OnInit {
  @Input() iCampaignId: string = undefined!;
  @Input() iClientId: any = undefined!;
  doWhat: IPermission = {} as IPermission;
  doWhatSub: IPermission = {} as IPermission;
  columns = [
    { title: 'ID', field: 'numericId', sortable: true, searchable: true },
    { title: 'Start Date', field: 'startDate', sortable: true, searchable: true },
    { title: 'DMS', field: 'dms', sortable: true, searchable: true },
    { title: 'Sales Rep', field: 'salesrep', sortable: true, searchable: true },
    { title: 'Status', field: 'status', sortable: true, searchable: true },
  ]

  subscriptions: Array<any>;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    public router: Router,
    private appService: AppService,
    private subscriptionService: SubscriptionApiService,
  ) {
    this.subscriptions = new Array<any>();  
  }

  ngOnInit(): void {
    this.loadSubscriptions();
    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.doWhatSub = getRolesForPage(u.roles, PAGE_NAMES.Subscriptions);
      });
  }

  loadSubscriptions() {
    this.subscriptionService.getAllSubscriptions(this.iCampaignId, this.iClientId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.subscriptions) {
          this.subscriptions = response.subscriptions.map((x:any) => {x.dms = x.campaigns[0].dms; x.salesrep = x.campaigns[0].salesRep; return x});
        }
      });
  }

  onAddClicked() {
    this.router.navigate(['pages/shop/']);
  }

  onClick(event: any) {
    this.router.navigate(['/pages/subscriptions', event.id]);
  }

}