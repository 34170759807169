<div class="modal-box" cdkDrag>
    <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
        <h4 class="modal-title">Merge Clients</h4>
        <h5 class="modal-title" *ngIf="step==1">Step 1 - Select Values.</h5>
        <h5 class="modal-title" *ngIf="step==2">Step 2 - Confirm and Merge.</h5>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="addClientForm" (ngSubmit)="onSubmit()">
        <div class="modal-body" *ngIf="step==1">
            <table class="table tbl-client">
                <thead>
                    <tr>
                        <th class="label"><label>Primary Record?</label></th>
                        <th *ngFor="let client of clients">
                            <label class="radio-label">
                                <input type="radio" value="{{ client.id }}" formControlName="id"
                                    (click)="setPrimaryRecord(client.id)" style="height: 16px; width: 16px;">
                            </label>
                            <button type="button" (click)="setPrimaryRecord(client.id)" class="btn btn-link">
                                Select All
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="label"><label>First Name</label></th>
                        <td *ngFor="let client of clients">
                            <label class="radio-label">
                                <input type="radio" value="{{ client.firstName }}" formControlName="firstName"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ client.firstName }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Last Name</label></td>
                        <td *ngFor="let client of clients">
                            <label class="radio-label">
                                <input type="radio" value="{{ client.lastName }}" formControlName="lastName"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ client.lastName }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Address</label></td>
                        <td *ngFor="let client of clients">
                            <label class="radio-label">
                                <input type="radio" value="{{ client.brandAddress }}" formControlName="brandAddress"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ client.brandAddress }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Company</label></td>
                        <td *ngFor="let client of clients">
                            <label class="radio-label">
                                <input type="radio" value="{{ client.brandId }}" formControlName="brandId"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ client.brand }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>DMS</label></td>
                        <td *ngFor="let client of clients">
                            <label class="radio-label">
                                <input type="radio" value="{{ client.dmsId }}" formControlName="dmsId"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ client.dmsName }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Sales Rep</label></td>
                        <td *ngFor="let client of clients">
                            <label class="radio-label">
                                <input type="radio" value="{{ client.salesRepId }}" formControlName="salesRepId"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ client.salesRep }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Phone #</label></td>
                        <td *ngFor="let client of clients">
                            <label class="radio-label">
                                <input type="radio" value="{{ client.phone }}" formControlName="phone"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ client.phone }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Email</label></td>
                        <td *ngFor="let client of clients">
                            <label class="radio-label">
                                <input type="radio" value="{{ client.email }}" formControlName="email"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ client.email }}</span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table> 
        </div>
        <div class="modal-footer" *ngIf="step==1" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" class="btn btn-secondry" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Cancel</button>
            <button [disabled]="!(addClientForm.value.id)" type="button" class="blue-button" (click)="nextStep()" title="Please select primary record to proceed!">Next</button>
        </div>
        <div class="modal-body" *ngIf="step == 2">
            <div class="row mb-3">
                <label class="col-sm-3" for="firstName">First Name</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="firstName"
                        [value]="addClientForm.value.firstName"
                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="Enter first name">
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">first name is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="lastName">Last Name</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="lastName"
                        [value]="addClientForm.value.lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
                        placeholder="Enter last name">
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">last name is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="brandAddress">Address</label>
                <div class="col-sm-9">
                    <textarea type="text" class="form-control" rows="3" formControlName="brandAddress"
                        [value]="addClientForm.value.brandAddress"
                        [ngClass]="{ 'is-invalid': submitted && f.brandAddress.errors }"
                        placeholder="enter address info"> </textarea>
                    <div *ngIf="submitted && f.brandAddress.errors" class="invalid-feedback">
                        <div *ngIf="f.brandAddress.errors.required">address is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="brandId">Company</label>
                <div class="col-sm-9">
                    <select [ngClass]="{ 'is-invalid': submitted && f.brandId.errors }" formControlName="brandId"
                        class="form-control form-select select-custom">
                        <option *ngFor="let comp of allCompanies" [ngValue]="comp.id"
                            [selected]="addClientForm.value.brandId == comp.id">{{comp.name}}</option>
                    </select>
                    <div *ngIf="submitted && f.brandId.errors" class="invalid-feedback">
                        <div *ngIf="f.brandId.errors.required">company is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="dmsId">DMS</label>
                <div class="col-sm-9">
                    <select [ngClass]="{ 'is-invalid': submitted && f.dmsId.errors }" formControlName="dmsId"
                        class="form-control form-select select-custom">
                        <option *ngFor="let dms of allDms" [ngValue]="dms.id"
                            [selected]="addClientForm.value.dmsId == dms.id">{{dms.firstName}} {{dms.lastName}}</option>
                    </select>
                    <div *ngIf="submitted && f.dmsId.errors" class="invalid-feedback">
                        <div *ngIf="f.dmsId.errors.required">dms is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="salesRepId">Sales Rep</label>
                <div class="col-sm-9">
                    <select [ngClass]="{ 'is-invalid': submitted && f.salesRepId.errors }" formControlName="salesRepId"
                        class="form-control form-select select-custom">
                        <option *ngFor="let rep of allSalesReps" [ngValue]="rep.id"
                            [selected]="addClientForm.value.salesRepId == rep.id">{{rep.firstName}} {{rep.lastName}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.salesRepId.errors" class="invalid-feedback">
                        <div *ngIf="f.salesRepId.errors.required">sales rep is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="phone">Phone #</label>
                <div class="col-sm-9">
                    <input type="tel" class="form-control" formControlName="phone" [value]="addClientForm.value.phone"
                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="Enter phone number">
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">phone number is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="email">Email</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="email" [value]="addClientForm.value.email"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Enter email address">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">email is required</div>
                        <div *ngIf="f.email.errors.email">email is invalid</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" *ngIf="step==2" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" class="btn btn-secondry" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Cancel</button>
            <div style="display: flex; flex-direction: row; justify-content: end;">
                <button type="button" class="blue-button" style="max-width: 55.97px !important; min-width: 0px !important;" (click)="previousStep()">Back</button>
                <button type="submit" class="btn btn-warning" style="margin-left: 8px; box-shadow: none !important;">Save</button>
            </div>
        </div>
    </form>
</div>