<div class="modal-box" cdkDrag>
    <form [formGroup]="addCompanyForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <h4 class="modal-title">{{ id === null ? 'Add' :'Edit'}} Company</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <label class="col-sm-3" for="name">Name</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="name"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Enter Company Name">
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="companyOwnerId">Company Owner</label>
                <div class="col-sm-9">
                    <ng-select [ngClass]="{ 'is-invalid': submitted && f.companyOwnerId.errors }" placeholder="Select"
                        [items]="allCompanyOwners" bindLabel="fullName" bindValue="id" formControlName="companyOwnerId">
                    </ng-select>
                    <div *ngIf="submitted && f.companyOwnerId.errors" class="invalid-feedback">
                        <div *ngIf="f.companyOwnerId.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-6">
                    <div class="row">
                        <label class="col-sm-6" for="contactFirstName">Contact First Name</label>
                        <div class="col-sm-6">
                            <input type="text" class="form-control" formControlName="contactFirstName"
                                [ngClass]="{ 'is-invalid': submitted && f.contactFirstName.errors }"
                                placeholder="Enter Contact First Name">
                            <div *ngIf="submitted && f.contactFirstName.errors" class="invalid-feedback">
                                <div *ngIf="f.contactFirstName.errors.required">Required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <label class="col-sm-6" for="contactLastName">Contact Last Name</label>
                        <div class="col-sm-6">
                            <input type="text" class="form-control" formControlName="contactLastName"
                                [ngClass]="{ 'is-invalid': submitted && f.contactLastName.errors }"
                                placeholder="Enter Contact Last Name">
                            <div *ngIf="submitted && f.contactLastName.errors" class="invalid-feedback">
                                <div *ngIf="f.contactLastName.errors.required">Required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="contactEmail">Contact Email</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="contactEmail"
                        [ngClass]="{ 'is-invalid': submitted && f.contactEmail.errors }" placeholder="Enter Contact Email">
                    <div *ngIf="submitted && f.contactEmail.errors" class="invalid-feedback">
                        <div *ngIf="f.contactEmail.errors.required">Required</div>
                        <div *ngIf="f.contactEmail.errors.email">Email is not valid</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="contactPhone">Contact Phone</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="contactPhone"
                        [ngClass]="{ 'is-invalid': submitted && f.contactPhone.errors }" placeholder="Enter Contact Phone">
                    <div *ngIf="submitted && f.contactPhone.errors" class="invalid-feedback">
                        <div *ngIf="f.contactPhone.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="marketingManagers">Marketing Managers</label>
                <div class="col-sm-9">
                    <ng-select placeholder="Select" [multiple]="true" [items]="allMarketingManagers" bindLabel="fullName"
                        bindValue="id" formControlName="marketingManagers">
                    </ng-select>
                </div>
            </div>

        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Cancel</button>
            <button *ngIf="isAdmin || canWrite || canCreate" type="submit" class="blue-button">SAVE</button>
        </div>
    </form>
</div>