import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';
import { COUPON_TYPES, PAGE_NAMES, ROLES_TYPE, RESTRICTION_TYPES } from '../../../core/helpers/constants';
import { createGuid, getRolesForPage } from '../../../core/helpers/utility';
import { IClient } from '../../../core/models/clients.models';
import { ICoupon } from '../../../core/models/coupon.models';
import { ISubscription } from '../../../core/models/datafeed.models';
import { IProductMain } from '../../../core/models/products.models';
import { Utility } from '../../../shared/utility';
import { AppService } from '../../../store/app.service';
import { CouponsApiService } from '../coupon.apiservice';
@Component({
  selector: 'app-edit-coupon',
  templateUrl: './edit-coupon.component.html',
  styleUrls: ['./edit-coupon.component.scss']
})
export class EditCouponComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public COUPON_TYPES = COUPON_TYPES;
  public RESTRICTION_TYPES = RESTRICTION_TYPES;

  couponAmountTitle = 'Coupon Amount ($)';
  haveNoEndDate: boolean = false;
  minUsage = 1;

  editCouponForm!: FormGroup;
  submitted = false;

  allSubscriptions = new Array<ISubscription>();
  allClients = new Array<IClient>();
  clientsFiltered: Array<Array<IClient>> = new Array<Array<IClient>>();
  subscriptionsFiltered: Array<Array<ISubscription>> = new Array<Array<ISubscription>>();

  allProducts: Array<IProductMain> = new Array<IProductMain>();
  productsIncludeSource: Array<IProductMain> = new Array<IProductMain>();
  productsExcludeSource: Array<IProductMain> = new Array<IProductMain>();

  isAdmin: boolean = false;
  isExternal: boolean = false;
  canDelete: boolean = false;
  canRead: boolean = false;
  canWrite: boolean = false;
  options: any = null;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private couponsApiService: CouponsApiService,
    private appService: AppService) {


    this.editCouponForm = this.formBuilder.group({
      id: [null],
      usageLimit: [null, [Validators.max(999999), Validators.min(0)]],
      code: ['', [Validators.required, Validators.maxLength(50)]],
      description: [''],
      type: [COUPON_TYPES[0].value],
      amount: ['', [Validators.required]],
      startDate: [null, [Validators.required]],
      expiryDate: [null],
      restrictionType:RESTRICTION_TYPES[0].value,
      usageWithOtherCoupons: [false],
      usageLimitSubscriptions: [null],
      usageLimitClients: [null],
      includeProductsIds: [[]],
      excludeProductsIds: [[]],
      cbNoExpiryDate: [this.haveNoEndDate]

    });
  }





  ngOnInit(): void {

    this.f.type.valueChanges
      .pipe(takeUntil(this.destroyed$)
      ).subscribe(value => {
        if (value === 1)
          this.couponAmountTitle = 'Coupon Amount ($)';
        else
          this.couponAmountTitle = 'Coupon Amount (%)';
      });
    this.f.includeProductsIds.valueChanges
      .pipe(takeUntil(this.destroyed$)
      ).subscribe(prods => {
        this.productsExcludeSource = this.allProducts.filter(x => !prods.includes(x.id));
      });
    this.f.excludeProductsIds.valueChanges
      .pipe(takeUntil(this.destroyed$)
      ).subscribe(prods => {
        this.productsIncludeSource = this.allProducts.filter(x => !prods.includes(x.id));
      });
    this.f.expiryDate.valueChanges
      .pipe(takeUntil(this.destroyed$)
      ).subscribe(val => {
        if (val !== "" && val !== null && val !== undefined) {
          this.haveNoEndDate = false;
          this.editCouponForm.patchValue({ cbNoExpiryDate: false })
        }
      });
    this.f.restrictionType.valueChanges
      .pipe(takeUntil(this.destroyed$)
      ).subscribe(value => {
        if(value === 1){
          this.editCouponForm.patchValue({includeProductsIds: []});
          this.editCouponForm.patchValue({excludeProductsIds: []});
        }
      });


    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isExternal = u.type == ROLES_TYPE.External;
        var r = getRolesForPage(u.roles, PAGE_NAMES.Coupons);
        this.isAdmin = r.isAdmin;
        this.canRead = r.canRead;
        this.canWrite = r.canWrite;
        this.canDelete = r.canDelete;
        if (r.options)
          this.options = JSON.parse(r.options!)
      })
    let id = this.route.snapshot.paramMap.get('id');
    this.appService.setPageTitle$(`Coupons - ${id ? 'Edit' : 'Add'}`);
    if (id) {
      this.getCouponDetails(id);
    }
    else {
      this.haveNoEndDate = false;
      this.editCouponForm.patchValue({ startDate: new Date() });
    }
    this.appService.getAllProductsFeed$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((prods: Array<IProductMain>) => {
        this.allProducts = prods;
        this.productsIncludeSource = [...prods.filter(y => !this.f.excludeProductsIds.value.includes(y.id))];
        this.productsExcludeSource = [...prods.filter(y => !this.f.includeProductsIds.value.includes(y.id))];
      })
    this.appService.fetchAllProductsFeed();
  }

  getCouponDetails(id: any) {
    this.couponsApiService.getCouponDetails(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.editCouponForm.patchValue(response.data);
        if (response.data.expiryDate === null) {
          this.haveNoEndDate = true
          this.editCouponForm.patchValue({ cbNoExpiryDate: true })
        }
      });
  }

  get f() { return this.editCouponForm.controls; }

  editRow() { }

  deleteRows() { }
  onSubLimitChange() {
    this.validateUsageLimit();
  }

  validateUsageLimit() {
    let total = this.f.usageLimit.value;
    if (_.isNull(total))
      return;
    let subUsage = this.f.usageLimitSubscriptions.value ?? 0;
    let clUsage = this.f.usageLimitClients.value ?? 0;
    if (total <= (subUsage + clUsage)) {
      let diff = (subUsage + clUsage) - total
      this.f.usageLimit.setValue(total + diff);
    }
    this.f.usageLimit.setValidators([Validators.required, Validators.min(subUsage + clUsage)]);
    this.f.usageLimit.updateValueAndValidity();
    this.minUsage = subUsage + clUsage;

  }


  onClientRowClick(evt: any) {
    this.router.navigate(['/pages/client-details', evt.id]);
  }
  onNoEndDateChange(evt: any) {
    var val = evt.target.checked;
    this.haveNoEndDate = val;
    if (val) {
      this.editCouponForm.patchValue({ expiryDate: null })
    }
  }
  onSubmit() {
    this.submitted = true;
    if(this.f.restrictionType.value == 2 && this.f.includeProductsIds.value.length == 0 && this.f.excludeProductsIds.value.length == 0){
      return;
    }
    if (this.editCouponForm.invalid)
      return;
    if (this.f.id.value === null)
      this.f.id.setValue(createGuid())
    let formValues: ICoupon = this.editCouponForm.value;

    formValues = this.trimObjectStrings(formValues);


    formValues.startDate = Utility.getDateFromDateTime(formValues.startDate);
    if (formValues.expiryDate)
      formValues.expiryDate = Utility.getDateFromDateTime(formValues.expiryDate);
    this.couponsApiService.saveCoupon(formValues)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.router.navigate(['/pages/coupons']);
      });
  }
  decimelFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  // This function will recursively iterate through the object
  trimObjectStrings(obj: any): any {
    // Iterate over each property of the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        
        if (typeof value === 'string') {
          // Trim the string if the value is a string
          obj[key] = value.trim();
        } else if (typeof value === 'object' && value !== null) {
          // Recursively call the function if the value is an object (or array)
          this.trimObjectStrings(value);
        }
      }
    }
    return obj;
  }

  ngAfterViewInit() {
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
