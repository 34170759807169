import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createGuid } from '../../../core/helpers/utility';
import { INotes } from '../../../core/models/campaigns.model';
import { ISalesRep } from '../../../core/models/datafeed.models';
import { DataFeedService } from '../../api/datafeed.service';
import * as util from '../../../core/helpers/utility'
import { ROLES_TYPE } from '../../../core/helpers/constants';

@Component({
  selector: 'modal-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteModal implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oSaveClicked = new EventEmitter<any>();

  allAssignees: Array<ISalesRep> = new Array<ISalesRep>();

  addNoteForm!: FormGroup;
  submitted = false;

  @Input() iClientId: string = '';
  @Input() iCampaignId: string = '';
  @Input() iLeadId: string = '';
  @Input() iType: number = 1;
  @Input() iActiveUserExternal: number = ROLES_TYPE.External;
  @Input() iIsAdmin: boolean = true;
  @Input() set iNote(note: INotes) {
    if (note) {
      this.addNoteForm.patchValue(note);
      if (note.noteFiles)
        this.files = note.noteFiles;
    }
  }

  constructor(
    private dataFeedService: DataFeedService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder) {
    this.addNoteForm = this.formBuilder.group({
      id: [null],
      type: [null],
      campaignId: [null],
      clientId: [null],
      leadId: [null],
      assignedUserId: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: [null, [Validators.required]],
      isInternal: [null],
    });

    this.activeUserId = util.getActiveUser().id;
    if (this.activeUserId) {
      this.addNoteForm.patchValue({
        assignedUserId: this.activeUserId
      });
    }
    this.loadDetails();
  }
  get f() { return this.addNoteForm.controls; }

  activeUserId = '';
  assignedUserFullname = '';

  ngOnInit() { }
  loadDetails() {
    this.dataFeedService.getAllNotesAssignees()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allAssignees = [...response.data];
          let assignedUserId = this.f.assignedUserId.value ?? this.activeUserId;
          if (assignedUserId) {
            let user = this.allAssignees.find(f => f.id == assignedUserId);
            if (user)
              this.assignedUserFullname = user.firstName + ' ' + user.lastName;
          }
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    if (this.addNoteForm.invalid)
      return;
    if (this.f.id.value === null)
      this.f.id.setValue(createGuid());
    var request = this.addNoteForm.value;

    request = this.trimObjectStrings(request);

    request.campaignId = this.iCampaignId;
    request.clientId = this.iClientId;
    request.leadId = this.iLeadId;
    request.type = this.iType;
    request.addFiles = this.files.filter(x => x.link === undefined).map(x => { return { name: x.name, content: x.content } });
    request.removeFiles = this.removedFiles;
    this.oSaveClicked.emit(request);
  }

  // This function will recursively iterate through the object
  trimObjectStrings(obj: any): any {
    // Iterate over each property of the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        
        if (typeof value === 'string') {
          // Trim the string if the value is a string
          obj[key] = value.trim();
        } else if (typeof value === 'object' && value !== null) {
          // Recursively call the function if the value is an object (or array)
          this.trimObjectStrings(value);
        }
      }
    }
    return obj;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  files: any[] = [];
  removedFiles: any[] = [];
  onSelect(event: any) {
    for (var i = 0; i < event.addedFiles.length; i++) {
      var file = event.addedFiles[i];
      var that = this.files;
      this.readFile(file).then((fileContents: any) => {
        file.content = fileContents;
        that.push(file);
      })
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
    if (event.id) {
      this.removedFiles.push(event.id);
    }
  }

  readFile(file: File): any {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        var result: any = (e.target as FileReader).result;
        return resolve(result);
      };
      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };
      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }
      reader.readAsDataURL(file);
    });
  }
}