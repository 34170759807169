import { Injectable } from '@angular/core';
import { IClient } from '../../core/models/clients.models';
import { HttpService } from '../../shared/http/base.http';
@Injectable()
export class ClientsApiService extends HttpService {
   getAllClients(request: any) {
      return this.post<any>('/clients/getAllClients', request);
   }
   getAllProspectClients(request: any) {
      return this.post<any>('/clients/getAllProspectClients', request);
   }
   getClientDetails(id: any) {
      return this.get<IClient>('/clients/getClientDetails?id=' + id)
   }
   saveClient(data: IClient) {
      return this.post('/clients/saveClient', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   deleteClient(ids: Array<string>) {
      return this.post('/clients/deleteClient', ids, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   mergeClients(mergedClient: any, primaryClientId: string, clientIdsToMerge: string[]) {
      let url_ = "/clients/mergeclients?";
      if (primaryClientId !== undefined && primaryClientId !== null)
         url_ += "primaryClientId=" + encodeURIComponent("" + primaryClientId) + "&";
      if (clientIdsToMerge !== undefined && clientIdsToMerge !== null)
         clientIdsToMerge && clientIdsToMerge.forEach(item => { url_ += "clientIdsToMerge=" + encodeURIComponent("" + item) + "&"; });

      url_ = url_.replace(/[?&]$/, "");
      // const content_ = JSON.stringify(mergedClient);

      return this.post(url_, mergedClient, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   async exportClients(request: any) {
      return await this.postDownload('/clients/export', request);
   }

   async exportProspectClients(request: any) {
      return await this.postDownload('/clients/exportProspect', request);
   }
}
