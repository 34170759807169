import { NgModule } from "@angular/core";
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FlatpickrModule } from 'angularx-flatpickr';
import { MatIconModule } from '@angular/material/icon';
import { SpecialDatatableComponent } from "./special-datatable.component";
import { SpecialDataTableSortableDirective } from "./special-datatable-sortable.directive";

@NgModule({
    declarations: [
      SpecialDatatableComponent,
      SpecialDataTableSortableDirective,
    ],
    imports: [
        CommonModule,
        FeatherModule.pick(allIcons),
        RouterModule,
        NgbModule,
        FormsModule,
        MatIconModule,
        ReactiveFormsModule,
        FlatpickrModule.forRoot(),
    ],
    exports: [SpecialDatatableComponent]
  })
  export class SpecialDatatableModule { }