import { PAGE_NAMES } from '../../core/helpers/constants';
import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'MENUITEMS.MENU.TEXT',
    //     isTitle: true
    // },
    {
        id: 11,
        label: 'MENUITEMS.SHOP.TEXT',
        icon: 'shopping-bag',
        link: '/pages/shop',
        pageName: PAGE_NAMES.Shop
    },
    {
        id: 1,
        label: 'MENUITEMS.HOME.TEXT',
        icon: 'home',
        link: '/pages/dashboard',
        pageName: PAGE_NAMES.Dashboard
    },
    {
        id: 4,
        label: 'MENUITEMS.CAMPAIGNS.TEXT',
        icon: 'globe',
        link: '/pages/campaigns',
        pageName: PAGE_NAMES.Campaigns
    },
    {
        id: 2,
        label: 'MENUITEMS.TASKS.TEXT',
        icon: 'list',
        link: '/pages/tasks',
        pageName: PAGE_NAMES.Tasks
    },
    {
        id: 5,
        label: 'DASHBOARD',
        icon: 'layout',
        link: '/pages/reports',
        pageName: PAGE_NAMES.Reports
    },
    {
        id: 14,
        label: 'MENUITEMS.SUBSCRIPTIONS.TEXT',
        icon: 'refresh-cw',
        link: '/pages/subscriptions',
        pageName: PAGE_NAMES.Subscriptions
    },
    {
        id: 15,
        label: 'ORDERS',
        icon: 'dollar-sign',
        link: '/pages/orders',
        pageName: PAGE_NAMES.Orders
    },
    {
        id: 6,
        label: 'MENUITEMS.LEADS.TEXT',
        icon: 'bar-chart-2',
        link: '/pages/leads',
        pageName: PAGE_NAMES.Leads
    },
    {
        id: 9,
        label: 'MENUITEMS.PROSPECTS.TEXT',
        icon: 'user-plus',
        pageName: PAGE_NAMES.Prospects,
        link: '/pages/prospects',
    },
    {
        id: 8,
        label: 'MENUITEMS.CLIENTS.TEXT',
        icon: 'user',
        link: '/pages/clients',
        pageName: PAGE_NAMES.Clients
    },
    {
        id: 10,
        label: 'MENUITEMS.USERS.TEXT',
        icon: 'server',
        link: '/pages/users',
        pageName: PAGE_NAMES.Users
    },
    {
        id: 7,
        label: 'MENUITEMS.COMPANIES.TEXT',
        icon: 'briefcase',
        link: '/pages/companies',
        pageName: PAGE_NAMES.Companies
    },
    {
        id: 16,
        label: 'MENUITEMS.SEARCHES.TEXT',
        icon: 'search',
        link: '/pages/searches',
        pageName: PAGE_NAMES.Searches
    },
    {
        id: 12,
        label: 'MENUITEMS.PRODUCTS.TEXT',
        icon: 'package',
        link: '/pages/products',
        pageName: PAGE_NAMES.Products
    },
    {
        id: 17,
        label: 'MENUITEMS.TAGS.TEXT',
        icon: 'tag',
        link: '/pages/tags',
        pageName: PAGE_NAMES.Tags
    },
    {
        id: 13,
        label: 'MENUITEMS.COUPONS.TEXT',
        icon: 'percent',
        link: '/pages/coupons',
        pageName: PAGE_NAMES.Coupons
    },
    // {
    //     id: 3,
    //     label: 'MENUITEMS.EMAIL.TEXT',
    //     icon: 'mail',
    //     link: '/pages/emails',
    //     pageName: PAGE_NAMES.Emails
    // },
    // {
    //     id: 17,
    //     label: 'MENUITEMS.SETTINGS.TEXT',
    //     icon: 'settings',
    //     link: '',
    //     pageName: PAGE_NAMES.Settings
    // },
    {
        id: 19,
        label: 'AUTOMATIONS',
        faIcon: 'fas fa-magic fa-lg',
        name: 'automations',
        link: '/automations',
        openOnNewPage: true,
        pageName: PAGE_NAMES.Automations
    },
    {
        id: 18,
        label: 'FORMS',
        icon: 'book-open',
        name: 'forms',
        link: 'https://reachagency.co/forms',
        openOnNewPage: true,
        pageName: PAGE_NAMES.Forms
    },
    {
        id: 20,
        label: 'MY ACCOUNT',
        faIcon: 'fas fa-clipboard-user fa-lg',
        link: '/pages/myaccount',
        pageName: PAGE_NAMES.MyAccount
    },
    {
        id: 21,
        label: 'PARTNERS',
        faIcon: 'fa fa-users',
        link: 'https://reach.marketing/partners/',
        openOnNewPage: true,
        name: 'partners'
    },
    {
        id: 22,
        label: 'MENUITEMS.LOGOUT.TEXT',
        faIcon: 'fas fa-sign-out-alt fa-lg',
        name: 'logout'
    },
];
