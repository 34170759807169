<div class="modal-box" cdkDrag>
    <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <h4 class="modal-title">{{ id === null ? 'Add' :'Edit'}} User</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <label class="col-sm-3" for="firstName">First Name</label>
                <div class="col-sm-9">
                    <input autocomplete="new-password" type="text" class="form-control" formControlName="firstName"
                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="Enter first name">
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="lastName">Last Name</label>
                <div class="col-sm-9">
                    <input autocomplete="new-password" type="text" class="form-control" formControlName="lastName"
                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" placeholder="Enter last name">
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="password">Password</label>
                <div class="col-sm-9">
                    <input autocomplete="new-password" type="password" class="form-control" formControlName="password"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="please enter password">
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="confirmPassword">Confirm Password</label>
                <div class="col-sm-9">
                    <input type="password" class="form-control" formControlName="confirmPassword"
                        [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
                        placeholder="please confirm your password">
                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors.required">please confirm your password</div>
                        <div *ngIf="f.confirmPassword.hasError('mismatchedPasswords')">passwords does not match</div>
                    </div>
                </div>
            </div>
            <div *ngIf="isAdmin || activeUser.type === ROLES_TYPE.Internal" class="row mb-3"
                [ngClass]="{'radio-disabled':id!==null && f.type.value!== null  }">
                <label class="col-sm-3" for="confirmPassword">User Type</label>
                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-check form-check-right mb-3">
                                <input class="form-check-input" [value]="1" id="formRadiosRight1" type="radio"
                                    formControlName="type">
                                <label class="form-check-label" for="formRadiosRight1">
                                    Internal
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-check form-check-right mb-3">
                                <input class="form-check-input" [value]="2" id="formRadiosRight2" type="radio"
                                    formControlName="type">
                                <label class="form-check-label" for="formRadiosRight2">
                                    External
                                </label>
                            </div>
                        </div>

                    </div>
                
                </div>
            </div>
            
            <div *ngIf="((isAdmin || activeUser.type === ROLES_TYPE.Internal) && (f.type.value==2))" class="row mb-3">
                <label class="col-sm-3" for="showShop">Show Shop </label>
                <div class="col-sm-9">
                    <input type="checkbox" formControlName="showShop" class="form-check-input" />
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="phone">Phone #</label>
                <div class="col-sm-9">
                    <input type="tel" class="form-control" formControlName="phone"
                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="Enter phone number">
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="email">Email</label>
                <div class="col-sm-9">
                    <input type="email" class="form-control" formControlName="email"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Enter email address">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Required</div>
                        <div *ngIf="f.email.errors.email">email is invalid</div>
                    </div>
                </div>
            </div>
            <div *ngIf="isAdmin ||  (canChangeRoles && (activeUser.id !== f.id.value))" class="row mb-3">
                <label class="col-sm-3">Add Role</label>
                <div class="col-sm-9">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <i-feather (click)="addRole()" name="plus"></i-feather>
                        <label style="margin-bottom: 0; padding-left:5px">Assign one or more roles for this user</label>
                    </div>
                    <div *ngIf="submitted && f.roles.errors" class="invalid-feedback" style="display: block ;">
                        <div *ngIf="f.roles.errors.required">One or more roles are required!</div>
                    </div>
                </div>
            </div>
            <ng-template #tipContent>
                <div *ngIf="showTooltip" fxLayout="column">
                    <p>If you select only a Client record, this user will be able to see all Campaigns under this Client</p>
                    <p>If you also select a Campaign, the user will only see that one Campaign</p>
                    <p>You can add multiple Campaigns by adding additional Roles</p>
                </div>
            </ng-template>
            <div *ngIf="isAdmin ||  (canChangeRoles && (activeUser.id !== f.id.value))" class="row mb-3">

                <div formArrayName="roles">
                    <div class="mb-3" *ngFor="let c of roles.controls; let i=index">
                        <div [formGroupName]="i">
                            <div class="row">
                                <label class="col-sm-3 col-form-label">Role</label>
                                <input type="hidden" formControlName="id" />
                                <div (shown)="onAddUserTooltipShown(i)" [ngbTooltip]="showTooltip[i] ? tipContent:''"
                                    tooltipClass="tooltip-roles-ddl" container="body"
                                    [ngClass]="{ 'col-sm-8' : (rolesOptions[i].Company === undefined && rolesOptions[i].Client === undefined), 'col-sm-4': (rolesOptions[i].Company !==undefined || rolesOptions[i].Client !==undefined)  }">
                                    <ng-select tooltip="voila" (change)="onRoleChange($event,i)"
                                        [ngClass]="{ 'is-invalid': submitted && roles.at(i).get('roleId')?.errors }"
                                        formControlName="roleId">
                                        <ng-option [attr.roleCode]="r.code"
                                            *ngFor="let r of ( (rolesFiltered[i] | async) || [] | P_RolesType_Filter: f.type.value)"
                                            [value]="r.id">{{r.title}} </ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && roles.at(i).get('roleId')?.errors" class="invalid-feedback">
                                        <div *ngIf="roles.at(i).get('roleId')?.errors?.required">Required</div>
                                    </div>
                                </div>
                                <div *ngIf="rolesOptions[i].Company !==undefined" class="col-sm-4">
                                    <ng-select
                                        [ngClass]="{ 'is-invalid': submitted && roles.at(i).get('companyId')?.errors}"
                                        placeholder="Select Company" (change)="onRoleCompanyChange($event,i)"
                                        [items]="allCompanies" bindLabel="name" bindValue="id" formControlName="companyId">
                                    </ng-select>
                                    <div *ngIf="submitted && roles.at(i).get('companyId')?.errors" class="invalid-feedback">
                                        <div *ngIf="roles.at(i).get('companyId')?.errors?.required">Required
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="rolesOptions[i].Client !==undefined && rolesOptions[i].Company !==undefined"
                                    class="col-sm-7"></div>
                                <div *ngIf="rolesOptions[i].Client !==undefined" class="col-sm-4">
                                    <ng-select (change)="onRoleClientChange($event,i)"
                                        [ngClass]="{ 'is-invalid': submitted && roles.at(i).get('clientId')?.errors}"
                                        placeholder="Select Client" [items]="clientsFiltered[i]" bindLabel="fullName"
                                        bindValue="id" formControlName="clientId">
                                    </ng-select>
                                    <div *ngIf="submitted && roles.at(i).get('clientId')?.errors" class="invalid-feedback">
                                        <div *ngIf="roles.at(i).get('clientId')?.errors?.required">Required
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="rolesOptions[i].Campaign !==undefined && rolesOptions[i].Campaign !==undefined"
                                    class="col-sm-7"></div>
                                <div *ngIf="rolesOptions[i].Campaign !==undefined" class="col-sm-4">
                                    <ng-select
                                        [ngClass]="{ 'is-invalid': submitted && roles.at(i).get('campaignId')?.errors}"
                                        placeholder="Select Campaign" [items]="campaignsFiltered[i]" bindLabel="name"
                                        bindValue="id" formControlName="campaignId">
                                    </ng-select>
                                    <div *ngIf="submitted && roles.at(i).get('campaignId')?.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="roles.at(i).get('campaignId')?.errors?.required">Required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <button type="button" class="btn btn-outline-danger btn-sm" (click)="deleteRole(i)">
                                        X
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="id === null &&(isAdmin || myRoles.indexOf(ROLES.SalesRep.Code) > -1)" class="row mb-3">
                <label class="col-sm-3" for="sendEmail">Email crendentials after save</label>
                <div class="col-sm-9">
                    <input type="checkbox" formControlName="sendEmail" class="form-check-input" />
                </div>
            </div>
        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <div fxLayout="row" fxLayoutAlign="start center">
                <button type="button" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Cancel</button>
            </div>
            <button style="float: left; margin-right: 12px; border: #add2ef solid 1px; color: #add2ef; padding: 8px 12px 8px 12px; font-size: 14px; min-height: 35px; min-width: 100px; border-radius: 4px;" *ngIf="id !== null" type="button" (click)="sendPasswordResetEmail()">Send Password Reset Email</button>
            <div>
                <button type="submit" class="blue-button">Save</button>
            </div>
        </div>
    </form>
</div>