<div class="example-box" cdkDrag>
    <div class="modal-header">
        <h4 class="modal-title">Card Info</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="margin-top: -40px">
        <app-product-checkout  [iAllMyClients]="iAllMyClients"></app-product-checkout>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="oSaveClick()"  class="btn btn-black">Pay</button>
        <button type="button" class="btn btn-secondry" (click)="activeModal.close('Close click')">Close</button>
    </div>
</div>