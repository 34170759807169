import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { IProductMain } from '../../core/models/products.models';
import { AppService } from '../../store/app.service';
import { ProductsApiService } from './products-api.service';
import { ProductsModalComponent } from '../../shared/modals/products-modal/products-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(
    private productApiService: ProductsApiService,
    private modalService: NgbModal,
    private _appService: AppService,
    private router: Router,
  ) { }

  openEditModal(evt: any) {

    const dialogRef = this.modalService.open(ProductsModalComponent, { size: 'lg' });
    if (evt.id === -1) {
      var d = this._appService.getActiveRoute$().value.url.split('/');
      evt.id = d[d.length - 1]
    }

    dialogRef.componentInstance.iId = evt.id;
    const dialogSubmitSubscription = dialogRef.componentInstance.oSaveClicked.subscribe((r: IProductMain) => {

      this.productApiService.saveProduct(r)
        .subscribe((response: any) => {
          if (response.good) {
            dialogRef.dismiss();
            this._appService.fetchAllProductsMain(null);
          }
        })
      dialogSubmitSubscription.unsubscribe();
    })

  }

  deleteProductForm() {
    var d = this._appService.getActiveRoute$().value.url.split('/');
    let id = d[d.length - 1]
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.productApiService.deleteProducts([id])
          .subscribe((r: any) => {
            if (r.good) {
              this.router.navigate(['/pages/products']);
            }
          })
      }
    });
  }
}
