import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { AppService } from '../../../store/app.service';
import { takeUntil } from 'rxjs/operators';
import { OrdersApiService } from '../../../pages/orders/orders.apiservice';

@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss']
})

export class AddOrderModal implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() subscriptionId!: string;
  @Output() oSaveClicked = new EventEmitter<any>();
  products!: any[];
  selectedProducts: string[] = [];
  addForm!: FormGroup;
  submitted = false;
  allSubscriptions: Array<any>;

  constructor(private appService: AppService,
    private ordersService: OrdersApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe) {

    this.allSubscriptions = new Array<any>();
    this.appService.getAllUserSubscription()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response) {
          this.allSubscriptions = response.map((x: any) => {
            x.value = x.numericId + " - " + x.campaigns[0].campaignName + " - " + x.campaigns[0].clientName + " - " + x.startDate
            return x;
          });
        }
      });
    this.appService.fetchAllUserSubscriptions();
  }
  ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      id: [null],
      subscriptionId: [this.subscriptionId, [Validators.required]],
      period: [null, [Validators.required]],
      amount: [null, [Validators.required]],
      isPaid: [null]
    });
  }

  get f() { return this.addForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.addForm.invalid)
      return;
    var period = this.addForm.value.period.split(' to ');
    if (period.length < 2) {
      this.addForm.controls['period'].patchValue('');
      return;
    }
    const request = {
      SubscriptionId: this.addForm.value.subscriptionId,
      Amount: this.addForm.value.amount.replace('$', '').replace(',', ''),
      From: period[0],
      To: period[1],
      IsPaid: this.addForm.value.isPaid ?? false
    };
    this.ordersService.addOrder(request).subscribe((response: any) => {
      if (response.good) {
        this.activeModal.close();
        this.oSaveClicked.emit();
      }
    });
  }

  transformAmount() {
    const control = this.addForm.controls['amount'];
    let amount = control.value;
    amount = amount.replace('$', '').replace(',', '');
    if (isNaN(+amount))
      amount = '';
    else
      amount = this.currencyPipe.transform(amount, '$');
    control.patchValue(amount);
  }
}