import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/http/base.http';

@Injectable()
export class SearchesApiService extends HttpService {
   getUserSearches() {
      return this.get('/SavedSearch/user')
   }

   getSavedSearch(searchId: string) {
      return this.get(`/SavedSearch?id=${searchId}`)
   }

   deleteUserSearch(searchId: string) {
      return this.delete(`/SavedSearch?id=${searchId}`)
   }

   addEditSearch(search: any) {
      return this.post(`/SavedSearch`, search)
   }
}