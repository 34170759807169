<div class="modal-box" cdkDrag>
    <form [formGroup]="addCampaignCheckForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <h4 class="modal-title">Campaign Check</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <label class="col-sm-3" for="title">Metrics<br>(Last 7 Days)</label>
                <div class="col-sm-9">
                    <table style="font-size: 11px;">
                        <tr>
                            <td><label for="impression">Impressions</label></td>
                            <td><input type="text" class="form-control" formControlName="impression"
                                [ngClass]="{ 'is-invalid': submitted && f.impression.errors }"></td>
                            <td><label for="leads">Leads</label></td>
                            <td><input type="text" class="form-control" formControlName="leads"
                                [ngClass]="{ 'is-invalid': submitted && f.leads.errors }"></td>
                            <td><label for="cpl">CPL</label></td>
                            <td><input type="text" prefix="$" mask="separator.4" thousandSeparator="" class="form-control" formControlName="cpl"
                                [ngClass]="{ 'is-invalid': submitted && f.cpl.errors }"></td>
                        </tr>
                        <tr>
                            <td><label for="ctr">CTR</label></td>
                            <td><input type="text" suffix="%" mask="separator.4" thousandSeparator="" class="form-control" formControlName="ctr"
                                [ngClass]="{ 'is-invalid': submitted && f.ctr.errors }"></td>
                            <td><label *ngIf="isGooglePlatform" for="cPMOrCPC">CPC</label><label *ngIf="!isGooglePlatform" for="cPMOrCPC">CPM</label></td>
                            <td><input type="text" prefix="$" mask="separator.4" thousandSeparator="" class="form-control" formControlName="cPMOrCPC"
                                [ngClass]="{ 'is-invalid': submitted && f.cPMOrCPC.errors }"></td>
                            <td><label *ngIf="isGooglePlatform" for="lFSROrLPCR">LPCR</label><label *ngIf="!isGooglePlatform" for="lFSROrLPCR">LFSR</label></td>
                            <td><input type="text" suffix="%" mask="separator.4" thousandSeparator="" class="form-control" formControlName="lFSROrLPCR"
                                [ngClass]="{ 'is-invalid': submitted && f.lFSROrLPCR.errors }"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="insightText">Insight</label>
                <div class="col-sm-9">
                    <textarea type="text" rows="3" class="form-control" formControlName="insightText"
                        [ngClass]="{ 'is-invalid': submitted && f.insightText.errors }"
                        placeholder="Enter Insight"> </textarea>
                    <div *ngIf="submitted && f.insightText.errors" class="invalid-feedback">
                        <div *ngIf="f.insightText.errors.required">Insight is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="changesText">Changes</label>
                <div class="col-sm-9">
                    <textarea type="text" rows="3" class="form-control" formControlName="changesText"
                        [ngClass]="{ 'is-invalid': submitted && f.changesText.errors }"
                        placeholder="Enter Changes"> </textarea>
                    <div *ngIf="submitted && f.changesText.errors" class="invalid-feedback">
                        <div *ngIf="f.changesText.errors.required">Changes is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="taskIdToCompleted">Complete Task</label>
                <div class="col-sm-9">
                    <ng-select [ngClass]="{ 'is-invalid': submitted && f.taskIdToCompleted.errors }" placeholder="None" [items]="tasksDataNotCompleted" bindLabel="title" bindValue="id"
                        formControlName="taskIdToCompleted">
                    </ng-select>
                    <div *ngIf="submitted && f.taskIdToCompleted.errors" class="invalid-feedback">
                        <div *ngIf="f.taskIdToCompleted.errors.required">please select an Task to Complete</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="addTaskDate">Add Task</label>
                <div class="col-sm-9">
                    <input [ngClass]="{ 'is-invalid': submitted && f.addTaskDate.errors }" mwlFlatpickr
                        [altInputClass]="'datetime'" [altInput]="true" [convertModelValue]="true" formControlName="addTaskDate"
                        class="form-control" />
                    <div *ngIf="submitted && f.addTaskDate.errors" class="invalid-feedback">
                        <div *ngIf="f.addTaskDate.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <table>
                    <tr>
                        <td style="padding-left: 10px;">
                            <div class="row">
                                <label class="col-sm-3" for="isQCCheck">Add QC Check</label>
                                <div class="col-sm-2">
                                    <input type="checkbox" formControlName="isQCCheck" class="form-check-input" />
                                </div>
                            </div>
                        </td>
                        <td style="text-align: right;">
                            <!-- <div class="row">
                                <label class="col-sm-6" for="isRedAlert">Add Red Alert</label>
                                <div class="col-sm-3">
                                    <input type="checkbox" formControlName="isRedAlert" class="form-check-input" />
                                </div>
                            </div> -->
                        </td>
                    </tr>
                </table>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="assignedUserId">Upload</label>
                <div class="col-sm-9">
                    <span>
                        <ngx-dropzone style="height: 80px;" [multiple]="false" (change)="onSelect($event)">
                            <ngx-dropzone-label>Drop or click to upload!</ngx-dropzone-label>
                        </ngx-dropzone>
                        <div class="row mb-3" style="margin: 10px;" *ngFor="let file of files">
                            <div class="col-sm-9">{{ file.name }}</div>
                            <div class="col-sm-3">
                                <a role="button" (click)="onRemove(file)">
                                    <i class="fas fa-trash-can"></i>
                                </a>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Cancel</button>
            <button type="submit" class="blue-button">SAVE</button>
        </div>
    </form>
</div>