import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanDeactivate,
  CanActivateChild,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as util from '../helpers/utility';
import { Constants, ROLES, ROLES_KEY } from '../helpers/constants';
import { Observable, of } from 'rxjs';
import * as _ from 'underscore';
import { AppService } from '../../store/app.service';
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private appService: AppService,
    private jwtHelperService: JwtHelperService,
  ) { }


  canActivate(route: ActivatedRouteSnapshot, url: any): Observable<boolean> {
    var token = util.getAuthToken();
    if (token === '') {
      this.router.navigate(['/account/login']);
      localStorage.removeItem(Constants.LS_AUTHENTICATION_KEY);
      return of(false);
    }
    if (token && !this.jwtHelperService.isTokenExpired(token)) {
      var tokenData = this.jwtHelperService.decodeToken(token);
      var r = tokenData[ROLES_KEY];
      var roles: Array<any> = [];
      if (typeof (r) === 'string')
        roles = [JSON.parse(r)];
      else
        roles = tokenData[ROLES_KEY].map((x: any) => JSON.parse(x))

      if (this.appService.getActiveUserRoles$().value.length == 0)
        this.appService.setActiveUserRoles$(roles);

      if (this.appService.getActiveUser$().value.id == null) {
        let u = util.getActiveUser();
        u.roles = roles;
        this.appService.setActiveUser$(u);
        if (u?.forcePasswordReset) {
          this.router.navigate(['/account/reset-password', token, true]);
        }
      }


      if (tokenData.code === ROLES.Admin.Code) /// admin access all
        return of(true);

      if (_.isEmpty(route.data)) // route is open for all
        return of(true);
      if ((route.data.roles).some((r: any) => roles.map(x => x.code).includes(r)))
        return of(true);
      else
        this.router.navigate(['/pages/dashboard']);

    }
    else this.router.navigate(['/login']);
    return of(false);
  }
  canActivateChild(route: ActivatedRouteSnapshot, url: any): boolean {
    const userRole = this.appService.getActiveUser$().value?.roles;
    let codes = userRole.map(x => x.code);
    if (codes.indexOf(ROLES.Admin.Code) > -1)
      return true;
    if (_.isEmpty(route.data)) // route is open for all
      return true;
    if ((route.data.roles as Array<number>).some(r => codes.includes(r)))
      return true;
    else
      this.router.navigate(['/pages/dashboard']);
    return false;
  }
}
