import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ReplaySubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ICampaign } from '../../../core/models/campaigns.model';
import { ReportsApiService } from '../../reports/reports.apiservice';
import { IReportingPeriod } from '../../../core/models/reporting.models';

@Component({
  selector: 'app-campaign-details-report-view',
  templateUrl: './campaign-details-report-view.component.html',
  styleUrls: ['./campaign-details-report-view.component.scss']
})
export class CampaignDetailsReportViewComponent implements OnInit {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() CampaignData: ICampaign = {} as ICampaign;
  @Input() ReportingPeriods: Array<IReportingPeriod> = [];

  reportUrl = '' as SafeResourceUrl;

  invalidCampaignId: boolean = false;

  constructor(private fb: FormBuilder,
    private domSanitizer: DomSanitizer,
    private ngxLoader: NgxUiLoaderService,
    private reportsService: ReportsApiService,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.reportForm = this.fb.group({
      key: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      stopDate: ['', [Validators.required]],
    });

    this.onReportPeriodChange("Past Week");
  }

  reportForm: FormGroup = this.fb.group({
    key: [null, [Validators.required]],
    startDate: [null, [Validators.required]],
    stopDate: [null, [Validators.required]],
  });

  get ReportLink() { return this.GenerateReportLink(); }

  reportformsubmitted = false;
  DisplayReport = false;
  onViewReportSubmit() {
    this.DisplayReport = true;
    // this.loadCompleted = false;

    this.reportformsubmitted = true;
    // if (this.CampaignData.platformCampaignId == null || this.CampaignData.platformCampaignId == "") {
    //   this.invalidCampaignId = true;
    // } else {
    //   this.invalidCampaignId = false;
    // }
    // if (this.reportForm.invalid) {
    //   return;
    // }
    this.ngxLoader.startLoader("loader-report");
    let reportLink = this.GenerateReportLink();
    if (reportLink) {
      //this.reportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(reportLink as string);
      this.reportsService.getReportContentByUrl(reportLink)
      .subscribe(responseUrl => {
        console.log(responseUrl);
        var x = document.getElementById("divReport");
        console.log(x);
        x?.querySelector('iframe')?.setAttribute("src", responseUrl);
        this.DisplayReport = true;
        //this.reportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(responseUrl.toString())
      });
      
    }
    else
      this.ngxLoader.stopLoader("loader-report");
  }

  openReportNewWindow(){
    let reportLink = this.GenerateReportLink();
    if (reportLink) {
      //this.reportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(reportLink as string);
      this.reportsService.getReportContentByUrl(reportLink)
      .subscribe(responseUrl => {
        console.log(responseUrl);
        window.open(responseUrl);
        //this.reportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(responseUrl.toString())
      });
      
    }
  }

  GenerateReportLink(): string | undefined {
    var form = this.reportForm.value;

    let campaignId = this.CampaignData.platformCampaignId;
    let accountId = this.CampaignData.platformAccountId;
    let platform = this.CampaignData.platform;
    let dateStart = form.startDate;
    let dateStop = form.stopDate;
    let reachCampaignID = this.CampaignData.id;

    // if (!accountId && !campaignId)
    //   return;

    if (accountId) {
      accountId = accountId.replace(/\ /g, '').replace(/\-/g, '');
    }

    //let url: string = 'https://reachme.co/AdsReports/AdsAccount?';   
    let url: string = `/Dashboard/AdsAccountNew?Dashboard=true`;
    url += '&';

    if(reachCampaignID){
      url += `ReachCampaignID=${reachCampaignID}`;
    }
    if (accountId) {
      accountId = accountId.replace(/\ /g, '').replace(/\-/g, '');
      if (reachCampaignID) { url += '&'; }
      url += `AccountID=${accountId}`;
    }
    if (campaignId) {
      if (reachCampaignID || accountId) { url += '&'; }
      url += `CampaignID=${campaignId}`;
    }
    if (platform) {
      if (reachCampaignID || accountId || campaignId) { url += '&'; }
      url += `Platform=${platform}`;
    }
    if (dateStart) {
      if (reachCampaignID || accountId || campaignId || platform) { url += '&'; }
      url += `DateStart=${dateStart}`;
    }
    if (dateStop) {
      if (reachCampaignID || accountId || campaignId || platform || dateStart) { url += '&'; }
      url += `DateStop=${dateStop}`;
    }

    return url;
  }

  get selectedReportPeriod(): string | undefined | null { return this.reportForm?.controls?.key?.value; }
  get datesReadonly(): boolean { return !(this.reportForm.controls.key.value == 'Custom'); }
  onReportPeriodChange(selectedPeriod: string) {
    let period = this.ReportingPeriods.find(f => f.key == selectedPeriod);

    let startDate = period?.startDate as string;
    let stopDate = period?.stopDate as string;

    this.reportForm = this.fb.group({
      key: [period?.key, [Validators.required]],
      startDate: [startDate, [Validators.required]],
      stopDate: [stopDate, [Validators.required]],
    });
  }

  onReportPeriodChanged(period: IReportingPeriod) {
    var form = this.reportForm.value;

    let platform = form.platform;
    if (!platform) platform = this.CampaignData.platform;

    let accountId = form.accountId;
    if (!accountId) accountId = this.CampaignData.platformAccountId;

    let campaignId = form.campaignId;
    if (!campaignId) campaignId = this.CampaignData.platformCampaignId;

    this.reportForm = this.fb.group({
      key: [period.key, [Validators.required]],
      startDate: [period.startDate, [Validators.required]],
      stopDate: [period.stopDate, [Validators.required]],
    });
  }

  FormatDate(iDate: Date | undefined): string | null {
    if (iDate) {
      let dtDate = new Date(iDate);
      return new Date(dtDate).toISOString().substring(0, 10);

      // var datePipe = new DatePipe('en-US');
      // let transformed = datePipe.transform(iDate, 'yyyy-MM-dd', 'UTC', 'en-US');

      // return transformedx
    }

    return null;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadCompleted = false;
  onFrameLoadComplete() {
    // if (this.reportUrl && !this.loadCompleted) {
    //   this.loadCompleted = true;
    //   this.ngxLoader.stopLoader("loader-report");
    // }
    // else {
    //   this.ngxLoader.stopLoader("loader-report");
    // }
  }
}