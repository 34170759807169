import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchesMainComponent } from './searches-main/searches-main.component';
import { SavedSearchesComponent } from './saved-searches/saved-searches.component';
import { NewSearchMainComponent } from './new-search/new-search-main/new-search-main.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { SearchesApiService } from './searches.apiservice';
import { DatatableModule } from '../../shared/components/datatable/datatable.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NewSearchFieldsComponent } from './new-search/new-search-fields/new-search-fields.component';
import { CampaignDatatableModule } from "../../shared/components/campaign-datatable/campaign-datatable.module";

@NgModule({
    declarations: [
        SearchesMainComponent,
        SavedSearchesComponent,
        NewSearchMainComponent,
        NewSearchFieldsComponent
    ],
    exports: [
        FlatpickrModule,
    ],
    providers: [SearchesApiService],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        NgbNavModule,
        FeatherModule.pick(allIcons),
        DatatableModule,
        FlatpickrModule.forRoot(),
        CampaignDatatableModule
    ]
})
export class SearchesModule { }
