import { CurrencyPipe, formatCurrency } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { AppService } from '../../../store/app.service';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { OrdersApiService } from '../../../pages/orders/orders.apiservice';

@Component({
  selector: 'app-add-order-subscription',
  templateUrl: './add-order-subscription.component.html',
  styleUrls: ['./add-order-subscription.component.scss']
})

export class AddOrderToSubscriptionModal implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() subscriptionId!: string;
  @Output() oSaveClicked = new EventEmitter<any>();
  @Input() products!: any[];
  selectedProducts: string[] = [];
  addForm!: FormGroup;
  submitted = false;
  allSubscriptions: Array<any>;
  totalOrderAmount: number = 0;

  constructor(private appService: AppService,
    private ordersService: OrdersApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe) {

    this.allSubscriptions = new Array<any>();
    
    // this.appService.getAllUserSubscription()
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe((response: any) => {
    //     if (response) {
    //       this.allSubscriptions = response.map((x: any) => {
    //         x.value = x.numericId + " - " + x.campaigns[0].campaignName + " - " + x.campaigns[0].clientName + " - " + x.startDate
    //         return x;
    //       });
    //     }
    //   });
    // this.appService.fetchAllUserSubscriptions();
  }
  ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      id: [null],
      subscriptionId: [this.subscriptionId, [Validators.required]],
      period: [null, [Validators.required]],
      amount: [null, [Validators.required]],
      isPaid: [null]
    });
    for (var i = 0; i < this.products.length; i++) {
      this.products[i].amount = 0; // Add "total": 2 to all objects in array
    }
    this.totalOrderAmount = 0;
  }

  get f() { return this.addForm.controls; }

  onSubmit() {
    this.submitted = true;
    var reqProducts = [];
    for (var i = 0; i < this.products.length; i++) {
      var amt = isNaN(this.products[i].amount) ? 0 : this.products[i].amount;
      reqProducts.push({productId : this.products[i].id, Amount : amt});
    }

    if(this.totalOrderAmount == 0){
      return;
    }

    var yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - (offset*60*1000))
    let todayAtUserComputer = yourDate.toISOString().split('T')[0];

    const request = {
      SubscriptionId: this.addForm.value.subscriptionId,
      TodayAtUserComputer: todayAtUserComputer,
      Products: reqProducts
    };
    this.ordersService.addOrderToSubscription(request).subscribe((response: any) => {
      if (response.good) {
        this.activeModal.close();
        if(response.error == ''){
          Swal.fire({
            title: 'Order Created',
            text: "Order has been successfully added to the subscription",
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          }).then((result) => {
            this.oSaveClicked.emit();
          })
        }
        else{
          Swal.fire({
            title: 'Order Created',
            text: "Order has been successfully added to the subscription. But There was an error processing payments",
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          }).then((result) => {
            this.oSaveClicked.emit();
          })
        }
        
      }
    });
  }

  transformAmount(control : any) {
    let amount = control.target.value;
    //amount = amount.replace('$', '').replace(',', '');
    if (isNaN(+amount)){
      amount = 0;
    }
    control.target.value = Number(amount);
    this.totalOrderAmount = 0;
    for (var i = 0; i < this.products.length; i++) {
      var amt:number = +this.products[i].amount;
      if(!isNaN(amt))
        this.totalOrderAmount = this.totalOrderAmount + amt;
    }
  }

  formatPrice(price: any) {
    return formatCurrency(price, 'en-US', '$')
  }
}