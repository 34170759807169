import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IWidget } from '../../core/models/widget.model';
import { HttpService } from '../../shared/http/base.http';

@Injectable()
export class DashboardApiService extends HttpService {
   getUserWidgets() {
      return this.get<IWidget[]>('/widget/all')
   }

   getWidgetValues(widgetId: string) {
      return this.get<IWidget>('/widget/value?widgetId=' + widgetId)
   }

   renameWidget(widgetId: string, newName: string) {
      return this.post<IWidget>(`/widget/rename?widgetId=${widgetId}&newname=${newName}`, null)
   }

   addWidget() {
      return this.post(`/widget`, null)
   }

   reorderWidget(widgetId: string, newOrder: number) {
      return this.get(`/widget/reorder?widgetId=${widgetId}&newOrder=${newOrder}`)
   }

   reorderWidgetItem(itemId: string, newOrder: number) {
      return this.get(`/widget/item/reorder?itemId=${itemId}&newOrder=${newOrder}`)
   }

   deleteWidget(widgetId: string) {
      return this.delete(`/widget?widgetId=${widgetId}`)
   }

   getWidgetSavedSearches() {
      return this.get(`/widget/savedsearches`)
   }

   addWidgetItems(request: any) {
      return this.post(`/widget/item`, request)
   }

   editWidgetItems(request: any) {
      return this.post(`/widget/editItems`, request)
   }
}