<div style="padding: 8px; background-color: white;">
    <div *ngIf="Error" class="card-body text-danger">
        {{ Error }}
    </div>
    <div class="card-body py-0">
        <div class="form-group row">  
            <label class="col col-12 text-danger">** this section is only viewable by admins only!</label>
            <label class="col col-12 text-danger">** reports will only send to email specified below!</label>
        </div> 
        <div class="form-group row">
            <label class="col col-auto">Send to emails</label>
            <div class="col col-3">
                <input type="email" multiple class="form-control" [(ngModel)]="ToEmails" autofocus #toEmailElement />
            </div>
            <label class="col col-auto">Copy emails</label>
            <div class="col col-4">
                <input type="email" multiple class="form-control" [(ngModel)]="CCEmails" />
            </div>
        </div> 
    </div>
    <div class="card-body" style="padding-top: 8px;">
        <app-campaign-datatable [iCanRead]="true" [iColumns]="adsReportsTableColumns" [iShowExport]="false"
            [iData$]="adsReportsTableData" [iPageSize]="100" [iShowSendEmail]="true"
            (oSendEmail)="oSendEmail($event)"></app-campaign-datatable>
    </div>
</div>