import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COUNTRY_LIST, PRODUCT_FAMILY } from '../../../core/helpers/constants';
import { IClient } from '../../../core/models/clients.models';
import { DataFeedService } from '../../../shared/api/datafeed.service';
import { AppService } from '../../../store/app.service';

@Component({
  selector: 'app-product-checkout',
  templateUrl: './product-checkout.component.html',
  styleUrls: ['./product-checkout.component.scss']
})

export class ProductCheckoutComponent {
  PRODUCT_FAMILY = PRODUCT_FAMILY;
  COUNTRY_LIST = COUNTRY_LIST
  @Input() public iProductFamily: number = 1;
  @Input() set iAllMyClients(clients: Array<IClient>) {
    this.allMyClients = [...clients];
    if (clients.length > 0) {
      this.fp.clientId.setValue(clients[0].id);
      this.onPaymentClientChange({ id: clients[0].id });
    }
  }

  @Output() oOnSubmit: EventEmitter<any> = new EventEmitter();

  allMyClients = new Array<IClient>();


  paymentForm: FormGroup
  coSubmitted = false;
  paymentSubmitted = false;
  myPaymentMethods: Array<any> = new Array<any>();
  activeUserId!: string;
  isNewPaymentMethod = false;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private datafeedService: DataFeedService
  ) {
    this.paymentForm = this.formBuilder.group({
      methodId: [null, [Validators.required]],
      clientId: [null, [Validators.required]],
      nickname: [null],
      firstName: [null],
      lastName: [null, []],
      cardNumber: [null, []],
      cardCVV: [null],
      cardExpiryDate: [null],
      agreeTerms: [null, [Validators.requiredTrue]],
      address: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      zip: [null, [Validators.required]],
    });
    this.appService.fetchAllUserPaymentMethods();

    // this.datafeedService.getPaymentMethodsByUserId()
    //   .subscribe(res => {
    //     res.data.map((x: any) => x.cardNumber = `************${x.cardNumber}`)
    //     this.myPaymentMethods = [...res.data];
    //   })

  }
  get fp() { return this.paymentForm.controls! }

  onPaymentMethodChange(evt: any) {
    if (evt) {
      let e = this.myPaymentMethods.find(x => x.id === evt.id);
      this.paymentForm.patchValue({
        address: e.address,
        city: e.city,
        state: e.state,
        zip: e.zip,
      });
    } else {
      this.paymentForm.patchValue({
        address: null,
        city: null,
        state: null,
        zip: null,
      });
    }
  }
  onPaymentClientChange(evt: any) {
    const sub = this.datafeedService.getPaymentMethodsByClientId(evt.id)
      .subscribe(res => {
        res.data.map((x: any) => x.cardNumber = `************${x.cardNumber}`)
        this.myPaymentMethods = [...res.data];
        setTimeout(() => { sub.unsubscribe(); }, 0);
      });

    this.paymentForm.patchValue({
      methodId: null,
      address: null,
      city: null,
      state: null,
      zip: null,
    });
  }
  // doSubmitCoForm() {
  //   this.coSubmitted = true;
  //   if (this.coForm.invalid)
  //     return false;
  //   return true;
  // }
  // getCoFormValues() {
  //   if (!this.coForm.invalid)
  //     return this.coForm.value;
  // }
  doSubmitPaymentForm() {
    this.paymentSubmitted = true;
    if (this.paymentForm.invalid)
      return false;
    return true;
  }
  getPaymentFormValues() {
    if (!this.paymentForm.invalid)
      return this.paymentForm.value;
  }
  onChangePaymentMethodSelectionMode(newPaymentMethod: boolean) {
    this.isNewPaymentMethod = newPaymentMethod;

    this.fp.methodId.setValidators(newPaymentMethod ? [] : [Validators.required]);
    this.fp.methodId.reset();
    this.fp.methodId.updateValueAndValidity();

    let formValidators = newPaymentMethod ? [Validators.required] : [];

    this.fp.nickname.setValidators(formValidators);
    this.fp.firstName.setValidators(formValidators);
    this.fp.lastName.setValidators(formValidators);
    this.fp.cardNumber.setValidators(formValidators);
    this.fp.cardExpiryDate.setValidators(formValidators);
    this.fp.cardCVV.setValidators(formValidators);
    this.fp.agreeTerms.setValidators([Validators.requiredTrue]);

    this.fp.nickname.reset();
    this.fp.firstName.reset();
    this.fp.lastName.reset();
    this.fp.cardNumber.reset();
    this.fp.cardExpiryDate.reset();
    this.fp.cardCVV.reset();
    this.fp.agreeTerms.reset();

    this.fp.nickname.updateValueAndValidity();
    this.fp.firstName.updateValueAndValidity();
    this.fp.lastName.updateValueAndValidity();
    this.fp.cardNumber.updateValueAndValidity();
    this.fp.cardExpiryDate.updateValueAndValidity();
    this.fp.cardCVV.updateValueAndValidity();
    this.fp.agreeTerms.updateValueAndValidity();
  }
}
