import { Injectable } from '@angular/core';
import { ITag } from '../../core/models/tags.models';
import { HttpService } from '../../shared/http/base.http';

@Injectable()
export class TagsApiService extends HttpService {
   getAllTags(savedSearchId: any) {
      const query = savedSearchId ? `?savedSearchId=${savedSearchId}` : '';
      return this.get<Array<ITag>>('/tags/getAllTags' + query)
   }
   getAssociatedTags(id: string, type: number) {
      return this.get<Array<ITag>>(`/tags/getAllAssociatedTags?id=${id}&type=${type}`)
   }
   saveAssociatedTags(data: Array<ITag>) {
      return this.post('/tags/saveAssociatedTags', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   deleteAssociatedTag(id: string) {
      return this.delete(`/tags/deleteAssociatedTag/${id}`);
   }

   getTagDetails(id: any) {
      return this.get<ITag>('/tags/getTagDetails?id=' + id)
   }
   saveTag(data: ITag) {
      return this.post('/tags/saveTag', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }

   deleteTag(ids: Array<string>) {
      return this.post('/tags/deleteTag', ids, { 'Content-Type': 'application/json; charset=utf-8' });
   }
}
