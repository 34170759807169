<div class="row">
    <div class="col-md-12">
        <div class="card card-custom">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <form [formGroup]="editTagForm" (ngSubmit)="onSubmit()">
                            <div class="row mb-3">
                                <label class="col-sm-3" for="name">Name</label>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" formControlName="name"
                                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                        placeholder="Enter tag name">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3" for="description">Description</label>
                                <div class="col-sm-6">
                                    <textarea rows="3" class="form-control" formControlName="description"
                                        [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                                        placeholder="enter description"></textarea>
                                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                        <div *ngIf="f.description.errors.required">description is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3" for="categories">Categories</label>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" formControlName="categories"
                                        [ngClass]="{ 'is-invalid': submitted && f.categories.errors }"
                                        placeholder="Enter categories">
                                    <div *ngIf="submitted && f.categories.errors" class="invalid-feedback">
                                        <div *ngIf="f.categories.errors.required">categories is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3"></div>
                                <div class="col-sm-6" style="display: flex; align-items: center; justify-content: space-between; flex-direction: row;">
                                    <button type="button" class="btn btn-orange" [routerLink]="returnPath">Cancel</button>
                                    <button class="blue-button" type="submit">SAVE</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-6">
                        <!-- <div class="row mb-3">
                            <label class="col-sm-3" for="clientId">Clients</label>
                            <div class="col-sm-9">
                                <select [ngClass]="{ 'is-invalid': submitted && f.clientId.errors }" formControlName="clientId" class="form-control form-select select-custom">
                                    <option *ngFor="let rep of allCompanies" [ngValue]="rep.id">{{rep.name}}</option>
                                </select>
                                <div *ngIf="submitted && f.clientId.errors" class="invalid-feedback">
                                    <div *ngIf="f.clientId.errors.required">brand is required</div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>