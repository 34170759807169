import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AppService } from '../../store/app.service';
import { CouponsApiService } from './coupon.apiservice';

@Injectable()
export class CouponsService {

    constructor(
        private appService: AppService,
        private couponService: CouponsApiService,
        private router: Router
    ) { }

    deleteCoupon() {
        var d = this.appService.getActiveRoute$().value.url.split('/');
        var cId = d[d.length - 1];
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.couponService.deleteCoupon([cId])
                    .subscribe((r: any) => {
                        if (r.good) {
                            this.router.navigate(['/pages/coupons']);
                        }
                    })
            }
        })
    }

}
