import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {  ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createGuid } from '../../../core/helpers/utility';
import { ICart, IProductMain, IProductCompanies } from '../../../core/models/products.models';
import { AppService } from '../../../store/app.service';
import { ShopApiService } from '../shop.apiservice';
import { UtilityService } from '../../../core/helpers/utility.service';
import { IUser } from '../../../core/models/auth.models';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})

/**
 * Page Title Component
 */
export class ProductsListComponent implements OnInit, OnDestroy {
  //new
  pageNumber = 1;
  pageSize = 12;
  tempProducts: Array<IProductMain> = new Array<IProductMain>();

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  allProducts: Array<IProductMain> = new Array<IProductMain>();
  holdProducts: Array<IProductMain> = new Array<IProductMain>();
  public userRoles: Array<number> = [];
  activeUser: IUser = {} as IUser;

  constructor(
    private shopApiService: ShopApiService,
    private appService: AppService,
    private router: Router,
    public uService: UtilityService
  ) {
    this.userRoles = [...this.uService.getUserRoles()];
    this.appService.getActiveUser$()
    .pipe(takeUntil(this.destroyed$)
    ).subscribe(u => {
      this.activeUser = u;
    });
  }

  allMyCompanies: {id: number; name: string}[] = [];
  doneLoading: boolean = false;

  ngOnInit(): void {
    this.appService.setPageTitle$('Shop');
    this.shopApiService.getAllProducts()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        this.allProducts = [...response.data];
        this.allProducts.sort((a, b) => a.display!.localeCompare(b.display!));
        this.tempProducts = this.allProducts.slice(0, this.pageSize);
        this.holdProducts = this.allProducts;
         this.allProducts.forEach((p: IProductMain) => {
          p.companyNames.split(',').forEach((pc: string) => {
            if (!this.allMyCompanies.find((c) => c.name === pc) && pc) {
              this.allMyCompanies.push({id: this.allMyCompanies.length, name: pc!});
            }
          })
          
        });
        this.allMyCompanies.sort((a, b) => a.name.localeCompare(b.name));
        this.doneLoading = true;
      });
  }

  onCompanyChange(event: any) {
    console.log(event);
    if (event !== undefined) {
      this.allProducts = this.holdProducts.filter((p: IProductMain) => p.companyNames?.indexOf(event.name) > -1);
    }
    else {
      this.allProducts = this.holdProducts;
    }
    this.onPageChange(1);
  }

  onAddtoCart(id: string) {
    if (this.userRoles.includes(1) || (!this.userRoles.includes(3) && !this.userRoles.includes(10) && !this.userRoles.includes(2) && !this.userRoles.includes(12))) {
      this.shopApiService.saveCart({
        id: createGuid(),
        productId: id,
        currentStep: 1,
      } as ICart)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((r: any) => {
          if (r.good) {
            this.router.navigate(['/pages/shop/cart', r.data.id]);
          }
        })
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  //new
  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.tempProducts = this.allProducts.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    console.log(this.pageNumber);
  }
}