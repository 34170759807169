import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PAGE_NAMES, ROLES } from '../../../core/helpers/constants';
import { getRolesForPage } from '../../../core/helpers/utility';
import { IPermission } from '../../../core/models/auth.models';
import { ICampaign } from '../../../core/models/campaigns.model';
import { LoadDataFromServer } from '../../../shared/components/datatable/datatable.model';
import { AppService } from '../../../store/app.service';
import { CampaignsApiService } from '../campaigns.apiservice';
import { MergeCampaignsModal } from '../../../shared/modals/merge-campaign/merge-campaign.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '../../../core/helpers/utility.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns-main.component.html',
  styleUrls: ['./campaigns-main.component.scss']
})
export class CampaignsMainComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;

  columns = [
    { title: 'Name', field: 'name', sortable: true, searchable: true },
    { title: 'Client', field: 'clientName', sortable: true, searchable: true },
    { title: 'Platform', field: 'platform', sortable: true, searchable: true },
    { title: 'DMS', field: 'dmsName', sortable: true, searchable: true },
    { title: 'Stage', field: 'stageId', sortable: true, pipe: 'P_CampaignStageTitle', searchable: true },
    { title: 'Active Subscription', field: 'subscriptionActive', pipe: 'P_Boolean', sortable: true, searchable: true },
  ]

  campaignsData: Array<ICampaign>
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private loadEvent!: LoadDataFromServer;
  dataTotalCount: number = 0;
  showDeleted: boolean = false;
  savedSearchId!: string;
  public userRoles: Array<number> = [];
  public ROLES = ROLES;

  constructor(
    private router: Router,
    private cApiService: CampaignsApiService,
    private modalService: NgbModal,
    private uService: UtilityService,
    private route: ActivatedRoute,
    private appService: AppService) {
    this.campaignsData = new Array<ICampaign>();
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Campaigns');
    this.userRoles = [...this.uService.getUserRoles()];
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Campaigns);
    this.appService.getAllCampaigns$()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        this.campaignsData = [...response.data];
        this.dataTotalCount = response.totalCount;
      });
    this.savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
  }

  onLoadingData(event: LoadDataFromServer) {
    this.loadEvent = event;
    this.appService.fetchAllCampaigns({
      SavedSearchId: this.savedSearchId,
      ShowDeleted: this.showDeleted,
      ...this.loadEvent
    });
  }

  onShowDeletedChange(){
    this.appService.fetchAllCampaigns({
      SavedSearchId: this.savedSearchId,
      ShowDeleted: this.showDeleted,
      ...this.loadEvent
    });
  }

  editRow(evt: any) {
    this.router.navigate(['/pages/edit-campaign', evt.id]);
  }

  deleteRows(evt: any) {
    this.cApiService.deleteCampaign(evt)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.good) {
          Swal.fire('Campaigns ', "Campaign(s) deleted", 'success')
        }
        this.appService.fetchAllCampaigns({
          SavedSearchId: this.savedSearchId,
          ShowDeleted: this.showDeleted,
          ...this.loadEvent
        });
      })
  }

  getRowLink(row: any) {
    return `/pages/campaign-details/${row.id}`;
  }

  ngAfterViewInit() {

  }

  handleMergeRecordsClicked(evt: string[]) {
    let campaignsToMerge = this.campaignsData.filter(f => evt.includes(f.id));
    // let groupedClient = campaignsToMerge.reduce(
    //   (result:any, currentValue:any) => { 
    //     (result[currentValue['clientId']] = result[currentValue['clientId']] || []).push(currentValue);
    //     return result;
    //   }, {});

    let groupedPlatform = campaignsToMerge.reduce(
        (result:any, currentValue:any) => { 
          (result[currentValue['platform']] = result[currentValue['platform']] || []).push(currentValue);
          return result;
        }, {});

    if(Object.keys(groupedPlatform).length > 1){
      Swal.fire({
        title: 'Cannot Merge',
        text: "Selected campaigns must have same platform.",
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        
      })
    }
    else{
      let size = campaignsToMerge.length == 2 ? 'lg' : 'xl';

      const dialogRef = this.modalService.open(MergeCampaignsModal, { size: size });

      dialogRef.componentInstance.campaignsToMerge = campaignsToMerge;
      const dialogSubmitSubscription = dialogRef.componentInstance.oMergeComplete.subscribe((r: any) => {
        this.appService.fetchAllCampaigns({
          SavedSearchId: this.savedSearchId,
          ShowDeleted: this.showDeleted,
          ...this.loadEvent
        });
        dialogSubmitSubscription.unsubscribe();
      })
    }  
  }

  async export() {
    this.cApiService.export({
      SavedSearchId: this.savedSearchId,
      ShowDeleted: this.showDeleted,
      ...this.loadEvent
    }).then((x: any) => {
      var newBlob = new Blob([x], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = "campaigns.xlsx";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
}
