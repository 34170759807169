<div class="modal-box" cdkDrag>
    <form id="frmAddForm" (ngSubmit)="onSubmit()">
        <div class="parent">
            <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
                <h4 class="modal-title">Add order to subscription</h4>
                <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="products">
                    <table style="width: 100%;">
                    <tr>
                        <th>Items</th>
                        <th>Cost</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                        <ng-container *ngFor="let product of products">
                            <tr>
                            <td><img [src]="product.mediaSrc">{{product.name}}</td>
                            <td>{{formatPrice(product.price)}}</td>
                            <td>
                                <div class="input-group input-group-sm mb-1">
                                    <span class="input-group-text border-0 bg-none">$ </span>
                                    <input type="text" name="{{product.id}}" class="form-control" (blur)="transformAmount($event)"
                                    [(ngModel)]="product.amount"
                                        placeholder="enter amount">
                                </div>
                            </td>
                            <td>
                            </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td></td>
                            <td></td>
                            <td colspan="2">
                                <p style="line-height: normal; margin-top: 1rem;">Order Total: {{formatPrice(totalOrderAmount)}}</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" class="btn btn-orange" (click)="activeModal.close('Close click')">Cancel</button>
            <button type="submit" class="blue-button" [disabled]="totalOrderAmount == 0">Save</button>
        </div>
    </form>
</div>