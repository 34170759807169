<div class="dv-custom-navs">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1" [ngStyle]="{'z-index' : (active === 1) ? '5' : '0'}">
            <a ngbNavLink>
                <i class="fas fa-sd-card"></i><label>Saved Searches</label>
            </a>
            <ng-template ngbNavContent>
                <div style="background-color: white; padding: 8px;">
                    <app-saved-searches></app-saved-searches>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" [ngStyle]="{'z-index' : (active === 2) ? '5' : '0', 'left': '-10px'}">
            <a ngbNavLink>
                <i-feather name="file-plus"></i-feather><label>{{mode}} Search</label>
            </a>
            <ng-template ngbNavContent>
                <div style="border-bottom: 5px solid white; border-left: 5px solid white; border-right: 5px solid white; padding: 8px;">
                    <app-new-search-main (searchSaved)="searchSaved()"></app-new-search-main>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>