<div class="">
    <!-- <form [formGroup]="coForm">
        <div class="row">
            <div class="col-md-2">
                <h4>Addtional Info</h4>
            </div>
            <div class="col-md-10">
                <textarea rows="3" class="form-control" formControlName="bdNotes"
                    [ngClass]="{ 'is-invalid': coSubmitted && fp.bdNotes.errors }"
                    placeholder="Addtional info"></textarea>
                <div *ngIf="coSubmitted && fp.bdNotes.errors" class="invalid-feedback">
                    <div *ngIf="fp.bdNotes.errors.required">Additional info is required </div>
                </div>
            </div>
        </div>
    </form> -->

    <form class="card-block" [formGroup]="paymentForm">
        <div class="row">
            <div class="col-md-12">
                <div class="row mb-10">
                    <label class="col-md-1">Client</label>
                    <div class="col-md-4">
                        <ng-select [clearable]="false"
                            [ngClass]="{'is-invalid': paymentSubmitted && fp.clientId.errors, 'disabled-dropdown': allMyClients.length ===1 }"
                            bindLabel="fullName" bindValue="id" (change)="onPaymentClientChange($event)" [items]="allMyClients" formControlName="clientId">
                        </ng-select>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <label *ngIf="!isNewPaymentMethod" class="col-md-4">Select from your saved payment methods</label>
                            <div *ngIf="!isNewPaymentMethod" class="col-md-6">
                                <ng-select [ngClass]="{'is-invalid': paymentSubmitted && fp.methodId.errors?.required}"
                                    bindLabel="nickname" (change)="onPaymentMethodChange($event)" bindValue="id"
                                    [items]="myPaymentMethods" formControlName="methodId">
                                </ng-select>
                                <div *ngIf="paymentSubmitted && fp.methodId.errors" class="invalid-feedback">
                                    <div *ngIf="fp.methodId.errors?.required">Please select a payment method
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!isNewPaymentMethod" class="col-md-2">
                                <button (click)="onChangePaymentMethodSelectionMode(true)" style="background: white" class="btn">Add
                                    New</button>
                            </div>
                            <div *ngIf="isNewPaymentMethod" style="text-align: right" class="col-md-12">
                                <button (click)="onChangePaymentMethodSelectionMode(false)" style="background: white" class="btn">Show Saved Payment Methods</button>
                            </div>

                        </div>
                        <div *ngIf="isNewPaymentMethod" class="row">
                            <div class="col-md-8">
                                <div class="row mb-10">
                                    <div class="col-md-12">
                                        <div fxLayout="column">
                                            <h2>Credit Card</h2>
                                            <p>Pay securely using your credit card.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-10">
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="firstName"
                                            [ngClass]="{ 'is-invalid': paymentSubmitted && fp.firstName.errors }"
                                            placeholder="First name on card">
                                        <div *ngIf="paymentSubmitted && fp.firstName.errors" class="invalid-feedback">
                                            <div *ngIf="fp.firstName.errors.required">First name is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="lastName"
                                            [ngClass]="{ 'is-invalid': paymentSubmitted && fp.lastName.errors }"
                                            placeholder="Last name on card">
                                        <div *ngIf="paymentSubmitted && fp.lastName.errors" class="invalid-feedback">
                                            <div *ngIf="fp.lastName.errors.required">Last name is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-10">
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" formControlName="cardNumber"
                                            mask="0000-0000-0000-0000"
                                            [ngClass]="{ 'is-invalid': paymentSubmitted && fp.cardNumber.errors }"
                                            placeholder="xxxx xxxx xxxx xxxx">
                                        <div *ngIf="paymentSubmitted && fp.cardNumber.errors" class="invalid-feedback">
                                            <div *ngIf="fp.cardNumber.errors.required">Card number is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-10">
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" mask="00/00"
                                            formControlName="cardExpiryDate"
                                            [ngClass]="{ 'is-invalid': paymentSubmitted && fp.cardExpiryDate.errors }"
                                            placeholder="MM/YY">
                                        <div *ngIf="paymentSubmitted && fp.cardExpiryDate.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="fp.cardExpiryDate.errors.required">Expiry date is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" mask="0000" class="form-control" formControlName="cardCVV"
                                            [ngClass]="{ 'is-invalid': paymentSubmitted && fp.cardCVV.errors }"
                                            placeholder="CVV">
                                        <div *ngIf="paymentSubmitted && fp.cardCVV.errors" class="invalid-feedback">
                                            <div *ngIf="fp.cardCVV.errors.required">CVV is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-10">
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" formControlName="nickname"
                                        [ngClass]="{ 'is-invalid': paymentSubmitted && fp.nickname.errors }"
                                        placeholder="Card nickname">
                                    <div *ngIf="paymentSubmitted && fp.nickname.errors" class="invalid-feedback">
                                        <div *ngIf="fp.nickname.errors.required">Card nickname is required</div>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
                <h4>Billing Details</h4>
            </div>
            <div class="col-md-10">
                <div class="row mb-10">
                    <div class="col-md-12">
                        <input type="text" class="form-control" formControlName="address"
                            [ngClass]="{ 'is-invalid': coSubmitted && fp.adAddress.errors }" placeholder="Street info">
                        <div *ngIf="coSubmitted && fp.adAddress.errors" class="invalid-feedback">
                            <div *ngIf="fp.adAddress.errors.required">Address is required</div>
                        </div>
                    </div>
                </div>
                <div class="row mb-10">
                    <div class="col-md-4">
                        <input type="text" class="form-control" formControlName="city"
                            [ngClass]="{ 'is-invalid': coSubmitted && fp.city.errors }" placeholder="City">
                        <div *ngIf="coSubmitted && fp.city.errors" class="invalid-feedback">
                            <div *ngIf="fp.city.errors.required">City is required</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" formControlName="state"
                            [ngClass]="{ 'is-invalid': coSubmitted && fp.state.errors }" placeholder="State">
                        <div *ngIf="coSubmitted && fp.state.errors" class="invalid-feedback">
                            <div *ngIf="fp.state.errors.required">State is required</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" formControlName="zip"
                            [ngClass]="{ 'is-invalid': coSubmitted && fp.zip.errors }" placeholder="Zip">
                        <div *ngIf="coSubmitted && fp.zip.errors" class="invalid-feedback">
                            <div *ngIf="fp.zip.errors.required">Zip is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 5px">
            <hr>
        </div>
        <div style="text-align: right;margin-top: 5px;">
            <div class="row">
                    <div class="col-md-9">
                        <input type="checkbox" id="agreeTerms" formControlName="agreeTerms"
                            class="form-check-input" />
                    </div>
                    <label class="col-md-3" for="agreeTerms">I have read and agreed the website
                        <a href="https://shop.franchisefocusedmarketing.com/terms-of-service/" target="_blank">terms &
                        conditions</a></label>
                    <label *ngIf="paymentSubmitted && fp.agreeTerms.errors?.required"
                        class="col-sm-11" style="color: red">Please accept the agreement</label>
            </div>
        </div>
    </form>
</div>