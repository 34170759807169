import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AppService } from '../../store/app.service';
import { LeadsApiService } from './leads.apiservice';

@Injectable()
export class LeadsService {


    constructor(
        private appService: AppService,
        private leadService: LeadsApiService,
        private router: Router
    ) {

    }


    deleteLeads() {
        var d = this.appService.getActiveRoute$().value.url.split('/');
        var cId = d[d.length - 1];
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.leadService.deleteLead([cId])
                    .subscribe((r: any) => {
                        if (r.good) {
                            this.router.navigate(['/pages/leads']);
                        }
                    })
            }
        })
    }

}
