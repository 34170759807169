<app-campaign-datatable
[iShowExport]='false'
[iCanRead]="doWhat.canRead"
[iCanWrite]="doWhat.canWrite"
[iCanDelete]="doWhat.canDelete"
[iColumns]="columns"
(oRowClick)="onClientRowClick($event)"
(oDeleteRows)="deleteRows($event)"
    (oEditRow)="editRow($event)"
    [iData$]="companiesData"></app-campaign-datatable>
