import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CAMPAIGN_STAGES, ROLES, TASK_PRIORITY } from '../../../../core/helpers/constants';
import { DataFeedService } from '../../../../shared/api/datafeed.service';
import { AppService } from '../../../../store/app.service';
import { SearchesApiService } from '../../searches.apiservice';

@Component({
  selector: 'app-new-search-main',
  templateUrl: './new-search-main.component.html',
  styleUrls: ['./new-search-main.component.scss']
})
export class NewSearchMainComponent {
  @Output() searchSaved = new EventEmitter();

  active = 1;
  newSearchForm!: FormGroup;
  submitted = false;

  campaignDisabled = false;
  companyDisabled = false;
  clientDisabled = false;
  userDisabled = false;
  tagDisabled = false;
  leadDisabled = false;
  taskDisabled = false;
  prospectDisabled = false;

  companyVisible = true;
  clientVisible = true;
  campaignVisible = true;
  userVisible = true;
  tagVisible = true;
  leadVisible = true;
  taskVisible = true;
  prospectVisible = true;

  editSavedSearchId!: string;
  allowedTypes: any[] = [];
  public allResultTypesPages = ['Campaigns', 'Clients', 'Leads', 'Users', 'Companies', 'Prospects', 'Tags', 'Tasks'];
  public allResultTypes = ['Campaign', 'Client', 'Lead', 'User', 'Company', 'Prospect', 'Tag', 'Task'];
  public stringOperations = [
    { name: 'Equals', value: 'Equals' },
    { name: 'Not Equals', value: 'NotEquals' },
    { name: 'Starts With', value: 'StartsWith' },
    { name: 'Ends With', value: 'EndsWith' },
    { name: 'Contains', value: 'Contains' },
    { name: 'Doesnt Contain', value: 'DoesntContain' },
    { name: 'Is Empty', value: 'IsEmpty' },
    { name: 'Is Filled', value: 'IsFilled' }];

  public defaultStringOperation = 'Contains';

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private searchApi: SearchesApiService,
    private dataFeedApi: DataFeedService) {

    this.newSearchForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      resultType: ['Campaign', [Validators.required]],
    });
    this.onResultTypeChange(this.allResultTypes[0]);
    const savedSearchId = this.route.snapshot.paramMap.get('id');
    if (savedSearchId) {
      this.editSavedSearchId = savedSearchId;
      this.editSavedSearch(savedSearchId);
    }

    const userRoles = this.appService.getActiveUserRoles$().value;
    if (userRoles.find(x => x.code == ROLES.Admin.Code))
      this.allowedTypes == this.allResultTypesPages;
    else {
      for (var r of userRoles) {
        for (var p of r.perms) {
          this.allowedTypes.push(p.pageName);
        }
      }
      for (let i = this.allResultTypes.length - 1; i >= 0; i--) {
        let page = this.allResultTypesPages[i];
        let found = this.allowedTypes.find((x: any) => page === x) !== undefined;
        if (!found) {
          this.allResultTypes.splice(i, 1);
          if (i === 7)
            this.taskVisible = false;
          if (i === 6)
            this.tagVisible = false;
          if (i === 5)
            this.prospectVisible = false;
          if (i === 4)
            this.companyVisible = false;
          if (i === 3)
            this.userVisible = false;
          if (i === 2)
            this.leadVisible = false;
          if (i === 1)
            this.clientVisible = false;
          if (i === 0)
            this.campaignVisible = false;
        }
      }
    }
    this.prospectColumns = this.clientsColumns.map(x => {
      var newControlName: any = `prospects.${x.formControlName.split('.')[1]}`;
      return { ...x, formControlName: newControlName }
    })
    this.dataFeedApi.getAllTaskAssignees()
      .subscribe((response: any) => {
        if (response.good) {
          var index = this.taskColumns.findIndex(x => x.formControlName === 'tasks.assignedUser');
          this.taskColumns[index].value.options = response.data.map((x: any) => {
            return { name: x.firstName + ' ' + x.lastName, value: x.id }
          });
          this.taskColumns[index].value.options?.splice(0, 0, { name: 'All', value: '' })
          this.taskColumns = [...this.taskColumns];
        }
      });
  }
  get form() { return this.newSearchForm.controls; }

  editSavedSearch(savedSearchId: string) {
    const that = this;
    this.searchApi.getSavedSearch(savedSearchId)
      .subscribe((response: any) => {
        if (response.good) {
          that.newSearchForm.controls['title'].setValue(response.savedSearch.name);
          var resultType = response.savedSearch.resultType.charAt(0).toUpperCase() + response.savedSearch.resultType.slice(1);
          that.newSearchForm.controls['resultType'].setValue(resultType);
          this.onResultTypeChange(resultType);
          for (var i = 0; i < response.savedSearch.fields.length; i++) {
            const field = response.savedSearch.fields[i];
            if (field.name.indexOf('_') >= 0) {
              const name = field.dataType + "." + field.name.substring(0, field.name.indexOf('_'));
              var columns = this.getColumnsByType(field.dataType) ?? [];
              const index = columns.findIndex((x: any) => x.formControlName === name);
              const newField: any = JSON.parse(JSON.stringify(columns[index]));
              newField.formControlName = field.dataType + "." + field.name;
              newField.operator.default = field.operator;
              newField.value.defaultValue = field.value;
              newField.deletable = true;
              this.addNewColumn(field.dataType, newField);
            }
            else {
              that.newSearchForm.controls[`${field.dataType}.${field.name}Operation`].setValue(field.operator);
              that.newSearchForm.controls[`${field.dataType}.${field.name}`].setValue(field.value);
            }
          }
        }
      });
  }

  onResultTypeChange(newResultType: any) {
    this.active = this.allResultTypes.findIndex(x => x === newResultType) + 1;
    if (newResultType === 'Campaign') {
      this.campaignDisabled = this.clientDisabled = this.userDisabled = this.tagDisabled = false;
      this.companyDisabled = this.leadDisabled = this.taskDisabled = this.prospectDisabled = true;
    } else if (newResultType === 'Company') {
      this.companyDisabled = this.clientDisabled = this.userDisabled = false;
      this.campaignDisabled = this.leadDisabled = this.tagDisabled = this.taskDisabled = this.prospectDisabled = true;
    } else if (newResultType === 'Client') {
      this.clientDisabled = this.companyDisabled = this.campaignDisabled = this.userDisabled = this.tagDisabled = false;
      this.leadDisabled = this.taskDisabled = this.prospectDisabled = true;
    } else if (newResultType === 'Prospect') {
      this.prospectDisabled = false;
      this.clientDisabled = this.leadDisabled = this.companyDisabled = this.campaignDisabled = this.userDisabled = this.tagDisabled = this.taskDisabled = true;
    } else if (newResultType === 'User') {
      this.userDisabled = false;
      this.companyDisabled = this.clientDisabled = this.tagDisabled = this.campaignDisabled = this.leadDisabled = this.taskDisabled = this.prospectDisabled = true;
    } else if (newResultType === 'Tag') {
      this.tagDisabled = false;
      this.companyDisabled = this.clientDisabled = this.campaignDisabled = this.userDisabled = this.leadDisabled = this.taskDisabled = this.prospectDisabled = true;
    } else if (newResultType === 'Lead') {
      this.leadDisabled = this.campaignDisabled = false;
      this.companyDisabled = this.clientDisabled = this.tagDisabled = this.userDisabled = this.taskDisabled = this.prospectDisabled = true;
    }
    else if (newResultType === 'Task') {
      this.taskDisabled = this.campaignDisabled = this.clientDisabled = this.userDisabled = false;
      this.companyDisabled = this.leadDisabled = this.tagDisabled = this.prospectDisabled = true;
    }
  }

  saveClicked() {
    this.submitted = true;
    if (!this.newSearchForm.valid) return;
    var search: any = { id: this.editSavedSearchId };
    search.fields = [];
    for (var key of Object.keys(this.newSearchForm.value)) {
      if (key === 'title')
        search.name = this.newSearchForm.value[key];
      else if (key === 'resultType')
        search.resultType = this.newSearchForm.value[key];
      else if (key.endsWith('Operation'))
        continue;
      else {
        var value = this.newSearchForm.value[key];
        var operator = this.newSearchForm.value[key + 'Operation'];
        if ((value === '' || value === undefined) && operator !== 'IsEmpty' && operator !== 'IsFilled')
          continue;
        var dataType = key.split('.')[0];
        var name = key.split('.')[1];
        search.fields.push({
          dataType,
          name,
          operator,
          value
        });
      }
    }
    this.appService.addSearch$(search);
    this.resetForm();
    if (this.editSavedSearchId) {
      this.router.navigate(['/pages/searches/']);
    }
    else {
      this.searchSaved.emit();
    }
  }

  resetForm() {
    this.newSearchForm.controls['title'].setValue('');
    this.newSearchForm.controls['resultType'].setValue('Campaign');
  }

  cancelClicked() {
    this.reset();
    this.router.navigate(['/pages/searches/']);
    this.searchSaved.emit();
  }

  reset() {
    this.campaignDisabled = this.clientDisabled = this.userDisabled = this.tagDisabled = this.companyDisabled = this.taskDisabled = true;
    this.resetForm();
  }

  onCompletionDateChange(value: any, row: any) {
    var completedWithinDatePickerEnabled = false;
    if (value === '') { // anytime
      this.newSearchForm.controls[row.formControlName].patchValue('');
    } else if (value === 'Today') { // today
      this.newSearchForm.controls[row.formControlName].patchValue(this.getDateString(0));
    } else if (value === 'LastWeek') { // last week
      this.newSearchForm.controls[row.formControlName].patchValue(this.getDateString(-7));
    } else if (value === 'LastMonth') { // last month
      this.newSearchForm.controls[row.formControlName].patchValue(this.getDateString(-30));
    } else if (value === 'Last90Days') { // 90 days
      this.newSearchForm.controls[row.formControlName].patchValue(this.getDateString(-90));
    } else if (value === 'CustomPeriod') { // custom
      this.newSearchForm.controls[row.formControlName].patchValue('');
      completedWithinDatePickerEnabled = true;
    }
    var index = this.taskColumns.findIndex(x => x.formControlName === row.formControlName)
    this.taskColumns[index].value.clickOpens = completedWithinDatePickerEnabled;
    this.taskColumns = [...this.taskColumns];
  }

  getDateString(days: number) {
    var today = new Date();
    var target = new Date(today.valueOf());
    target.setDate(today.getDate() + days);
    var dateToString = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    if (days >= 0) {
      return `${dateToString(today)} to ${dateToString(target)}`;
    } else {
      return `${dateToString(target)} to ${dateToString(today)}`;
    }
  }

  campaignStages = [{ name: '', value: '' }, ...CAMPAIGN_STAGES.map(x => { return { name: x.title, value: x.value.toString() } })];

  campaignColumns = [
    {
      name: 'Name',
      formControlName: 'campaign.name',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Platform',
      formControlName: 'campaign.platform',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Location Email',
      formControlName: 'campaign.locationEmail',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Location Street',
      formControlName: 'campaign.street',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Location City',
      formControlName: 'campaign.city',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Location State',
      formControlName: 'campaign.state',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Location Zipcode',
      formControlName: 'campaign.zipcode',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Platform Campaign ID',
      formControlName: 'campaign.platformCampaignId',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Platform Account ID',
      formControlName: 'campaign.platformAccountId',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'DMS',
      formControlName: 'campaign.dms',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Stage',
      formControlName: 'campaign.stage',
      operator: { options: [{ name: 'Equals', value: 'Equals' }, { name: 'Not Equals', value: 'Not Equals' }], default: 'Equals' },
      value: { type: 'select', options: this.campaignStages }
    },
    {
      name: 'Campaign Notes',
      formControlName: 'campaign.campaignNotes',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Campaign Creation Notes',
      formControlName: 'campaign.campaignCreationNotes',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Tags',
      formControlName: 'campaign.tags',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Crm Name',
      formControlName: 'campaign.crmName',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Crm Id',
      formControlName: 'campaign.crmId',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    }
  ];

  clientsColumns = [
    {
      name: 'First Name',
      formControlName: 'client.firstName',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Last Name',
      formControlName: 'client.lastName',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'DMS',
      formControlName: 'client.dms',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Sales Rep',
      formControlName: 'client.salesRep',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Phone',
      formControlName: 'client.phone',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Address',
      formControlName: 'client.address',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Tags',
      formControlName: 'client.tags',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Notes',
      formControlName: 'client.notes',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Email',
      formControlName: 'client.email',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    }
  ];

  companyColumns = [
    {
      name: 'Company Name',
      formControlName: 'company.companyName',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Contact Name',
      formControlName: 'company.contactName',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Contact Email',
      formControlName: 'company.contactEmail',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Contact Phone',
      formControlName: 'company.contactPhone',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    }
  ];

  leadColumns = [
    {
      name: 'First Name',
      formControlName: 'lead.firstName',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Last Name',
      formControlName: 'lead.lastName',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Email',
      formControlName: 'lead.email',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Phone',
      formControlName: 'lead.phone',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Referral Id',
      formControlName: 'lead.referralid',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Referral Source',
      formControlName: 'lead.referralsource',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'CRM Name',
      formControlName: 'lead.crmName',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'CRM Id',
      formControlName: 'lead.crmId',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Page Title',
      formControlName: 'lead.pageTitle',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Page Url',
      formControlName: 'lead.pageUrl',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Is ClubReady Member',
      formControlName: 'lead.isMember',
      operator: { options: [{ name: 'Equals', value: 'Equals' }], default: 'Equals' },
      value: { type: 'select', options: [{ name: 'Yes', value: '1' }, { name: 'No', value: '0' }] }
    }
  ];

  tagColumns = [
    {
      name: 'Tag Name',
      formControlName: 'tag.tagName',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Tag Description',
      formControlName: 'tag.tagDescription',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Tag Categories',
      formControlName: 'tag.tagCategories',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    }
  ];
  prospectColumns: any[] = [];

  priorityOptions = [{ name: 'Any', value: '' }, ...TASK_PRIORITY.map(x => { return { name: x.title, value: x.value.toString() } })];
  duedateStatusOptions: any[] = [
    { name: 'All', value: '' },
    { name: 'Overdue', value: 'Overdue' },
    { name: 'Today', value: 'Today' },
    { name: 'Upcoming', value: 'Upcoming' }];

  completionStatusOptions: any[] = [
    { name: 'All', value: '' },
    { name: 'Completed', value: 'Completed' },
    { name: 'Not Completed', value: 'NotCompleted' }];

  completionDateOperations: any[] = [
    { name: 'Anytime', value: '' },
    { name: 'Today', value: 'Today' },
    { name: 'Last Week', value: 'LastWeek' },
    { name: 'Last Month', value: 'LastMonth' },
    { name: 'Last 90 days', value: 'Last90Days' },
    { name: 'Custom', value: 'CustomPeriod' }];
  taskColumns = [
    {
      name: 'Title',
      formControlName: 'tasks.title',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Description',
      formControlName: 'tasks.description',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'Priority',
      formControlName: 'tasks.priority',
      operator: { options: [{ name: 'Equals', value: 'Equals' }, { name: 'Not Equals', value: 'Not Equals' }], default: 'Equals' },
      value: { type: 'select', options: this.priorityOptions }
    },
    {
      name: 'Due Date',
      formControlName: 'tasks.duedate',
      operator: { options: ['Equals', 'GreaterThan', 'GreaterThanOrEqual', 'LessThan', 'LessThanOrEqual'].map(x => { return { name: x, value: x } }), default: 'Equals' },
      value: { type: 'datePicker', mode: 'single' }
    },
    {
      name: 'Due Date Status',
      formControlName: 'tasks.duedateStatus',
      operator: { options: [{ name: 'Equals', value: 'Equals' }], default: 'Equals' },
      value: { type: 'select', options: this.duedateStatusOptions }
    },
    {
      name: 'Completion Status',
      formControlName: 'tasks.completionStatus',
      operator: { options: [{ name: 'Equals', value: 'Equals' }], default: 'Equals' },
      value: { type: 'select', options: this.completionStatusOptions, }
    },
    {
      name: 'Completed Within',
      formControlName: 'tasks.completionDate',
      operator: { options: this.completionDateOperations, default: '', change: (t: any, r: any) => this.onCompletionDateChange(t, r) },
      value: { type: 'datePicker', mode: 'range', clickOpens: false }
    },
    {
      name: 'Assigned User',
      formControlName: 'tasks.assignedUser',
      operator: { options: [{ name: 'Equals', value: 'Equals' }], default: 'Equals' },
      value: { type: 'select', options: [] }
    }
  ];

  userColumns = [
    {
      name: 'User Name',
      formControlName: 'user.userName',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    },
    {
      name: 'User Email',
      formControlName: 'user.userEmail',
      operator: { options: this.stringOperations, default: this.defaultStringOperation },
      value: { type: 'text' }
    }
  ];

  getColumnsByType(dataType: any) {
    if (dataType === 'campaign') return this.campaignColumns;
    if (dataType === 'client') return this.clientsColumns;
    if (dataType === 'company') return this.companyColumns;
    if (dataType === 'lead') return this.leadColumns;
    if (dataType === 'tag') return this.tagColumns;
    if (dataType === 'user') return this.userColumns;
    return null;
  }


  addNewColumn(dataType: any, newField: any) {
    var formControlName = newField.formControlName.split('_')[0];
    if (dataType === 'campaign') {
      var index = this.campaignColumns.findIndex(x => x.formControlName === formControlName);
      this.campaignColumns.splice(index + 1, 0, newField);
      this.campaignColumns = [...this.campaignColumns];
    }
    if (dataType === 'client') {
      var index = this.clientsColumns.findIndex(x => x.formControlName === formControlName);
      this.clientsColumns.splice(index + 1, 0, newField);
      this.clientsColumns = [...this.clientsColumns];
    }
    if (dataType === 'company') {
      var index = this.companyColumns.findIndex(x => x.formControlName === formControlName);
      this.companyColumns.splice(index + 1, 0, newField);
      this.companyColumns = [...this.companyColumns];
    }
    if (dataType === 'lead') {
      var index = this.leadColumns.findIndex(x => x.formControlName === formControlName);
      this.leadColumns.splice(index + 1, 0, newField);
      this.leadColumns = [...this.leadColumns];
    }
    if (dataType === 'tag') {
      var index = this.tagColumns.findIndex(x => x.formControlName === formControlName);
      this.tagColumns.splice(index + 1, 0, newField);
      this.tagColumns = [...this.tagColumns];
    }
    if (dataType === 'user') {
      var index = this.userColumns.findIndex(x => x.formControlName === formControlName);
      this.userColumns.splice(index + 1, 0, newField);
      this.userColumns = [...this.userColumns];
    }
    return null;
  }
}