import { Injectable } from '@angular/core';
import { ICampaign, ICampaignAudit, INotes } from '../../core/models/campaigns.model';
import { HttpService } from '../../shared/http/base.http';

@Injectable()
export class CampaignsApiService extends HttpService {
   getAllCampaigns(request: any) {
      return this.post<Array<ICampaign>>('/campaigns/getAllCampaigns', request);
   }
   getNewCampaignId() {
      return this.get<string>('/campaigns/getNewCampaignId')
   }
   getCampaignsByClient(clientId: any) {
      return this.get<Array<ICampaign>>('/campaigns/getCampaignsByClient?id=' + clientId)
   }
   getCampaignsNamesByClient(clientId: any) {
      return this.get<Array<ICampaign>>('/campaigns/getCampaignsNamesByClient?id=' + clientId)
   }

   getCampaignDetails(id: any) {
      return this.get<ICampaign>('/campaigns/getCampaignDetails?id=' + id)
   }
   saveCampaign(data: ICampaign) {
      return this.post('/campaigns/saveCampaign', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   deleteCampaign(ids: Array<string>) {
      return this.post('/campaigns/deleteCampaign', ids, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   getAudit(id: string) {
      return this.get<ICampaignAudit>('/campaigns/getAudit?id=' + id)
   }
   saveAudit(data: ICampaignAudit) {
      return this.post('/campaigns/saveAudit', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   async export(request: any) {
      return await this.postDownload('/campaigns/export', request);
   }
   mergeCampaigns(mergedCampaign: any, primaryCampaignId: string, CampaignIdsToMerge: string[]) {
      let url_ = "/campaigns/mergeCampaigns?";
      if (primaryCampaignId !== undefined && primaryCampaignId !== null)
         url_ += "primaryCampaignId=" + encodeURIComponent("" + primaryCampaignId) + "&";
      if (CampaignIdsToMerge !== undefined && CampaignIdsToMerge !== null)
         CampaignIdsToMerge && CampaignIdsToMerge.forEach(item => { url_ += "campaignIdsToMerge=" + encodeURIComponent("" + item) + "&"; });

      url_ = url_.replace(/[?&]$/, "");
      // const content_ = JSON.stringify(mergedClient);

      return this.post(url_, mergedCampaign, { 'Content-Type': 'application/json; charset=utf-8' });
   }
}
