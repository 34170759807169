import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Options, SortableEvent } from 'sortablejs';
import { IWidget } from '../../../core/models/widget.model';
import { AppService } from '../../../store/app.service';
import { AddWidgetItemComponent } from '../add-widget-item/add-widget-item.component';
@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})

export class WidgetComponent implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() widget!: IWidget;
  renameVisible: boolean = false;
  editVisible: boolean = false;
  newName: any = '';
  changeLayoutStarted: boolean = false;
  removeItemsList: string[] = [];

  constructor(private appService: AppService,
    private modalService: NgbModal,
    private router: Router) {
    this.appService.getChangeLayoutValue()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: boolean) => { this.changeLayoutStarted = response; });
  }

  ngOnInit(): void {
    if (this.widget.items.length && this.widget.items[0].value === '') {
      this.appService.fetchWidgetValues(this.widget.id);
    }
  }

  refresh() {
    this.widget.items.forEach(item => item.value = '');
    this.appService.fetchWidgetValues(this.widget.id);
  }

  removeWidget() {
    this.appService.removeWidget$(this.widget.id);
  }

  renameWidget() {
    this.renameVisible = true;
    this.newName = this.widget.name;
  }

  cancelRename() {
    this.renameVisible = false;
  }

  completeRename() {
    this.appService.renameWidget$(this.widget.id, this.newName);
    this.newName = '';
    this.renameVisible = false;
  }

  addItem() {
    const dialogRef = this.modalService.open(AddWidgetItemComponent);
    dialogRef.componentInstance.widgetId = this.widget.id;
    dialogRef.componentInstance.widgetName = this.widget.name;
  }

  edit() {
    this.editVisible = true;
    this.removeItemsList = [];
  }

  addItemToRemoveList(itemId: string) {
    this.removeItemsList.push(itemId);
  }

  isItemToBeDeleted(itemId: string) {
    return this.removeItemsList.indexOf(itemId) > -1;
  }


  completeEdit() {
    var request = {
      WidgetId: this.widget.id,
      RemoveItems: this.removeItemsList
    }
    this.appService.editWidgetItems$(request);
    this.removeItemsList = [];
    this.editVisible = false;
  }

  cancelEdit() {
    this.editVisible = false;
    this.removeItemsList = [];
  }

  itemClicked(item: any) {
    const query = { queryParams: { savedsearchid: item.savedSearchId } };
    if (item.resultType.toLowerCase() === 'user')
      this.router.navigate(['/pages/users'], query);

    else if (item.resultType.toLowerCase() === 'campaign')
      this.router.navigate(['/pages/campaigns'], query);

    else if (item.resultType.toLowerCase() === 'company')
      this.router.navigate(['/pages/companies'], query);

    else if (item.resultType.toLowerCase() === 'client')
      this.router.navigate(['/pages/clients'], query);

    else if (item.resultType.toLowerCase() === 'prospect')
      this.router.navigate(['/pages/prospects'], query);

    else if (item.resultType.toLowerCase() === 'tag')
      this.router.navigate(['/pages/tags'], query);

    else if (item.resultType.toLowerCase() === 'lead')
      this.router.navigate(['/pages/leads'], query);

    else if (item.resultType.toLowerCase() === 'task')
      this.router.navigate(['/pages/tasks'], query);
  }

  onWidgetItemDrop(event: SortableEvent) {
    const itemId = event.item.id;
    const newOrder = event.newIndex! + 1;
    this.appService.reorderWidgetItem(itemId, newOrder);
  }

  getSortableOptions(widget: IWidget) {
    const sortableOptions: Options = {
      group: 'widgetItems' + widget.id,
      handle: '.itemdraghandler',
      onUpdate: (event) => this.onWidgetItemDrop(event),
      dataIdAttr: 'id'
    };
    return sortableOptions;

  }
}