import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NOTES_TYPE } from '../../../core/helpers/constants';
import { INotes } from '../../../core/models/campaigns.model';
import { DataFeedService } from '../../api/datafeed.service';
import { AddNoteModal } from '../../modals/add-note/add-note.component';
import { AppService } from '../../../store/app.service';
import { CampaignsApiService } from '../../../pages/campaigns/campaigns.apiservice';
@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss']
})
export class PageNotFoundComponent {
  constructor() {
  }
}
