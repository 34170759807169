import { Injectable, ErrorHandler, Injector } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
//import { AppService } from './app.service';
import * as _ from 'underscore'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../environments/environment';
@Injectable()
export class ErrorService implements ErrorHandler {
    constructor(
        private injector: Injector,
        private ngxLoader: NgxUiLoaderService
    ) {
    }
    handleError(error: any) {
        if (error instanceof HttpErrorResponse) {
            let errorMessage = '';
            if (error.error instanceof ProgressEvent) {
                Swal.fire('Something really bad happened', error.message, 'error') 
            } else {
                // server-side error
                Swal.fire('Error', this.getErrorMessage(error), 'error')
            }
        } else {
            console.log(error + " ELSEE");

            if(environment.production == false){
                console.log(error);
            }
        }
        
        if(environment.production == false){
            console.log(error);
        }
    }

    getErrorMessage(error: any) {
        if (!_.isEmpty(error.error) && (typeof error.error === 'string' || error.error instanceof String))
            return error.error;
        if (!_.isEmpty(error.error) && (typeof error.error.msg === 'string' || error.error.msg instanceof String))
            return error.error.msg;
        if (!_.isEmpty(error.error) && (typeof error.error.Msg === 'string' || error.error.Msg instanceof String))
            return error.error.Msg;
        if (typeof error.message === 'string' || error.message instanceof String)
            return error.message;
    }
}
