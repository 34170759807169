import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalSearchApiService } from './global-search.apiservice';
import { ActivatedRoute, Router } from '@angular/router';
import { CAMPAIGN_STAGES } from '../../core/helpers/constants';
import { AppService } from '../../store/app.service';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})

export class GlobalSearchComponent implements OnInit {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  result: Map<string, any[]> = new Map();
  keyword: string = '';
  constructor(private searchApiService: GlobalSearchApiService,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setPageTitle$('');
    this.columns.prospects = [...this.columns.clients];

    this.route.params.subscribe(params => {
      this.keyword = params['keyword'];
      this.search(this.keyword);
    });
  }

  columns: any = {
    "clients": [
      { "field": "firstname", "title": "First Name" },
      { "field": "lastname", "title": "Last Name" },
      { "field": "brand", "title": "Company" },
      { "field": "dmsname", "title": "DMS" },
      { "field": "salesrep", "title": "Sales Rep" },
      { "field": "phone", "title": "Phone" },
      { "field": "email", "title": "Email" }
    ],
    "campaigns": [
      { "field": "name", "title": "Name" },
      { "field": "clientname", "title": "Client" },
      { "field": "platform", "title": "Platform" },
      { "field": "dms", "title": "DMS" },
      { "field": "stage", "title": "Stage", "transform": this.getStageName },
      { "field": "subscriptionactive", "title": "Active Subscription", transform: this.getActiveSubscribtion }
    ],
    "tags": [
      { "field": "name", "title": "Name" },
      { "field": "description", "title": "Description" },
      { "field": "categories", "title": "Categories" }
    ],
    "companies": [
      { "field": "companyname", "title": "Name" },
      { "field": "firstname", "title": "First Name" },
      { "field": "lastname", "title": "Last Name" },
      { "field": "email", "title": "Email" }
    ],
    "users": [
      { "field": "firstname", "title": "First Name" },
      { "field": "lastname", "title": "Last Name" },
      { "field": "email", "title": "Email" }
    ],
    "leads": [
      { "field": "firstname", "title": "First Name" },
      { "field": "lastname", "title": "Last Name" },
      { "field": "email", "title": "Email" },
      { "field": "phone", "title": "Phone" },
      { "field": "campaignname", "title": "Campaign" },
      { "field": "referralid", "title": "Referral Id" },
      { "field": "referralsource", "title": "Referral Source" },
    ],
    "tasks": [
      { "field": "title", "title": "Title" },
      { "field": "clientname", "title": "Client Name" },
      { "field": "campaignname", "title": "Campaign Name" },
      { "field": "duedate", "title": "Due Date" },
      { "field": "assignedusername", "title": "Assigned User" },
    ],
    "subscriptions": [
      { "field": "numericid", "title": "#" },
      { "field": "product", "title": "Products" },
      { "field": "clientname", "title": "Client Name" },
      { "field": "startdate", "title": "Start Date", pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY'] },
      { "field": "status", "title": "Status" },
      { "field": "campaigns", "title": "Campaigns" },
    ]
  }

  search(keyword: string) {
    this.result.clear();
    const that = this;
    this.searchApiService.getSearchResults(keyword)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        that.result = that.processSearchResponse(response, that.columns);
      });
  }

  processSearchResponse(response: any, columns: any): Map<string, any[]> {
    response.data = this.onlyUnique(response.data);
    var result: Map<string, any[]> = new Map();
    for (let i = 0; i < response.data.length; i++) {
      const responseItem = response.data[i];
      const type = responseItem.estype;
      const defination = columns[type];
      if (!result.has(type)) {
        result.set(type, []);
      }
      let row: any = {};
      Object.keys(responseItem).forEach((key: string) => {
        var lowerCaseKey = key.toLowerCase();
        if (defination.find((x: any) => x.field === lowerCaseKey)?.transform) {
          row[lowerCaseKey] = defination.find((x: any) => x.field === lowerCaseKey).transform(responseItem);
        } else {
          row[lowerCaseKey] = responseItem[key];
        }
      });
      result.get(type)?.push(row);
    }
    return result;
  }

  onlyUnique(arr: Array<any>) {
    arr = arr.map((x: any) => { return { ...x, id: x.id.split('_')[0] } });
    arr = arr.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    return arr;
  }

  getLink(entity: any) {
    if (entity.estype === 'clients') { return `/pages/client-details/${entity.id}`; }
    else if (entity.estype === 'campaigns') { return `/pages/campaign-details/${entity.id}`; }
    else if (entity.estype === 'tags') { return `/pages/tags/`; }
    else if (entity.estype === 'companies') { return `/pages/companies/`; }
    else if (entity.estype === 'users') { return `/pages/users/`; }
    else if (entity.estype === 'leads') { return `/pages/leads/details/${entity.id}`; }
    else if (entity.estype === 'tasks') { return `/pages/tasks`; }
    else if (entity.estype === 'subscriptions') { return `/pages/subscriptions/${entity.id}`; }
    else if (entity.estype === 'prospects') { return `/pages/prospect-details/${entity.id}`; }
    return null;
  }

  onRowClick(evt: any) {
    const link = this.getLink(evt);
    this.router.navigate([link]);
  }

  getStageName(item: any) {
    return CAMPAIGN_STAGES.find(x => x.value === item.Stage)?.title;
  }

  getActiveSubscribtion(item: any) {
    return item.subscriptionactive ? "Yes" : "No";
  }
}
