<div class="row">
    <div class="col-12">
        <div class="card card-custom">
            <form [formGroup]="dtForm" *ngIf="iShowHeader">
                <div class="card-header">
                    <div class="d-flex flex-md-row flex-lg-row flex-sm-row justify-content-between align-items-center">
                        <div class="flex-row">
                            <div
                                *ngIf="(iShowActions && !iHideHeaderActions) && (isAdmin || iCanDelete || iShowExport) && activePage !== PAGE_NAMES.Users">
                                <div *ngIf="isAdmin || iCanDelete || iShowMerge" ngbDropdown
                                    class="d-inline-block dropdown-custom">
                                    <button style="margin-right: 20px;" class="btn btn-dd" id="dropdownBasic1"
                                        ngbDropdownToggle>Actions</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <!-- <button *ngIf="(isAdmin || iCanWrite) && iShowDropdownAdd" (click)="addNew()"
                                            ngbDropdownItem>Add
                                            New</button> -->
                                        <button *ngIf="(isAdmin || iCanDelete) && iShowDropDownDelete"
                                            (click)="deleteRows()" ngbDropdownItem>Delete</button>

                                        <button *ngIf="iShowMerge && checkedItemsCount >= 2" type="button"
                                            ngbDropdownItem (click)="mergeRecords()"
                                            title="Please select two or more records to merge!">Merge
                                        </button>
                                    </div>
                                </div>
                                <div class="d-inline-block">
                                    <button *ngIf="iShowExport" type="button" class="btn btn-outline-dark button"
                                        style="padding-top: 3px;padding-bottom: 3px;"
                                        (click)="exportToCsv()">Export</button>
                                    <!-- <button *ngIf="iShowMerge" [disabled]="checkedItemsCount <= 1" type="button"
                                        class="btn-custom" style="margin-right:10px" (click)="mergeRecords()" title="Please select two or more records to merge!">Merge
                                    </button> -->
                                </div>
                            </div>
                        </div>
                        <div *ngIf="iShowDateRange" class="d-flex flex-row align-items-center">
                                    <label class="d-inline-flex align-items-center">From: </label>&nbsp;
                                    <input id="dtStartDate"
                                        mwlFlatpickr [altInputClass]="'datetime'" [altInput]="true"
                                        [convertModelValue]="true" formControlName="startDate" class="form-control" />
                                        &nbsp;&nbsp;&nbsp;
                                    <label class="d-inline-flex align-items-center">To: </label>&nbsp;
                                    <input id="dtEndDate"
                                        mwlFlatpickr [altInputClass]="'datetime'" [altInput]="true"
                                        [convertModelValue]="true" formControlName="endDate" class="form-control" /><a (click)="clearDates()"><i-feather name="x-circle" style="margin: 5px 0px 0px 5px; cursor: pointer;"></i-feather></a>
                        </div>
                        <div class="d-flex flex-row align-items-center">

                            <div *ngIf="iShowSearch" id="tickets-table_filter" class="dataTables_filter text-md-end">
                                <label class="d-inline-flex align-items-center" style="margin-bottom: 0;">Search:
                                    <input id="txtSearchQuery" type="text" formControlName="searchQuery"
                                        class="form-control form-control-sm ms-2" aria-controls="tickets-table"
                                        (keydown.enter)="doServerSearch($event)"></label>
                            </div>

                            <div class="d-flex justify-content-between align-items-center">

                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    formControlName="pageSize" (change)="onPageSizeChange($event)"
                                    class="form-control form-select select-custom">
                                    <option [value]="10">10 Per Page</option>
                                    <option [value]="25">25 Per Page</option>
                                    <option [value]="50">50 Per Page</option>
                                    <option [value]="100">100 Per Page</option>
                                    <option [value]="250">250 Per Page</option>
                                    <option [value]="500">500 Per Page</option>
                                </select>

                                <div class="text-md-right float-md-end pagination-rounded">
                                    <ngb-pagination [maxSize]="5" [rotate]="true" [ellipses]="true"
                                        [collectionSize]="collectionSize" [page]="1" [boundaryLinks]="true"
                                        (pageChange)="onPageChange($event)" [pageSize]="f.pageSize.value">
                                    </ngb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="card-body">
                <table cellspacing="0" cellpadding="0" id="datatable" class="table dt-responsive nowrap w-100">
                    <thead>
                        <tr>
                            <th *ngIf="iShowSelect  && (isAdmin || iCanDelete || iCanWrite)">
                                <div class="form-check">
                                    <input (change)="onCbHeadChange($event)" class="form-check-input" type="checkbox">
                                </div>
                            </th>
                            <ng-container *ngFor="let col of iColumns; let i=index">
                                <th *ngIf="col.visible === undefined || col.visible" dtSortable
                                    [attr.sortable]="(col.sortable  && col.sortable)? col.field : null"
                                    [sortable]="(col.sortable  && col.sortable)? col.field : null"
                                    (sort)="onSort($event, col)">
                                    {{col.title}}
                                    <ng-container *ngIf="col.title === '' && col.field === 'media'">
                                        <i class="far fa-image fa-2x"></i>
                                    </ng-container>
                                    <ng-container *ngIf="col.title === '' && col.field === 'html'">
                                        <i class="fas fa-code fa-2x"></i>
                                    </ng-container>
                                </th>
                            </ng-container>


                            <th *ngIf="(iShowActions || iShowViewAction) && (iCanWrite || iCanDelete)">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="viewData.length==0">
                            <td style="text-align: center;"
                                [attr.colspan]="(iColumns.length + 1) + ( iShowActions ? 1 :0)">
                                No data found
                            </td>

                        </tr>
                        <tr [style.color]="row.isDeleted ? 'red' : null" *ngFor="let row of viewData; let i=index">
                            <td *ngIf="iShowSelect && (isAdmin || iCanDelete || iCanWrite)">
                                <div class="form-check">
                                    <input [(ngModel)]="row.isSelected" (change)="onCbChange($event,row)"
                                        (click)="$event.stopPropagation();" class="form-check-input" type="checkbox">
                                </div>
                            </td>
                            <td *ngFor="let col of iColumns; let j=index" style="padding: 0px;height: 30px;">
                                <a *ngIf="(col.visible === undefined || col.visible )"
                                    style="color: inherit ;display: block;padding: 10px;line-height: 30px;"
                                    (click)="oRowClick.emit(row)" [routerLink]="getRowLink(row).routerLink"
                                    [queryParams]="getRowLink(row).queryParams" role="link">
                                    <div *ngIf="col.anchorLink && col.anchorLink; else html">
                                        <a [routerLink]="getAnchorLink(col.anchorType, row[col.anchorParamsField] )">
                                            {{row[col.field]}}
                                        </a>
                                    </div>

                                    <ng-template #html>
                                        <div *ngIf="col.type === 'html'; else image"
                                            [innerHTML]="SafeHtml(row[col.field])">
                                        </div>
                                    </ng-template>

                                    <ng-template #image>
                                        <div *ngIf="col.type === 'image'; else normal">
                                            <img [src]="SafeUrl(extractFieldValue(row, col.field,col.pipe, col?.pipeArgs))"
                                                style="height: 25px;" />
                                        </div>
                                    </ng-template>

                                    <ng-template #normal>
                                        <ngb-highlight
                                            [result]="extractFieldValue(row, col.field, col.pipe, col?.pipeArgs)">
                                        </ngb-highlight>
                                    </ng-template>
                                </a>
                            </td>
                            <td
                                *ngIf="( iShowActions || iShowViewAction )  && (iCanWrite || iCanDelete || iCanRead || true)">
                                <!-- <div *ngIf="(col.visible ===undefined || col.visible )"> -->
                                <a *ngIf="iShowEditAction && (isAdmin || (iCanWrite && (row.canBeEdit == null || row.canBeEdit == true)) )" class="a-btn"
                                    style="margin-right: 10px;" (click)="editRow(row);$event.stopPropagation();">
                                    <i-feather name="edit"></i-feather>
                                </a>
                                <!-- <div *ngIf="(col.visible ===undefined || col.visible )"> -->
                                <a *ngIf="iShowViewAction && (isAdmin || iCanRead)" class="a-btn"
                                    style="margin-right: 10px;" (click)="viewRow(row);$event.stopPropagation();"
                                    title="View">
                                    <i-feather name="eye"></i-feather>
                                </a>
                                <a *ngIf="iShowExternalAction && (isAdmin || iCanRead)" class="a-btn"
                                    style="margin-right: 10px;" (click)="showExternal(row);$event.stopPropagation();"
                                    title="Opens on new page">
                                    <i-feather name="external-link"></i-feather>
                                </a>
                                <a *ngIf="showDeleteAction(row) && iShowDeleteAction && (isAdmin || iCanDelete) && ( activePage === PAGE_NAMES.Users ? iActiveUserId !== row?.id && row?.archived == true : true)"
                                    class="a-btn" style="margin-right: 10px;"
                                    (click)="onDeleteRow($event,row);$event.stopPropagation();">
                                    <i-feather name="trash"></i-feather>
                                </a>
                                <a *ngIf="(isAdmin || iCanDelete) && ( activePage === PAGE_NAMES.Users ? iActiveUserId !== row?.id  && row?.archived == true : false)"
                                    class="a-btn" style="margin-right: 10px;"
                                    (click)="onUnArchiveRow($event,row);$event.stopPropagation();">
                                    <mat-icon title="UnArchive">unarchive</mat-icon>
                                </a>
                                <a *ngIf="(isAdmin || iCanDelete) && ( activePage === PAGE_NAMES.Users ? iActiveUserId !== row?.id  && row?.archived == false : false)"
                                    class="a-btn" style="margin-right: 10px;"
                                    (click)="onArchiveRow($event,row);$event.stopPropagation();">
                                    <mat-icon title="Archive">archive</mat-icon>
                                </a>
                                <a *ngIf="iShowSendEmail" title="Email" class="a-btn" style="margin-right: 10px;"
                                    (click)="onSendEmail(row);$event.stopPropagation();">
                                    <i-feather name="mail"></i-feather>
                                </a>
                                <a *ngIf="iShowMakeCopyAction && iCanWrite" title="Create copy of this product" class="a-btn"
                                    style="margin-right: 10px;"
                                    (click)="oOnCopyClicked.emit(row);$event.stopPropagation();">
                                    <i-feather name="copy"></i-feather>
                                </a>
                                <button *ngIf="showCustomAction(row)" type="button" class="btn btn-outline-dark button"
                                    style="padding-top: 3px;padding-bottom: 3px;"
                                    (click)="executeCustomAction(row)">{{iCustomActionLabel}}</button>
                                <!-- <a class="a-btn" [routerLink]="">
                                    <i-feather name="list"></i-feather>
                                </a> -->
                                <!-- </div> -->

                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing
                            {{service.startIndex}} to
                            {{service.endIndex}} of {{service.totalRecords}}
                            entries
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                </div> -->

            </div>
        </div> <!-- end col -->
    </div> <!-- end row -->
</div>