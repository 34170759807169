import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { IPermission } from '../../../core/models/auth.models';
import { ICompany } from '../../../core/models/datafeed.models';
import { AppService } from '../../../store/app.service';
import { CompanyApiService } from '../company.apiservice';
import { CompanyService } from '../company.service';

@Component({
  selector: 'app-company-main',
  templateUrl: './company-main.component.html',
  styleUrls: ['./company-main.component.scss'],
})
export class CompanyMainComponent implements OnInit, OnDestroy {
  doWhat: IPermission = {} as IPermission;
  columns = [
    { title: 'Company Name', field: 'name', sortable: true, searchable: true },
    { title: 'Contact Name', field: 'companyOwner.firstName', sortable: true, searchable: true },
    { title: 'Contact Email', field: 'companyOwner.email', searchable: true, sortable: true },
    { title: 'Contact Phone', field: 'companyOwner.phone', searchable: true },
  ];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  companiesData: Array<ICompany>

  constructor(
    private appService: AppService,
    private companyApiService: CompanyApiService,
    private route: ActivatedRoute,
    private compService: CompanyService,
  ) {
    this.companiesData = new Array<ICompany>();
  }

  ngOnInit(): void {

    this.appService.setPageTitle$('Companies');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Companies);
    this.appService.getAllCompanies$()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: Array<ICompany>) => {
        this.companiesData = [...response];
      });
    const savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
    this.appService.fetchAllCompanies(savedSearchId);
  }

  editRow(evt: any) {
    this.compService.openEditCompanyModal(evt)
  }
  deleteRows(evt: any) {
    this.companyApiService.deleteCompany(evt)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.appService.fetchAllCompanies(null);
      })

  }
  onClientRowClick(evt: any) {
    this.compService.openEditCompanyModal(evt)
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
