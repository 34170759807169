<div fxLayout="column" fxLayoutAlign="start center">
    <aw-wizard class="wizard-design" style="min-width: 100%;">
        <aw-wizard-step 
            style="margin-top: 16px; min-height: 400px;" stepTitle="Product(s)">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-right: 32px;">
                <div>
                    <p *ngIf="doneLoading && ((userRoles.includes(1)) || (userRoles.includes(2)) || (userRoles.includes(4)) || (userRoles.includes(3)) || (userRoles.includes(10)) || (userRoles.includes(12)))">Please select a Company:</p>
                    <ng-select *ngIf="doneLoading && ((userRoles.includes(1)) || (userRoles.includes(2)) || (userRoles.includes(4)) || (userRoles.includes(3)) || (userRoles.includes(10)) || (userRoles.includes(12)))" [items]="this.allMyCompanies" bindLabel="name" bindValue="id" (change)="onCompanyChange($event)" style="width: 33vw !important;"></ng-select>
                </div>
                <div *ngIf="(allProducts.length / pageSize) > 1" class="text-md-right float-md-end pagination-rounded">
                    <ngb-pagination [maxSize]="1" [rotate]="true" [ellipses]="true"
                        [collectionSize]="allProducts.length" [page]="pageNumber" [boundaryLinks]="true"
                        (pageChange)="onPageChange($event)" [pageSize]="pageSize">
                    </ngb-pagination>
                </div>
            </div>    
            <div fxLayout="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start" style="margin-top: 16px !important;">
                <ng-container *ngFor="let p of tempProducts">
                    <app-product-tile (click)="onAddtoCart(p.id)" [product]="p" fxFlex.xs="100%" fxFlex.sm="33%" fxFlex="0 1 calc(33.3% - 32px)"></app-product-tile>
                </ng-container>
            </div>
            <div *ngIf="(allProducts.length / pageSize) > 1" fxLayout="row" fxLayoutAlign="end center" style="padding-right: 32px;">
                <div class="text-md-right float-md-end pagination-rounded">
                    <ngb-pagination [maxSize]="1" [rotate]="true" [ellipses]="true"
                        [collectionSize]="allProducts.length" [page]="pageNumber" [boundaryLinks]="true"
                        (pageChange)="onPageChange($event)" [pageSize]="pageSize">
                    </ngb-pagination>
                </div>
            </div>    
        </aw-wizard-step>
        <aw-wizard-step 
            style="margin-top: 40px; min-height: 400px;" stepTitle="Location">
        </aw-wizard-step>
        <aw-wizard-step 
            style="margin-top: 40px; min-height: 400px;" stepTitle="Ad Spend">
        </aw-wizard-step>
        <aw-wizard-step 
            style="margin-top: 40px; min-height: 400px;" stepTitle="Checkout">
        </aw-wizard-step>
    </aw-wizard>
</div>