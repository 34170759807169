<div style="background-color: white !important;">
    <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 8px 16px 16px 16px;">
        <div style="display: flex; flex-direction: row; align-items: center; padding: 8px 4px 8px 8px; border: 1px solid grey; border-radius: 16px; min-width: 265px; background-color: white; max-height: 34px !important;">
            <i-feather name="search" style="width: 16px; height: 16px; color: grey;"></i-feather>
            <input type="text" placeholder="Search" class="form-control form-control-sm ms-2" [(ngModel)]="searchString"
                style="border: none !important; background-color: white !important; max-height: 15px; max-width: 220px;" (keydown.enter)="doNoteSearch()">
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <div *ngIf="iCanWrite || iIsAdmin" style="background-color: white !important;" class="hide-from-small-578">
                <button style="float: right; margin-right: 16px;" class="blue-button" type="button" (click)="onOpenNote()">
                    ADD NOTE
                </button>
                <!-- <button *ngIf="iType == 1" style="float: right; margin-right: 16px;" class="blue-button" type="button" (click)="onCampaignCheck()">
                    CAMPAIGN CHECK
                </button> -->
            </div>
            <div class="text-md-right float-md-end pagination-rounded">
                <ngb-pagination [maxSize]="1" [rotate]="true" [ellipses]="true"
                    [collectionSize]="notesData.length" [page]="pageNumber" [boundaryLinks]="true"
                    (pageChange)="onPageChange($event)" [pageSize]="pageSize">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <table style="margin-top: 0px !important; width: 100% !important;">
        <thead>
            <th style="width: 5%;"></th>
            <th style="width: 5%;">UPDATED</th>
            <th style="width: 15%; text-align: center;">AUTHOR</th>
            <th *ngIf="isClientNotes()" style="width: 25%;">CAMPAIGN</th>
            <th style="width: 100%;">NOTE</th>
            <th *ngIf="iCanWrite || iCanDelete || iIsAdmin"> {{iHideActionColName ? '': 'ACTIONS' }}</th>
        </thead>
        <tbody>
            <tr *ngIf="notesData.length==0">
                <td style="text-align: center; padding: 32px;" [attr.colspan]="isClientNotes() ? 5 : 4">
                    No data found
                </td>
            </tr>
            <tr *ngFor="let note of tempNotesData; let i=index">
                <td style="vertical-align: top; width: 5%; text-align: center;">
                    <span *ngIf="note.isInternal == true"><i-feather title="Internal" name="eye-off"></i-feather></span>
                </td>
                <td style="vertical-align: top; width: 5%; text-align: center;" class="color-anchor">
                    <p style="font-weight: bold; color: #495057; padding-right: 8px;" class="hide-from-small">UPDATED: </p>{{note.updatedOn | P_AppDateFormat}}
                </td>
                <td style="vertical-align: top; width: 15%; text-align: center;" class="color-anchor">
                    <p style="font-weight: bold; color: #495057; padding-right: 8px;" class="hide-from-small">AUTHOR: </p>{{note.assignedUserName}}
                </td>
                <td *ngIf="isClientNotes()" class="color-anchor" style="vertical-align: top; width: 15%; text-align: start;">
                    <p class="hide-from-small" style="font-weight: bold; color: #495057; padding-right: 8px;">CAMPAIGN: </p>
                    <a *ngIf="note.campaignId" [routerLink]="['/pages/campaign-details', note.campaignId]"
                        class="text-dark">
                        {{note.campaignName}} &nbsp; <i-feather name="link" style="width: 16px; height: 16px;">
                        </i-feather>
                    </a>
                </td>
                <td style="flex-direction: column;width: 100%; padding-left: 12px;">
                    <p class="hide-from-small" style="text-decoration: underline; font-weight: bold; color: #495057;">NOTE: </p>
                    <label class="color-anchor" style="font-weight: bold;font-size: 15px;"> {{note.title}}</label>
                    <p style="white-space: pre-wrap;word-break:break-word" class="color-anchor">{{note.description}}
                    </p>
                    <!-- <div [innerHTML]="SafeHtml(note.description)">
                                    </div> -->
                    <div *ngFor="let file of note.noteFiles">
                        <a (click)="getAttachmentUrl(note.id, file.name)" target="_blank"
                            style="cursor: pointer;color: inherit;">
                            {{file.name}}
                            <i style="margin-left: 10px;" class="fas fa-download"></i>
                        </a>
                    </div>
                </td>
                <td *ngIf="iCanWrite || iCanDelete || iIsAdmin" style="white-space: nowrap; vertical-align: top;">
                    <a *ngIf="iCanWrite || iIsAdmin" class="a-btn" style="margin-right: 10px;" [routerLink]=""
                        (click)="onOpenNote(note.id);$event.stopPropagation();">
                        <i-feather name="edit"></i-feather>
                    </a>
                    <a *ngIf="iCanDelete || iIsAdmin" class="a-btn" style="margin-right: 10px;" [routerLink]=""
                        (click)="onDeleteNote(note.id);$event.stopPropagation();">
                        <i-feather name="x"></i-feather>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
