import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/http/base.http';

@Injectable()
export class ProductsApiService extends HttpService {

  getAllProducts(savedSearch: string = '') {
    return this.get<any>(`/products/getAllProducts`);
  }

  getProductDetails(id: string) {
    return this.get<any>(`/products/getProductDetails?id=${id}`);
  }

  getProductMedia(id: string) {
    return this.get<any>(`/products/getProductMedia?id=${id}`, { 'responseType': 'blob' as 'json' });
  }

  saveProduct(data: any) {
    return this.post('/products/saveProduct', data, { 'Content-Type': 'application/json; charset=utf-8' });
  }
  makeProductCopy(id: string) {
    return this.get('/products/makeProductCopy?id=' + id);
  }
  saveProductMedia(data: any) {
    return this.post('/products/saveProductMedia', data);
  }
  deleteProducts(data: Array<string>) {
    return this.post('/products/deleteProducts', data, { 'Content-Type': 'application/json; charset=utf-8' });
  }


  // create(data: IProduct) {
  //   // console.log('creating: ', data)
  //   return this.post<{ good: boolean, data: IProduct }>(`${this.productsApiBase}`, data, { 'Content-Type': 'application/json; charset=utf-8' });
  // }

  // update(id: string, data: IProduct): Observable<any> { // : Observable<{ good: boolean, data: IProduct }> {
  //   return this.put(`${this.productsApiBase}/${id}`, data, { 'Content-Type': 'application/json; charset=utf-8' });
  // }

  // deleteOne(id: string): Observable<any> { // <{ good: boolean, data: number }>
  //   return this.delete(`${this.productsApiBase}/${id}`, { 'Content-Type': 'application/json; charset=utf-8' });
  // }

  // deleteMany(ids: Array<string>) {
  //   return this.post<{ good: boolean, data: number }>(`${this.productsApiBase}/DeleteMany`, ids, { 'Content-Type': 'application/json; charset=utf-8' });
  // }

  // uploadProductMedia(productId: string, file: File) {
  //   const formData = new FormData();
  //   formData.append("Files", file);
  // return this.post<{ good: boolean, data: IProductsMedium }>(`${this.productsApiBase}/ProductsMedia/${productId}`, formData);
  // }

  // saveAssociatedTags(productId: string, tagAss: any[]) {
  //   return this.post<{ good: boolean, data: { productId: string, tagId: string } }>(`${this.productsApiBase}/ProductsTags/${productId}`, tagAss, { 'Content-Type': 'application/json; charset=utf-8' });
  // }

  // saveProductDependencies(productId: any, productDependencies: any[]) {
  //   return this.post<{ good: boolean, data: any }>(`${this.productsApiBase}/ProductsDependencies/${productId}`, productDependencies, { 'Content-Type': 'application/json; charset=utf-8' });
  // }
  // updateProductRequiredDependencies(productId: string, requiredDependencies: string): Observable<any> {
  //   let payload = { requiredDependencies: requiredDependencies };
  //   return this.post<{ good: boolean, data: any }>(`${this.productsApiBase}/ProductsDependencies/Required/${productId}/${requiredDependencies}`, { 'Content-Type': 'application/json; charset=utf-8' });
  // }

  // deleteManyDependencies(productId: string, productDependentsIds: Array<string>): Observable<any> {
  //   return this.post(`${this.productsApiBase}/ProductsDependencies/Delete/${productId}`, productDependentsIds, { 'Content-Type': 'application/json; charset=utf-8' });
  // }
}
