<div class="container-fluid bg-white p-3">
    <div class="col-12">
      <div class="card report-card">
        <div class="card-body">
          
          <!-- Show selector if there are multiple companies -->
          <ng-container *ngIf="companiesData.length > 1; else singleCompanyView">
            <label for="companySelector">Select Company:</label>
            <select id="companySelector" class="form-select" [(ngModel)]="selectedCompanyId" (change)="updateIframeUrl(selectedCompanyId)">
              <option *ngFor="let company of companiesData" [value]="company.id">{{ company.name }}</option>
            </select>
            <div class="embed-responsive embed-responsive-16by9 mt-3">
              <iframe class="embed-responsive-item" [src]="iframeUrl" width="100%" height="600" style="border: none;"></iframe>
            </div>
          </ng-container>
  
          <!-- Show iframe directly if only one company -->
          <ng-template #singleCompanyView>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" [src]="iframeUrl" width="100%" height="60vh" style="border: none;"></iframe>
            </div>
          </ng-template>
  
        </div>
      </div>
    </div>
  </div>