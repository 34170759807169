import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICampaign } from '../../../core/models/campaigns.model';

@Component({
  selector: 'app-client-reporting-update-select-campaigns-modal',
  templateUrl: './client-reporting-update-select-campaigns-modal.component.html',
  styleUrls: ['./client-reporting-update-select-campaigns-modal.component.scss']
})
export class ClientReportingUpdateSelectCampaignsModalComponent implements OnInit {
  @Input() CurrentlySelected: Array<ICampaign> = [];
  @Input() ReportType: string = '';
  @Input() Platform: string = '';
  @Input() CampaignsData: Array<ICampaign> = [];
  @Output() oSaveClicked = new EventEmitter<Array<string>>();

  CampaignOptions$ = new BehaviorSubject<Array<{ text: string, value: string }>>([]);

  constructor() { }

  ngOnInit(): void { 
    this.updateCampaignsOptions();
  }

  updateCampaignsOptions(): void {
    // Using All Campaigns
    var unfilteredOptions = this.createSelectOptions(this.CampaignsData, this.Platform, this.ReportType);

    // Get Selected options
    var selectedCampaigns = this.SelectedOptions(this.CurrentlySelected, this.Platform, this.ReportType);

    // Not Selected
    var opts = unfilteredOptions.filter(co => !selectedCampaigns.find(sc => co.value === sc.value));
    this.CampaignOptions$.next(opts);
  }

  SelectedOptions(campaigns: Array<ICampaign>, platform: string, reportType: string): Array<{ text: string, value: string }> {
    if (!campaigns || !platform) return [];
    let res = campaigns.filter(f => f.platform.toLowerCase() === platform.toLowerCase());

    return Array.from(res, r => { return { value: r.campaignId, text: `${r.name}` }; });
  }

  createSelectOptions(campaigns: Array<ICampaign>, platform: string, reportType: string): Array<{ text: string, value: string }> {
    if (!campaigns || !platform) return [];
    let res = campaigns.filter(f => f.platform.toLowerCase() === platform.toLowerCase());

    return Array.from(res, r => { return { value: r.id, text: `${r.name}` }; });

    // if (reportType == 'Simple') {
    //   let nonUnique = res.filter(f => f.platformAccountId).map(m => m.platformAccountId);
    //   let unique = new Set(nonUnique); // Set returns only unique values.

    //   return Array.from(unique, val => { return { value: val, text: val }; });
    // }

    // if (reportType == 'Advanced') {
    //   return Array.from(res, r => { return { value: r.id, text: `${r.platform} - ${r.platformCampaignId} - ${r.name}` }; });
    // }

    // return [];
  }


  getCampaignsByPlatformByAccount(accountId: string): Array<string> {
    let nonUnique = this.CampaignsData
      .filter(f => f.platform.toLowerCase() === this.Platform.toLowerCase())
      .filter(f => f.platformAccountId == accountId)
      .map(m => m.id);
    let unique = new Set(nonUnique);

    return Array.from(unique, val => val);
  }

  onSubmit(selectedValue: string) {
    console.log('Pop submitted.');
    // let campaigns = this.CampaignsData.filter(x => x.id == selectedValue)[0];
    // this.CurrentlySelected.push(campaigns);
    // this.updateCampaignsOptions();
    // if (this.ReportType == 'Simple') {
    //   let campaigns = this.getCampaignsByPlatformByAccount(selectedValue);
    //   this.oSaveClicked.emit(campaigns)
    // }

    // if (this.ReportType == 'Advanced') {
    //   this.oSaveClicked.emit([selectedValue])
    // }

    this.oSaveClicked.emit([selectedValue])

  } 
} 
