import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
    toasts: any[] = [];

    show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
        this.toasts.push({ textOrTpl, ...options });
    }

    remove(toast: any) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }

    showStandard(msg: string, delay: number = 10000) {
        this.show(msg, { classname: 'bg-primary text-center text-white', delay });
    }

    /**
     * Success message 
     */
    showSuccess(msg: string, delay: number = 10000) {
        this.show(msg, { classname: 'bg-success text-center text-white', delay });
    }

    /**
     * Danger message 
     */
    showDanger(msg: string, delay: number = 10000) {
        this.show(msg, { classname: 'bg-danger text-center text-white', delay });
    }

    /**
     * Warning message 
     */
    showWarning(msg: string, delay: number = 10000) {
        this.show(msg, { classname: 'bg-warning text-center text-white', delay });
    }
}
