import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { createEmptyGuid } from '../../../core/helpers/utility';
import { IPermission } from '../../../core/models/auth.models';
import { ICampaign } from '../../../core/models/campaigns.model';
import { ICompany } from '../../../core/models/datafeed.models';
import { IReportingPeriod } from '../../../core/models/reporting.models';
import { DataFeedService } from '../../../shared/api/datafeed.service';
import { AppService } from '../../../store/app.service';
import { ReportsApiService } from '../reports.apiservice';
import { IRollupReportDataResponse } from '../../../core/models/reports.models';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-reports-rollup-company-main',
  templateUrl: './reports-rollup-company-main.component.html',
  styleUrls: ['./reports-rollup-company-main.component.scss']
})
export class ReportsRollupCompanyCampaignsComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;

  bShowSelect$ = new BehaviorSubject(false);

  companyData = {} as ICompany;
  platforms: Array<string> = [];

  columns = [
    { title: 'Name', field: 'name', sortable: true, searchable: true },
    // { title: 'Client', field: 'clientName', sortable: true, searchable: true },
    { title: 'Platform', field: 'platform', sortable: true, searchable: true },
    { title: 'DMS', field: 'dmsName', sortable: true, searchable: true },
    { title: 'Stage', field: 'stageId', sortable: true, pipe: 'P_CampaignStageTitle', searchable: true },
    { title: 'Active Subscription', field: 'subscriptionActive', pipe: 'P_Boolean', searchable: true },
  ]
  campaignsData: Array<ICampaign> = [];
  filteredCampaignsData = new BehaviorSubject<Array<ICampaign>>([]);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private fb: FormBuilder,
    private reportsService: ReportsApiService,
    private dataFeedService: DataFeedService,
    private location: Location,) {
  }

  reportForm: FormGroup = this.fb.group({
    key: [null, [Validators.required]],
    startDate: [null, [Validators.required]],
    stopDate: [null, [Validators.required]],
    platforms: ['All', [Validators.required]],
    reportFormat: ['html', [Validators.required]],
    aggregation: ['All', [Validators.required]],
  });
  get key() { return this.reportForm.get('key') as FormControl; }
  get startDate() { return this.reportForm.get('startDate') as FormControl; }
  get stopDate() { return this.reportForm.get('stopDate') as FormControl; }
  get formPlatforms() { return this.reportForm.get('platforms') as FormControl; }
  get reportFormat() { return this.reportForm.get('reportFormat') as FormControl; }
  get aggregation() { return this.reportForm.get('aggregation') as FormControl; }

  companyId = '';
  ngOnInit(): void {
    this.appService.setPageTitle$('Rollup Report');
    this.appService.setActivePage$(PAGE_NAMES.Reports);
    
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Reports);

    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.companyId = id;
      this.getCompanyData(id);
      this.getCompanyAssociatedCampaigns(id);
    }
    this.getSupportedReportingPeriods();

    let orderId = this.route.snapshot.paramMap.get('orderId');
    console.log('orderId: ', orderId);
    if (orderId) {
      this.GetRollupReportDataResponse(orderId);
    }
  }

  getCompanyData(id: string) {
    if (!this.isEmptyCompanyGuid) {
      this.dataFeedService.getCompanyData(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          // console.log(response);
          this.companyData = response.data;
        });
    }
  }

  getCompanyAssociatedCampaigns(id: string) {
    this.dataFeedService.getCompanyAssociatedCampaigns(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        // console.log(response);
        this.campaignsData = [...response.data];
        this.campaignsData = this.campaignsData.filter(f => f.campaignAdAccounts.length > 0);

        this.platforms = Array.from(new Set(this.campaignsData.map(item => item.platform)));
        this.applyPlatformFilterOnCampaigns('All');
      });
  }

  applyPlatformFilterOnCampaigns(platform: string) {
    if (platform.toLowerCase() == 'all') {
      this.filteredCampaignsData.next(this.campaignsData);
    }
    else {
      let filtered = this.campaignsData
        .filter(f => f.platform.toLowerCase() == platform.toLowerCase())
      this.filteredCampaignsData.next(filtered);
    }
  }

  reportPeriods: Array<IReportingPeriod> = [];
  getSupportedReportingPeriods() {
    this.reportsService.getSupportedReportingPeriods()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: Array<any>) => {
        // console.log(response); 
        this.reportPeriods = response as Array<IReportingPeriod>;

        // console.log(this.reportPeriods);
        this.onReportPeriodChange("Past Week");
      });
  }

  reportformsubmitted = false;
  DateStart = '' as string | undefined | null;
  DateStop = '' as string | undefined | null;
  get selectedReportPeriod(): string | undefined | null { return this.reportForm?.controls?.key?.value; }
  get datesReadonly(): boolean { return !(this.selectedReportPeriod == 'Custom'); }
  onReportPeriodChange(selectedPeriod: string) {
    let period = this.reportPeriods.find(f => f.key == selectedPeriod);
    this.DateStart = period?.startDate;
    this.DateStop = period?.stopDate;

    this.key.setValue(selectedPeriod);
    this.startDate.setValue(this.DateStart);
    this.stopDate.setValue(this.DateStop);

    this.active = 1;
  }

  handleAggregateChange($event: any, val: boolean) {
    var target = $event.target;
    if (target.checked) {
      this.bShowSelect$.next(val);
      this.active = 1;
    }
  }

  handlePlatformsChange($event: any, val: string) {
    var target = $event.target;
    if (target.checked) {
      this.applyPlatformFilterOnCampaigns(val);
      this.active = 1;
    }
  }

  handleReportFormatChange($event: any, val: string) {
    var target = $event.target;
    if (target.checked && this.active == 3) {
      this.active = 2;
    }
  }


  get isEmptyCompanyGuid() {
    return this.companyId == createEmptyGuid();
  }

  manuallySelectedCampaigns = [] as Array<string>;
  onSelectCheckboxChange(payload: { data: ICampaign, evt: Event }) {
    let target = payload.evt.target as HTMLInputElement
    let campaign = payload.data;

    if (target.checked) {
      this.manuallySelectedCampaigns.push(campaign.id);
    }
    else {
      while (this.manuallySelectedCampaigns.indexOf(campaign.id) >= 0) {
        this.manuallySelectedCampaigns.splice(this.manuallySelectedCampaigns.indexOf(campaign.id), 1);
      }
    }
    // console.log(campaign.id);
    // console.log(target.checked);
    // console.log(this.manuallySelectedCampaigns);
  }

  dataLoadCompleted = false;
  rollupReportDataResponse: IRollupReportDataResponse | null = null;
  SelectCampaignsError = null as string | null;
  onFormSubmit() {
    this.SelectCampaignsError = null;
    // console.log(this.reportForm.value);

    this.reportformsubmitted = true;
    if (this.reportForm.invalid)
      return;

    if (this.reportForm.touched == true) {
      this.DateStart = this.startDate.value;
      this.DateStop = this.stopDate.value;
    }

    // console.log('this.aggregation.value: ', this.aggregation.value);
    if (this.aggregation.value == 'SelectCampaigns' && this.manuallySelectedCampaigns.length == 0) {
      this.SelectCampaignsError = "** Please select campaigns."
      return;
    }

    var campaigns = [] as Array<string>;
    if (this.aggregation.value == 'SelectCampaigns') {
      campaigns = this.manuallySelectedCampaigns;
    }
    else {
      campaigns = [createEmptyGuid()];
    }
    // console.log('campaigns: ', campaigns);

    let params = {
      companyId: this.companyId,
      reportFormat: this.reportFormat.value,
      campaigns: campaigns,
      aggregation: this.aggregation.value,
      platforms: this.formPlatforms.value,
      dateStart: this.startDate.value,
      dateStop: this.stopDate.value,
    };


    var paramsUrl = "" + this.router.createUrlTree([''], { queryParams: params });
    paramsUrl = paramsUrl.replace('/', '');
    // console.log(paramsUrl);

    this.rollupReportDataResponse = null;
    this.reportsService.startRollupReportProcess(paramsUrl)
      .subscribe(res => {
        this.rollupReportDataResponse = res.data;
        if (this.rollupReportDataResponse?.orderId) {
          this.dataLoadCompleted = false;
          this.active = 2;
        }
      });
  }

  GetRollupReportDataResponse(orderId: string) {
    this.reportsService.getRollupReportData(orderId)
      .subscribe(res => {
        if (res?.good && res.data) {
          this.rollupReportDataResponse = res.data;
          this.DataLoadCompleted(true);
        }
        else{
          this.location.go(`/pages/reports/rollup-campaigns/${this.companyId}`);
        }
      });
  }

  active: number = 1;
  onTabChange(event: number) {
    // console.log(event); 
    // Do Nothing on tab change 
  }

  DataLoadCompleted(complete: boolean) {
    this.dataLoadCompleted = complete;
    this.active = 3;

    this.location.go(`/pages/reports/rollup-report/viewer/${this.companyId}/${this.rollupReportDataResponse?.orderId}`);

    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree(['/reports/view-rollup-report', this.rollupReportDataResponse?.orderId ?? createEmptyGuid() ])
    // );
    // window.open(url, '_blank');
  }

  openHtmlReportNewWindow(){
    if (this.rollupReportDataResponse?.orderId) {
    this.reportsService.getRollupReport(this.rollupReportDataResponse.orderId, 'html')
      .subscribe(responseUrl => {
        window.open(responseUrl);
      });
    }
  }

  openPdfReportNewWindow(){
    if (this.rollupReportDataResponse?.orderId) {
    this.reportsService.getRollupReport(this.rollupReportDataResponse.orderId, 'pdf')
      .subscribe(responseUrl => {
        window.open(responseUrl);
      });
    }
  }

  get htmlReportUrl() {
    let params = {
      orderId: this.rollupReportDataResponse?.orderId,
      reportFormat: 'html',
    };

    var uri = environment.baseUrl + this.router.createUrlTree(['/Dashboard/completed-rollup-report'], { queryParams: params })
    return uri;
  }

  get pdfReportUrl() {
    let params = {
      orderId: this.rollupReportDataResponse?.orderId,
      reportFormat: 'pdf',
    };

    var uri = environment.baseUrl + this.router.createUrlTree(['/Dashboard/completed-rollup-report'], { queryParams: params })
    return uri;
  }

}