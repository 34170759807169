import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IClient } from '../../../core/models/clients.models';
import { OrdersApiService } from '../../../pages/orders/orders.apiservice';
import { ProductCheckoutComponent } from '../../../pages/shop/product-checkout/product-checkout.component';
@Component({
  selector: 'modal-process-order-payment',
  templateUrl: './process-order-payment.component.html',
  styleUrls: ['./process-order-payment.component.scss']
})
export class ProcessOrderPaymentModal implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild(ProductCheckoutComponent) public checkoutComponent!: ProductCheckoutComponent;
  @Output() oSaveClicked = new EventEmitter<any>();

  @Input() iOrderId: string = "";
  @Input() iAllMyClients: Array<IClient> = [];

  constructor(
    public activeModal: NgbActiveModal,
    public orderApiService: OrdersApiService,
  ) {
  }


  ngOnInit() {
  }
  ngAfterViewInit() {
  }
  onSubmit() {
  }

  oSaveClick() {
    if (this.checkoutComponent.doSubmitPaymentForm()) {
      let values = this.checkoutComponent.getPaymentFormValues();
      values.orderId = this.iOrderId;
      this.oSaveClicked.emit(values);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
