import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGE_NAMES, ROLES_TYPE } from '../../../core/helpers/constants';
import { getRolesForPage } from '../../../core/helpers/utility';
import { IPermission } from '../../../core/models/auth.models';
import { ICompany } from '../../../core/models/datafeed.models';
import { DataFeedService } from '../../../shared/api/datafeed.service';
import { AppService } from '../../../store/app.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-reports-cmo-report',
  templateUrl: './reports-cmo-report.component.html',
  styleUrls: ['./reports-cmo-report.component.scss']
})
export class ReportsCmoDashboardComponent implements OnInit, OnDestroy {
  doWhat: IPermission = {} as IPermission;
  companiesData: Array<ICompany> = [];
  selectedCompanyId: string = '';
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isAdmin: boolean = false;
  roleOptions: any = {};
  isExternal: boolean = false;
  iframeUrl: SafeResourceUrl = '';

  constructor(
    private router: Router,
    private appService: AppService, 
    private sanitizer: DomSanitizer,
    private dataFeedService: DataFeedService
  ) {}

  ngOnInit(): void {
    this.appService.setPageTitle$('Brand Dashboard');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Reports);

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isExternal = u.type === ROLES_TYPE.External;
        const r = getRolesForPage(u.roles, PAGE_NAMES.Reports);
        this.isAdmin = r.isAdmin;
        this.roleOptions = r.options ? JSON.parse(r.options ?? '{}') : {};
      });

    this.getAssociatedCompanies(); 
  }

  getAssociatedCompanies() {
    this.dataFeedService.getAssociatedCompanies()
      .subscribe(res => {
        // Sort companies alphabetically by name
        this.companiesData = [...res.data].sort((a, b) => a.name.localeCompare(b.name));
  
        // If only one company, set iframe URL directly
        if (this.companiesData.length === 1) {
          if (this.companiesData[0].id) {
            this.updateIframeUrl(this.companiesData[0].id);
          }
        }
      });
  }

  updateIframeUrl(companyId: string) {
    this.selectedCompanyId = companyId;
    const company = this.companiesData.find(c => c.id === companyId);
    if (company) {
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://dms.reach.marketing/dmm/cmo.php?company=${company.name}`);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}