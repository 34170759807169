<div class="row">
    <div class="col-md-12">
        <div class="card card-custom">
            <div class="card-body">
                <form [formGroup]="editCampaignForm" (ngSubmit)="onSubmit()">
                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="campaignId">Campaign ID</label>
                        <div class="col-sm-4">
                            <input readonly type="text" class="form-control" formControlName="campaignId">
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="name">Campaign Name</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="name"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                placeholder="Campaign Name">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.name.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.name.errors.required">
                                Name is Required
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row mb-3">
                        <label class="col-sm-4" for="locationAddress">Address</label>
                        <div class="col-sm-4">
                            <textarea rows="3" class="form-control" formControlName="locationAddress"
                                [ngClass]="{ 'is-invalid': submitted && f.locationAddress.errors }"
                                placeholder="Location's Address"></textarea>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.locationAddress.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.locationAddress.errors.required">Address is Required </div>
                            <div *ngIf="f.locationAddress.errors.max">Cannot be greater than 200 characters</div>
                        </div>
                    </div> -->
                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="street">Location Street</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="street"
                                [ngClass]="{ 'is-invalid': submitted && f.street.errors }"
                                placeholder="Street Address">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.street.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.street.errors.required">Street address is Required </div>
                            <div *ngIf="f.street.errors.max">Street cannot be greater than 200 characters</div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="city">Location City</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="city"
                                [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                                placeholder="city">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.city.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.city.errors.max">City cannot be greater than 200 characters</div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="state">Location State</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="state"
                                [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
                                placeholder="State">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.state.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.state.errors.max">State cannot be greater than 200 characters</div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="zipcode">Location Zipcode</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="zipcode"
                                [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }"
                                placeholder="Zipcode">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.zipcode.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.zipcode.errors.max">Zipcode cannot be greater than 10 characters</div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="locationPhone">Phone</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="locationPhone"
                                [ngClass]="{ 'is-invalid': submitted && f.locationPhone.errors }"
                                placeholder="Location's Phone Number">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.locationPhone.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.locationPhone.errors.required">Phone Number is Required</div>
                            <div *ngIf="f.locationPhone.errors.max">Phone cannot be greater than 20 characters</div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="locationEmail">Email</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="locationEmail"
                                [ngClass]="{ 'is-invalid': submitted && f.locationEmail.errors }"
                                placeholder="Location's Email Address">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.locationEmail.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.locationEmail.errors.required">Email is Required</div>
                            <div *ngIf="f.locationEmail.errors.max">Email cannot be greater than 50 characters</div>
                            <div *ngIf="f.locationEmail.errors.email">Invalid Email Address</div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="platform">Platform</label>
                        <div class="col-sm-4">
                            <ng-select [ngClass]="{ 'is-invalid': submitted && f.platform.errors }"
                                formControlName="platform">
                                <ng-option *ngFor="let p of allPlatforms" [value]="p">{{p}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.platform.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.platform.errors.required">Platform is Required</div>
                        </div>
                    </div>
                    <!-- 
                    <div class="row mb-3">
                        <label class="col-sm-3 center-label" for="subscriptionId">Subscription ID</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="subscriptionId"
                                [ngClass]="{ 'is-invalid': submitted && f.subscriptionId.errors }"
                                placeholder="Subscription ID">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.subscriptionId.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.subscriptionId.errors.required">Subscription ID is Required</div>
                        </div>
                    </div>
                    -->

                    <!-- https://atlastechdev.atlassian.net/browse/REAC-59 -->
                    <!-- Subscription Active - just make this true/false based on whether this campaign has subscriptions
                    Have made this row readonly and values pre populated according to above condition  -->
                    <div class="row mb-3" [hidden]="f.id?.value === null">
                        <label class="col-sm-4 center-label" for="subActive">Active Subscription?</label>
                        <div class="col-sm-3">
                            {{ f.subscriptionActive.value | P_Boolean }}
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="stageId">Stage</label>
                        <div class="col-sm-3">
                            <ng-select [ngClass]="{ 'is-invalid': submitted && f.stageId.errors }"
                                formControlName="stageId">
                                <ng-option *ngFor="let stage of CAMPAIGN_STAGES" [value]="stage.value">{{stage.title}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.stageId.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.stageId.errors.required">Stage is Required</div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="crmName">CRM Name</label>
                        <div class="col-sm-4">
                            <ng-select *ngIf="!isCustomCrmName" formControlName="crmName">
                                <ng-option *ngFor="let c of defaultCRMNames" [value]="c.value">{{c.title}}
                                </ng-option>
                            </ng-select>
                            <input *ngIf="isCustomCrmName" type="text" class="form-control" formControlName="crmName"
                                [ngClass]="{ 'is-invalid': submitted && f.crmName.errors }"
                                placeholder="CRM Name">
                        </div>
                        <div *ngIf="isCustomCrmName" class="col-md-1">
                            <i-feather name="corner-right-up" (click)="onShowDropdown()" title="Show Dropdown"
                                class="icon-sm icon"></i-feather>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.crmName.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.crmName.errors.required">CRM Name is Required</div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="crmId">CRM ID</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="crmId"
                                [ngClass]="{ 'is-invalid': submitted && f.crmId.errors }" placeholder="CRM ID">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.crmId.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.crmId.errors.required">CRM ID is Required</div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-4 center-label" for="clientId">Client</label>
                        <div class="col-sm-3">
                            <ng-select [ngClass]="{ 'is-invalid': submitted && f.clientId.errors }"
                                (change)="updateProspectClientDetails()" formControlName="clientId">
                                <ng-option *ngFor="let c of allClients" [value]="c.id">{{c.firstName}} {{c.lastName}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.clientId.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.clientId.errors.required">Client is Required</div>
                        </div>
                    </div>

                    <div class="row mb-3" [ngClass]="{'disabled' : options !==null && !options.canAssign}">
                        <label class="col-sm-4 center-label" for="dmsId">DMS</label>
                        <div class="col-sm-3">
                            <ng-select [ngClass]="{ 'is-invalid': submitted && f.dmsId.errors }"
                                formControlName="dmsId">
                                <ng-option *ngFor="let rep of allDms" [value]="rep.id">{{rep.firstName}}
                                    {{rep.lastName}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.dmsId.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.dmsId.errors.required">DMS is Required</div>
                        </div>
                    </div>
                    <!--
                    <div class="row mb-3">
                        <label class="col-sm-3" for="employeeIds">Employees</label>
                        <div class="col-sm-9">
                            <ul style="max-height: 150px; overflow-y:scroll" class="list-group col-sm-4">
                                <li class="list-group-item" *ngIf="f.employees.value?.length === 0">No employee found
                                </li>
                                <li class="list-group-item" *ngFor="let e of f.employees.value">{{e.fullName }}</li>
                            </ul>
                        </div>
                    </div>
                    -->
                    <div *ngIf="!isExternal || isAdmin" class="row mb-3">
                        <label class="col-sm-4" for="opportunityNotes">Internal Notes</label>
                        <div class="col-sm-4">
                            <textarea rows="3" class="form-control" formControlName="opportunityNotes"
                                [ngClass]="{ 'is-invalid': submitted && f.opportunityNotes.errors }"
                                placeholder="Special setups: location targeting, changes to lead routing, etc."></textarea>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.opportunityNotes.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.opportunityNotes.errors.required">Internal Notes are Required
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-sm-4" for="platformAccountId">Ad Account Info</label>
                        <div class="col-sm-4">
                            <table id="adAcccounts" style="width: 100%;" *ngIf="campaignAdAccounts?.length != 0">
                                <tr>
                                  <td style="width: 35%;"><strong>Ad Account ID</strong></td>
                                  <td style="width: 35%;"><strong>Ad Campaign ID</strong></td>
                                  <td style="width: 30%;"><strong>Ad Label</strong></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr *ngFor="let ad of campaignAdAccounts;">
                                  <td>{{ad.platformAccountID}}</td>
                                  <td>{{ad.platformCampaignID}}</td>
                                  <td>{{ad.adLabel}}</td>
                                  <td>
                                    <span class="input-group-text" style="cursor: pointer;"
                                        title="Click to edit Ad account." (click)="onEditAccountClick(ad.id)">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </span>
                                  </td>
                                  <td>
                                    <span class="input-group-text" style="cursor: pointer;"
                                        title="Click to remove Ad account." (click)="deleteAdAccount(ad)">
                                        <i class="fas fa-times"></i>
                                    </span>
                                  </td>
                                </tr>
                            </table>
                            <button *ngIf="!isAddAcountMode" class="btn btn-sm btn-black" (click)="OnAddAccontClick()">Add Account</button>
                            <div *ngIf="isAddAcountMode" class="row pb-2" style="margin-top: 10px;border: 1px lightgray solid;padding-top: 10px;">
                                <div class="row">
                                    <label class="col-sm-4 center-label">Platform Account ID</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" [(ngModel)]="currentAccountId" [ngModelOptions]="{standalone: true}"
                                            [ngClass]="{ 'is-invalid': isAddAccountSubmitted && currentAccountId.trim().length == 0 }"
                                            placeholder="Platform Account ID">
                                    </div>
                                </div>
                                <div *ngIf="isAddAccountSubmitted && currentAccountId.trim().length == 0" class="row mb-minus-3 row-validation">
                                    <div>Platform Account ID is Required</div>
                                </div>
            
                                <div class="row" style="margin-top: 5px;">
                                    <label class="col-sm-4 center-label">Platform Campaign ID</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" [(ngModel)]="currentCampaignId" [ngModelOptions]="{standalone: true}"
                                            [ngClass]="{ 'is-invalid': isAddAccountSubmitted && currentCampaignId.trim().length == 0 }"
                                            placeholder="Platform Campaign ID">
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 5px;">
                                    <label class="col-sm-4 center-label">Platform Ad Label</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" [(ngModel)]="currentAdLabel" [ngModelOptions]="{standalone: true}"
                                            [ngClass]="{ 'is-invalid': isAddAccountSubmitted && currentAdLabel.trim().length == 0 }"
                                            placeholder="Platform Ad Label">
                                    </div>
                                </div>
                                <div *ngIf="isAddAccountSubmitted && currentCampaignId.trim().length == 0" class="row mb-minus-3 row-validation">
                                    <div>Platform Campaign ID is Required</div>
                                </div>
                                <div class="col-sm-2">
                                    <button *ngIf="!isUpdateAccountMode" [disabled]="currentCampaignId.trim().length == 0 || currentAccountId.trim().length == 0" type="button" class="btn btn-sm btn-dark mt-2" (click)="addAccountDetail()">
                                        Add</button>
                                    <button *ngIf="isUpdateAccountMode" [disabled]="currentCampaignId.trim().length == 0 || currentAccountId.trim().length == 0" type="button" class="btn btn-sm btn-dark mt-2" (click)="updateAccountDetail()">
                                        Update</button>
                                </div>
                                <div class="col-sm-1">
                                    <button type="button" class="btn btn-sm btn-dark mt-2" (click)="CancelAddMode()">
                                        Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div *ngIf="!isExternal || isAdmin" class="row mb-3">
                        <label class="col-sm-4 center-label" for="platformAccountId">Platform Account ID</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="platformAccountId"
                                [ngClass]="{ 'is-invalid': submitted && f.platformAccountId.errors }"
                                placeholder="Platform Account ID">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.platformAccountId.errors" class="row mb-minus-3 row-validation">
                        <div *ngIf="f.platformAccountId.errors.required">Platform Account ID is Required</div>
                    </div>

                    <div *ngIf="!isExternal || isAdmin" class="row mb-3">
                        <label class="col-sm-4 center-label" for="platformCampaignId">Platform Campaign ID</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" formControlName="platformCampaignId"
                                [ngClass]="{ 'is-invalid': submitted && f.platformCampaignId.errors }"
                                placeholder="Platform Campaign ID">
                        </div>
                    </div>
                    <div *ngIf="submitted && f.platformCampaignId.errors" class="row mb-minus-3 row-validation">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9">
                            <div *ngIf="f.platformCampaignId.errors.required">Platform Campaign ID is Required</div>
                        </div>
                    </div> -->

                    <div class="row mb-3">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4" style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; align-items: center;">
                            <a [routerLink]="returnPath" class="btn btn-orange" type="button">Cancel</a>
                            <button class="blue-button" type="submit">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>