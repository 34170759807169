<div class="container-fluid" style="background-color: white; padding: 8px !important;" [ngClass]="{'disable-all':!iCanWrite}">
    <form style="padding: 8px 8px 8px 8px;" [formGroup]="auditForm" (ngSubmit)="onSubmit()">
        <div class="row mb-10 mt-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <h4>3 DAY CHECK</h4>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label for="lastCheck">Last 3 Day Check</label>
            </div>
            <div class="col-sm-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.lastCheck.errors,'disable-calender':!iCanWrite  }"
                    mwlFlatpickr [altInputClass]="'datetime'" [altInput]="true" [convertModelValue]="true"
                    formControlName="lastCheck" class="form-control" />
            </div>
        </div>
        <div class="row mb-10 mt-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <h4>FULL CAMPAIGN AUDIT</h4>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>CPL (last 30 days)</label>
            </div>
            <div class="col-md-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.cpL30d.errors }" type="number" formControlName="cpL30d"
                    class="form-control" />
                <div *ngIf="submitted && f.cpL30d.errors" class="invalid-feedback">
                    <div *ngIf="f.cpL30d.errors.required">required</div>
                </div>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>CPL (last 7 days)</label>
            </div>
            <div class="col-md-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.cpL7d.errors }" type="number" formControlName="cpL7d"
                    class="form-control" />
                <div *ngIf="submitted && f.cpL7d.errors" class="invalid-feedback">
                    <div *ngIf="f.cpL7d.errors.required">required</div>
                </div>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>Last Call with Client</label>
            </div>
            <div class="col-md-4">
                <input
                    [ngClass]="{ 'is-invalid': submitted && f.lastClientCallDate.errors, 'disable-calender':!iCanWrite }"
                    mwlFlatpickr [altInputClass]="'datetime'" [altInput]="true" [convertModelValue]="true"
                    formControlName="lastClientCallDate" class="form-control" />
                <div *ngIf="submitted && f.lastClientCallDate.errors" class="invalid-feedback">
                    <div *ngIf="f.lastClientCallDate.errors.required">required</div>
                </div>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>Last Email Sent</label>
            </div>
            <div class="col-md-4">
                <input
                    [ngClass]="{ 'is-invalid': submitted && f.lastEmailSentDate.errors,'disable-calender':!iCanWrite }"
                    mwlFlatpickr [altInputClass]="'datetime'" [altInput]="true" [convertModelValue]="true"
                    formControlName="lastEmailSentDate" class="form-control" />
                <div *ngIf="submitted && f.lastEmailSentDate.errors" class="invalid-feedback">
                    <div *ngIf="f.lastEmailSentDate.errors.required">required</div>
                </div>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>Are the campaign and ads live and delivering?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="adsLive" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>Is the campaign producing leads?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="producingLeads" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>Leads email checked to confirm lead delivery?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="leadsEmailChecked" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>Do the ads and the sales stage match?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="adsSalesMatch" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>Had the ad set been refreshed recently?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="adsSetRefreshed" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>Is the location targeting correct?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="locationTargetCorrect" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>If Facebook, is the demographic targeting correct?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="fbDemographicCorrect" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>Does the campaign daily budget match the subscription?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="budgetMatchSubscription" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>If Facebook AND non-Xpo, de we have access to Leads Center?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="fbLeadsCenterAccess" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>If Google, are the ads directing to the correct landing page?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="googleAdsCorrectLandingPage" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>If Google, are the correct keywords being used?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="googleKeywordsCorrect" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>If Google, are the negtive keywords / keyword lists being used?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="googleNegtiveKeywords" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>If there is an LP, does the landing page load correctly?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="lpLandingPageCorrect" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>If there is an LP, is the load time excessively long?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="lpPageTimeLong" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>If there is an LP, does it look right on mobile and desktop?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="lpCrossPlatform" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>If there is an LP, is the correct offer being shown?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="lpCorrectOfferShown" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>You have had some form of communication with client?</label>
            </div>
            <div class="col-md-4">
                <select formControlName="communicationWithClient" class="form-control form-select ">
                    <option *ngFor="let o of bitOptions" [ngValue]="o.value">{{o.title}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label>Last Audit</label>
            </div>
            <div class="col-md-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.lastAudit.errors,'disable-calender':!iCanWrite }"
                    mwlFlatpickr [altInputClass]="'datetime'" [altInput]="true" [convertModelValue]="true"
                    formControlName="lastAudit" class="form-control" />
                <div *ngIf="submitted && f.lastAudit.errors" class="invalid-feedback">
                    <div *ngIf="f.lastAudit.errors.required">required</div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="iCanWrite" style="margin-top: 22px;">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <button type="submit" style="float: right;" class="blue-button">SAVE</button>
            </div>
        </div>
    </form>
</div>