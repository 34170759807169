import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../../store/app.service';

@Component({
  selector: 'app-searches-main',
  templateUrl: './searches-main.component.html',
  styleUrls: ['./searches-main.component.scss']
})
export class SearchesMainComponent implements OnInit {
  active = 1;
  mode = 'Create';

  constructor(private appService: AppService,
    private route: ActivatedRoute) {
      this.route.params.subscribe(params => {
        const savedSearchId = params.id;
        this.active = savedSearchId ? 2 : 1;
        this.mode = savedSearchId ? 'Modify' : 'Create';
      });
     }

  ngOnInit(): void {
    this.appService.setPageTitle$('Searches');
  }

  searchSaved() {
    this.active = 1;
  }

}
