import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from 'rxjs/operators';
import { SubscriptionApiService } from '../../../pages/subscriptions/subscription.apiservice';
import { AppService } from '../../../store/app.service';
import { PaymentMethodApiService } from '../../../pages/my-account/payment-method/payment-method.apiservice';
import { UtilityService } from '../../../core/helpers/utility.service';
import { ROLES } from '../../../core/helpers/constants';
import * as _ from 'underscore';

@Component({
  selector: 'modal-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.scss']
})
export class EditSubscriptionModal {

  @Output() oSaveClicked = new EventEmitter<any>();
  public userRoles: Array<number> = [];
  public ROLES = ROLES;

  @Input() set iId(value: string) {
    this.id = value;
    if (value !== null) {
      this.loadDetails();
    }
  }
  subscription: any;
  allPaymentMethods: Array<any> = new Array<any>();
  clientId?: string = '';
  editForm!: FormGroup;
  addProductForm!: FormGroup;
  submitted = false;
  id: string = '';
  prods: Array<any> = new Array<any>();
  filteredProds: Array<any> = new Array<any>();
  assignedProductId: string = '';
  isAddProductMode: boolean = false;
  constructor(
    private appService: AppService,
    private uService: UtilityService,
    private subscriptionApiService: SubscriptionApiService,
    private paymentMethodApiService: PaymentMethodApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder) {
    this.userRoles = [...this.uService.getUserRoles()];
    this.editForm = this.formBuilder.group({
      id: [null],
      products: this.formBuilder.array([]),
      paymentMethodId: [null, Validators.required],
    });
    this.addProductForm = this.formBuilder.group({
      assignedProductId: [null, Validators.required]
    });
    this.subscriptionApiService.getProductsWithVariables()
        .subscribe((response: any) => {
          if (response.good) {
            this.prods = response.data;
          }
        });
  }

  get f() { return this.editForm.controls; }

  public get productsForm(): FormArray {
    return this.editForm.get('products') as FormArray
  }

  loadDetails() {
    if (this.id) {
      this.subscriptionApiService.getSubscription(this.id)
        .pipe(take(1))
        .subscribe((response: any) => {
          if (response.good) {
            this.subscription = response.subscription;
            this.editForm.patchValue(response.subscription);
            this.clientId = response.subscription.campaigns[0].clientId;
            this.loadPaymentMethodsByClientId();
            for (let i = 0; i < response.subscription.products.length; i++) {
              const product = response.subscription.products[i];
              this.productsForm.push(this.formBuilder.group({ productId: [product.id, Validators.required], adSpendAmount: [product.adSpend] }));
            }
          }
        });
    }
  }

  loadPaymentMethodsByClientId() {
    this.paymentMethodApiService.getPaymentMethodsByClient(this.clientId)
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response.good) {
          this.allPaymentMethods = response.paymentMethods.map((x: any) => {
            return { ...x, name: `${x.nickname} - ${x.cardNumber}` }
          });
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    if (this.editForm.invalid)
      return;
    const request = {
      id: this.editForm.getRawValue().id,
      adSpend: this.editForm.getRawValue().products.map((x: any) => { return { productId: x.productId, adSpend: x.adSpendAmount } }),
      paymentMethodId: this.editForm.getRawValue().paymentMethodId
    };
    //request.adSpend = request.adSpend.filter((x: any) => x.adSpend !== null);
    this.subscriptionApiService.edit(request).subscribe((response: any) => {
      if (response.good) {
        this.activeModal.close();
        this.oSaveClicked.emit();
      }
    });
  }

  deleteProduct(id: string) {
    const index = this.productsForm.value.findIndex((product: { productId: string; }) => product.productId == id);
    this.productsForm.removeAt(index);
    const indexProd = this.subscription.products.findIndex((x: { id: string; }) => x.id == id);
    this.subscription.products.splice(indexProd,1);
  }

  addProduct(){
    var selectedProdIds = _.flatten(this.subscription.products.map((x: { id: any; }) => x.id));
    this.filteredProds = this.prods.filter(x => !selectedProdIds.includes(x.id));
    this.isAddProductMode = true;
  }

  addProductToSub(){
    var selectedProductId = this.addProductForm.get('assignedProductId')?.value;
    var selectedProduct = this.prods.filter(x => x.id == selectedProductId)[0];
    this.subscription.products.push(selectedProduct);
    this.productsForm.push(this.formBuilder.group({ productId: [selectedProduct.id, Validators.required], adSpendAmount: [selectedProduct.adSpend] }));
    this.isAddProductMode = false;
  } 

  CancelAddMode(){
    this.isAddProductMode = false;
  }

  isProductNotSelected(){
    var selectedProductId = this.addProductForm.get('assignedProductId')?.value;
    if(selectedProductId == null){
      return true;
    }
    else{
      return false;
    }
  }

}
