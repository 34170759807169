import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NOTES_TYPE, TAGS_TYPE } from '../../../core/helpers/constants';
import { INotes } from '../../../core/models/campaigns.model';
import { DataFeedService } from '../../api/datafeed.service';
import { AddNoteModal } from '../../modals/add-note/add-note.component';
import { AppService } from '../../../store/app.service';
import { CampaignsApiService } from '../../../pages/campaigns/campaigns.apiservice';
import { ITag } from '../../../core/models/tags.models';
import { AssignTagModal } from '../../modals/assign-tag/assign-tag.component';
import { TagsApiService } from '../../../pages/tags/tags.apiservice';
@Component({
  selector: 'app-tags-tab',
  templateUrl: './tags-tab.component.html',
  styleUrls: ['./tags-tab.component.scss']
})
export class TagsTabComponent implements OnInit {
  //new
  pageNumber = 1;
  pageSize = 10;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  tagsData: Array<ITag>;
  tempTagsData: Array<ITag>;
  @Input() iParentId: string = undefined!;
  @Input() iType: number = undefined!;
  @Input() iCanWrite: boolean = false;
  @Input() iCanDelete: boolean = false;

  constructor(
    private appService: AppService,
    private router: Router,
    private tagService: TagsApiService,
    private modalService: NgbModal,
    private campaignService: CampaignsApiService,
    private dataFeedService: DataFeedService
  ) {
    this.tagsData = new Array<ITag>();
    this.tempTagsData = new Array<ITag>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.iParentId || changes.iType) && (this.iParentId && this.iType)) {
      this.appService.fetchAssociatedTags(this.iParentId, this.iType);
    }
  }

  ngOnInit(): void {
    this.appService.getAllTags$()
      .pipe(
        takeUntil(this.destroyed$),
        //filter((d: any) => !!d && d.type === this.iType)
      )
      .subscribe((response: Array<ITag>) => {
        this.tagsData = response;
        this.tempTagsData = this.tagsData.slice(0, this.pageSize);
      });
  }

  onOpenTag(
    id?: string) {
    const dialogRef = this.modalService.open(AssignTagModal);
    if (this.iType == TAGS_TYPE.Campaigns)
      dialogRef.componentInstance.iCampaignId = this.iParentId;
    else if (this.iType == TAGS_TYPE.Clients)
      dialogRef.componentInstance.iClientId = this.iParentId;
    else if (this.iType == TAGS_TYPE.Products)
      dialogRef.componentInstance.iProductId = this.iParentId;
    dialogRef.componentInstance.iType = this.iType
    dialogRef.componentInstance.iAssignedTags = this.tagsData.map(x => x.tagId)

    const dialogSubmitSubscription = dialogRef.componentInstance.oSaveClicked.subscribe((r: any) => {

      this.tagService.saveAssociatedTags(r)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          dialogRef.dismiss();
          this.appService.fetchAssociatedTags(this.iParentId, this.iType);
        })
      dialogSubmitSubscription.unsubscribe();
    })
  }
  onDeleteTag(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        this.tagService.deleteAssociatedTag(id)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            this.appService.fetchAssociatedTags(this.iParentId, this.iType);
          })
      }
    })


  }


  onClientRowClick(evt: any) {
    this.router.navigate(['/pages/client-details', evt.id]);
  }


  ngAfterViewInit() {

  }

  //new
  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.tempTagsData = this.tagsData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    console.log(this.pageNumber);
  }
}