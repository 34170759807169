import { Injectable } from '@angular/core';
import { IUser } from '../../core/models/auth.models';
import { IClient } from '../../core/models/clients.models';
import { HttpService } from '../../shared/http/base.http';
@Injectable()
export class UsersApiService extends HttpService {
   getAllUsers(savedSearchId: any) {
      const query = savedSearchId ? `?savedSearchId=${savedSearchId}` : '';
      return this.get<Array<IUser>>('/users/getAllUsers' + query);
   }
   getUserDetails(id: any) {
      return this.get<IUser>('/users/getUserDetails?id=' + id)
   }
   saveUser(data: IUser) {
      return this.post('/users/saveUser', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   deleteUser(data: Array<string>) {
      return this.post('/users/deleteUser', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   archiveUser(data: Array<string>) {
      return this.post('/users/archiveUser', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   unarchiveUser(data: Array<string>) {
      return this.post('/users/unarchiveUser', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
}
