import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debug } from 'console';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { IPermission, IUser } from '../../../core/models/auth.models';
import { AppService } from '../../../store/app.service';
import { UsersApiService } from '../users.apiservice';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-users-main',
  templateUrl: './users-main.component.html',
  styleUrls: ['./users-main.component.scss'],
})
export class UsersMainComponent implements OnInit, OnDestroy {
  doWhat: IPermission = {} as IPermission;
  columns = [
    { title: 'First Name', field: 'firstName', sortable: true, searchable: true },
    { title: 'Last Name', field: 'lastName', sortable: true, searchable: true },
    { title: 'Phone', field: 'phone', searchable: true },
    { title: 'Email', field: 'email', searchable: true },
    { title: 'Roles', field: 'code', pipe: 'P_RolesName_Filter', searchable: true },
  ];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  usersData: Array<IUser>;
  activeUser: IUser = {} as IUser;
  savedSearchId: any;

  constructor(
    private userApiService: UsersApiService,
    private route: ActivatedRoute,
    private appService: AppService,
    private userService: UsersService,
  ) {
    this.usersData = new Array<IUser>();
    this.savedSearchId = null;
  }

  ngOnInit(): void {

    this.appService.setPageTitle$('Users');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Users);
    console.log(this.doWhat);
    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: IUser) => {
        this.activeUser = response;
        console.log(this.activeUser);
        if (this.activeUser.roles[0].code == 4) {
          this.doWhat.canDelete = false;
        }
      });
    this.appService.getAllUsers$()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: Array<IUser>) => {
        this.usersData = [...response];
        console.log([...response]);
      });
    this.savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
    this.appService.fetchAllUsers(this.savedSearchId);

  }

  editRow(evt: any) {
    this.userService.openEditUserModal(evt)
  }
  deleteRows(evt: any) {
    this.userApiService.deleteUser(evt)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.appService.fetchAllUsers(this.savedSearchId);
      })

  }
  archiveRows(evt: any) {
    this.userApiService.archiveUser(evt)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.appService.fetchAllUsers(this.savedSearchId);
      })

  }
  unarchiveRows(evt: any) {
    this.userApiService.unarchiveUser(evt)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.appService.fetchAllUsers(this.savedSearchId);
      })

  }
  onClientRowClick(evt: any) {
    if(evt.canBeEdit == null || evt.canBeEdit){
      this.userService.openEditUserModal(evt);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
