<div class="la-ball-spin-clockwise la-dark la-3x" *ngIf="!loadCompleted">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>

<!-- <iframe [srcdoc] = "reportData"></iframe> -->

<!-- <div id="divRollupReportContent" *ngIf="loadCompleted" [innerHtml]="reportData"></div> -->
<iframe #iframe style="height: 99vh;" width="100%" (load)="onFrameLoadComplete()"></iframe>  
 