import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { IUser } from '../../core/models/auth.models';
import { IClient } from '../../core/models/clients.models';
import { AddClientModal } from '../../shared/modals/add-client/add-client.component';
import { AddUserModal } from '../../shared/modals/add-user/add-user.component';
import { AppService } from '../../store/app.service';
import { UsersApiService } from './users.apiservice';

@Injectable()
export class UsersService {

    constructor(
        private modalService: NgbModal,
        private appService: AppService,
        private userService: UsersApiService,
    ) {

    }

    openEditUserModal(evt: any) {
        const dialogRef = this.modalService.open(AddUserModal, { size: 'lg' });
        // if (evt.id === -1) {
        //     var d = this.appService.getActiveRoute$().value.url.split('/');
        //     evt.id = d[d.length - 1]
        // }
        dialogRef.componentInstance.iId = evt.id;
        const dialogSubmitSubscription = dialogRef.componentInstance.oSaveClicked.subscribe((r: IUser) => {

            this.userService.saveUser(r)
                .subscribe((response: any) => {
                    dialogRef.dismiss();
                    this.appService.fetchAllUsers(null);
                })
            dialogSubmitSubscription.unsubscribe();
        })
    }

}
