import { Injectable } from '@angular/core';
import { ICart, IProductMain } from '../../core/models/products.models';
import { HttpService } from '../../shared/http/base.http';
@Injectable()
export class ShopApiService extends HttpService {


   updateCartStep(id: string, stepCode: number) {
      let chunk = { id, stepCode };
      return this.post('/shop/updateCartStep', chunk, { 'Content-Type': 'application/json; charset=utf-8' });
   }

   saveCart(data: ICart) {
      return this.post('/shop/saveCart', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   getCartDetails(id: string) {
      return this.get<ICart>('/shop/getCartDetails?id=' + id);
   }
   getAllProducts() {
      return this.get<Array<IProductMain>>('/products/getAllByCompany');
   }
   getProductDetails(id: string) {
      return this.get<IProductMain>('/products/getProductDetailsShop?id=' + id);
   }
   getCouponDetails(cartId: string, code: string) {
      return this.get<any>(`/shop/getCouponDetails?cartId=${cartId}&code=${code}`);
   }

   getCartProductPrices(request: any){
      return this.post('/shop/getCartProductPrices', request, { 'Content-Type': 'application/json; charset=utf-8' });
   }
}
