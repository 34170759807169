<div class="card flexbox-wrapper"> 

    <div *ngIf="Error" class="card-header p-0 text-danger">
        <div class="btn-toolbar p-3" role="toolbar" [innerHTML]="Error">
        </div>
    </div>

    <!-- <div class="card-header p-0 text-danger">
        <div class="btn-toolbar p-3" role="toolbar" >
            ** please note you can still change report formart even after the report has procesed.
        </div>
    </div> -->

    <div class="card-header p-0 ">
        <div class="btn-toolbar p-3" role="toolbar">
            Processing Rollup Report Data.
        </div>

        <div class="btn-toolbar p-3" role="toolbar">
            <div class="progress w-100" style="height: 1.5rem !important;">
                <div *ngIf="combinedStatus <= 99" role="progressbar" aria-valuemin="0"
                    class="bg-info progress-bar progress-bar-striped progress-bar-animated"
                    [attr.aria-valuenow]="combinedStatus" aria-valuemax="100" style="width: {{ combinedStatus }}%;">
                    {{MathFloor(combinedStatus)}} %
                </div>

                <div *ngIf="combinedStatus >= 99 && combinedStatus < 100" role="progressbar" aria-valuemin="0"
                    class="bg-success progress-bar progress-bar-striped progress-bar-animated"
                    [attr.aria-valuenow]="combinedStatus" aria-valuemax="100" style="width: {{ combinedStatus }}%;">
                    {{MathFloor(combinedStatus)}} %
                </div>

                <div *ngIf="combinedStatus >= 100" role="progressbar" aria-valuemin="0" class="bg-success progress-bar"
                    [attr.aria-valuenow]="combinedStatus" aria-valuemax="100" style="width: {{ combinedStatus }}%;">
                    {{MathFloor(combinedStatus)}} %
                </div>
            </div>
        </div>
    </div>

    <div class="card-body m-1">
        <ul class="message-list" *ngIf="iRollupReportDataResponse">
            <li class="unread ps-1 pe-1"
                *ngFor="let campaign of ( iRollupReportDataResponse?.campaigns ?? [] ); let i = index;"
                style="background-color: #f6f6f6; font-weight: 500; color: #292d32;">
                <div class="col-mail col-mail-1">
                    <span class="text-black">{{i+1}}</span>
                    <span class="title">{{ campaign.platform }}</span>
                </div>
                <div class="col-mail col-mail-2">
                    <span class="subject" style="right:300px !important;">{{ campaign.name }}</span>
                    <span class="date mt-3" style="width:290px !important; padding-left: 5px !important;">

                        <div class="progress"
                            *ngIf="campaign.bundle == 'Queued' || campaign.bundle == 'Queued' || !campaign.bundle"
                            style="height: 0.725rem !important;">
                            <div role="progressbar" aria-valuemin="0" class="bg-dark progress-bar progress-bar-striped"
                                aria-valuenow="1" aria-valuemax="100" style="width: 1%;">
                            </div>
                        </div>

                        <div class="progress" *ngIf="campaign.bundle == 'Started'" style="height: 0.725rem !important;">
                            <div role="progressbar" aria-valuemin="0"
                                class="bg-info progress-bar progress-bar-striped progress-bar-animated"
                                aria-valuenow="75" aria-valuemax="100" style="width: 75%;">
                            </div>
                        </div>

                        <div class="progress" *ngIf="campaign.bundle == 'Completed'"
                            style="height: 0.725rem !important;">
                            <div role="progressbar" aria-valuemin="0" class="bg-success progress-bar"
                                aria-valuenow="100" aria-valuemax="100" style="width: 100%;">
                            </div>
                        </div>

                        <div *ngIf="campaign.bundle == 'Faulted'" class="progress"
                            style="height: 0.725rem !important; cursor: pointer;" >
                            <div role="progressbar" aria-valuemin="0"
                                class="bg-danger progress-bar progress-bar-striped" aria-valuenow="80"
                                aria-valuemax="100" style="width: 80%;"> 
                            </div>
                        </div>

                    </span>
                </div>
            </li>
        </ul>
    </div>
</div>