import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createGuid } from '../../../core/helpers/utility';
import { ICampaignAudit, INotes } from '../../../core/models/campaigns.model';
import { CampaignsApiService } from '../campaigns.apiservice';
@Component({
  selector: 'app-campaign-audit',
  templateUrl: './campaign-audit.component.html',
  styleUrls: ['./campaign-audit.component.scss']
})
export class CampaignAuditComponent implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  bitOptions = [
    {
      title: 'Yes',
      value: true
    },
    {
      title: 'No',
      value: false
    },
    {
      title: 'N/A',
      value: null
    }
  ]
  submitted = false;
  auditData: Array<ICampaignAudit>;
  auditForm: FormGroup;
  campaignId: string = '';
  @Input() iCanWrite = false;
  @Input() set iCampaignId(id: string) {
    this.campaignId = id;
    this.campaignService.getAudit(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.auditForm.patchValue(response.data);
        this.auditForm.patchValue({ campaignId: id });
      });
  }



  constructor(
    private router: Router,
    private campaignService: CampaignsApiService,
    private formBuilder: FormBuilder
  ) {
    this.auditData = new Array<ICampaignAudit>();
    this.auditForm = this.formBuilder.group({
      id: [null],
      lastCheck: [null],
      campaignId: [null],
      adsLive: [false],
      producingLeads: [false],
      leadsEmailChecked: [false],
      adsSalesMatch: [false],
      adsSetRefreshed: [false],
      locationTargetCorrect: [false],
      budgetMatchSubscription: [false],
      fbDemographicCorrect: [false],
      fbLeadsCenterAccess: [false],
      googleAdsCorrectLandingPage: [false],
      googleKeywordsCorrect: [false],
      googleNegtiveKeywords: [false],
      lpLandingPageCorrect: [false],
      lpPageTimeLong: [false],
      lpCrossPlatform: [false],
      lpCorrectOfferShown: [false],
      communicationWithClient: [false],
      cpL30d: ['', Validators.required],
      cpL7d: ['', Validators.required],
      lastClientCallDate: [null, Validators.required],
      lastEmailSentDate: [null, Validators.required],
      lastAudit: [null],
    });
  }
  get f() { return this.auditForm.controls; }
  ngOnInit(): void { }


  onSubmit() {
    this.submitted = true;
    if (this.auditForm.invalid)
      return;
    if (this.f.id.value === null)
      this.f.id.setValue(createGuid())
    let formValues: ICampaignAudit = this.auditForm.value;
    this.campaignService.saveAudit(formValues)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
      })
  }

}
