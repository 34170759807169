import { Injectable } from '@angular/core';
import { IRole, IRoleCreation, IUser } from '../../core/models/auth.models';
import { ICampaign, ICampaignCheck, INotes } from '../../core/models/campaigns.model';
import { IClient } from '../../core/models/clients.models';
import { ICompany, ISalesRep, ISubscription } from '../../core/models/datafeed.models';
import { HttpService } from '../../shared/http/base.http';
@Injectable()
export class DataFeedService extends HttpService {

   getRoleRestrictions() {
      return this.get<Array<IRoleCreation>>('/datafeed/getRoleRestrictions');
   }
   getAllCompanies() {
      return this.get<Array<ICompany>>('/datafeed/getAllCompanies');
   }
   getAllClients() {
      return this.get<Array<IClient>>('/datafeed/getAllClients');
   }
   getAllProducts() {
      return this.get<Array<IClient>>('/datafeed/getAllProducts');
   }
   getAllSubscriptions() {
      return this.get<Array<ISubscription>>('/datafeed/getAllSubscriptions');
   }
   getAllEmployees(clientId: string) {
      return this.get<Array<IUser>>(`/datafeed/getAllEmployees?cId=${clientId}`);
   }
   getAllClientCampaigns(ids: any) {
      return this.post('/datafeed/getAllClientCampaigns', ids, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   getClientsFeedByIds(ids: any) {
      return this.post('/datafeed/getClientsFeedByIds', ids, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   getAllTaskAssignees() {
      return this.get<Array<IUser>>('/datafeed/getAllTaskAssignees');
   }

   getAllNotesAssignees() {
      return this.get<Array<IUser>>('/datafeed/getAllNotesAssignees');
   }

   getNotes(id: string, type: number) {
      return this.get<Array<INotes>>(`/datafeed/getNotes?id=${id}&type=${type}`)
   }
   saveNote(data: INotes) {
      return this.post('/datafeed/saveNote', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   saveCampaignCheck(data: ICampaignCheck) {
      return this.post('/datafeed/saveCampaignCheck', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   deleteNote(id: string) {
      return this.delete(`/datafeed/deleteNote/${id}`);
   }
   getNoteFileUrl(id: any, filename: any) {
      return this.get(`/datafeed/getNoteFileUrl?id=${id}&filename=${filename}`);
   }


   getAllRoles() {
      return this.get<Array<IRole>>('/datafeed/getAllRoles');
   }
   getAllUsersWithRole(code: number) {
      return this.get<Array<IRole>>(`/datafeed/getAllUsersWithRole?code=${code}`);
   }
   getUserContactInfo(id: string) {
      return this.get<any>(`/datafeed/getUserContactInfo?id=${id}`)
   }

   getAllProductFamilies() {
      return this.get<any>(`/datafeed/getAllProductFamilies`)
   }
   getPaymentMethodsByUserId() {
      return this.get<any>(`/datafeed/getPaymentMethodsByUserId`)
   }
   getPaymentMethodsByClientId(id: string) {
      return this.get<any>(`/datafeed/getPaymentMethodsByClientId?id=${id}`)
   }
   getCompanyData(companyId: string) {
      return this.get<any>(`/datafeed/GetCompany/${companyId}`);
   }

   getAssociatedCompanies() {
      return this.get<any>(`/datafeed/getAssociatedCompanies`);
   }

   getCompanyAssociatedCampaigns(companyId: string) {
      return this.get<any>(`/datafeed/getCompanyAssociatedCampaigns/${companyId}`);
   }
}
