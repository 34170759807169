import { Injectable } from '@angular/core';
import { ILead } from '../../core/models/lead.models';
import { HttpService } from '../../shared/http/base.http';

@Injectable()
export class LeadsApiService extends HttpService {

   getAllLeads(request: any) {
      return this.post<Array<ILead>>('/leads/all', request);
   }

   getLead(leadId: string) {
      return this.get('/leads?leadId=' + leadId);
   }

   addEdit(data: ILead) {
      return this.post('/leads', data);
   }

   deleteLead(leadIds: Array<string>) {
      return this.post('/leads/delete', leadIds);
   }

   exportLeads(request: any) {
      return this.postDownload('/leads/export', request);
   }
}
