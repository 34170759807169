<div class="modal-box" cdkDrag>
    <form [formGroup]="addForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" style="cursor: move; background-color: #EBE9EB; justify-content: space-between;" cdkDragHandle>
            <h4 class="modal-title">{{ id ? 'Edit' : 'Add a New' }} Payment Method</h4>
            <button type="button" style="background-color: transparent !important;" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div fxLayout="column" style="min-width: 450px;">
                    <div fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                        <label class="col-sm-4" for="name">Payment Type</label>
                        <div class="col-sm-8">
                        <div class="radio-inline">
                            <label class="radio-label">
                            <input type="radio" value="Card" formControlName="paymentType" (click)="setFormValidation('Card')"
                                [attr.disabled]="id != '' ? true : null"
                                [ngClass]="{ 'is-invalid': submitted && f.paymentType.errors}">
                            <span> Card</span>
                            </label>
                            &nbsp; &nbsp;
                            <label class="radio-label">
                            <input type="radio" value="ACH" formControlName="paymentType" (click)="setFormValidation('ACH')"
                                [attr.disabled]="id != '' ? true : null"
                                [ngClass]="{ 'is-invalid': submitted && f.paymentType.errors }">
                            <span> ACH (Bank Account)</span>
                            </label>
                        </div>
                        <div *ngIf="submitted && f.paymentType?.errors" style="font-size: 80%; color: #fd625e;">
                            <div *ngIf="f.paymentType?.errors?.required">Payment type is required.</div>
                        </div>
                        </div>
                    </div>
                    <div [hidden]="f.paymentType.value !== 'Card'" fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                        <label for="firstName" style="margin-right: 20px; min-width: 105px;">First Name</label>
                        <input style="margin-right: 10px;" type="text" class="form-control" formControlName="firstName"
                            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="Enter first name">
                        <div *ngIf="submitted && f.firstName.errors" style="margin-right: 20px;" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">first name is required</div>
                        </div>
                        <label for="lastName" style="margin-right: 10px; min-width: 70px;">Last Name</label>
                        <input type="text" class="form-control" formControlName="lastName"
                            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" placeholder="Enter last name">
                        <div *ngIf="submitted && f.lastName.errors" style="margin-left: 20px;" class="invalid-feedback">
                            <div *ngIf="f.lastName.errors.required">last name is required</div>
                        </div>
                    </div>
                    <div fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                        <label for="clientId" style="margin-right: 20px; min-width: 105px;">Client</label>
                        <ng-select [readonly]="id != '' ? true : false" style="width: 100%;" formControlName="clientId" placeholder="Select client"
                            [ngClass]="{ 'is-invalid': submitted && f.clientId.errors }" bindLabel="fullName" bindValue="id"
                            [items]="allClients">
                        </ng-select>
                        <div *ngIf="submitted && f.clientId.errors" style="margin-left: 20px;" class="invalid-feedback">
                            <div *ngIf="f.clientId.errors.required">client is required
                            </div>
                        </div>
                    </div>
                    <div [hidden]="f.paymentType.value != 'ACH'">
                        <div fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                            <label for="address" style="margin-right: 20px; min-width: 105px;">ACH Account Name</label>
                            <input type="text" class="form-control" formControlName="achAccountName"
                                [ngClass]="{ 'is-invalid': submitted && f.achAccountName.errors }" placeholder="Name on the customer's ACH account">
                            <div *ngIf="submitted && f.achAccountName.errors" class="invalid-feedback">
                                <div *ngIf="f.achAccountName.errors.required">ACH account name is required</div>
                            </div>
                        </div>
                        <div fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                            <label for="address" style="margin-right: 20px; min-width: 105px;">Bank Account Number</label>
                            <input type="text" [attr.disabled]="id != '' ? true : null" class="form-control" formControlName="bankAccountNumber"
                                [ngClass]="{ 'is-invalid': submitted && f.bankAccountNumber.errors }" ng-pattern="/^[0-9]*$/"
                                placeholder="Customer's bank account number">
                            <div *ngIf="submitted && f.bankAccountNumber.errors" class="invalid-feedback">
                                <div *ngIf="f.bankAccountNumber.errors.required">Bank account number is required</div>
                            </div>
                        </div>
                        <div fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                            <label for="address" style="margin-right: 20px; min-width: 105px;">Bank Routing Number</label>
                            <input type="text" [attr.disabled]="id != '' ? true : null" class="form-control" formControlName="bankRoutingNumber"
                                [ngClass]="{ 'is-invalid': submitted && f.bankRoutingNumber.errors }" ng-pattern="/^[0-9]*$/"
                                placeholder="Customer's bank routing number">
                            <div *ngIf="submitted && f.bankRoutingNumber.errors" class="invalid-feedback">
                                <div *ngIf="f.bankRoutingNumber.errors.required">Bank routing number is required</div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="f.paymentType.value !== 'Card'" fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                        <label for="cardNumber" style="min-width: 105px; margin-right: 20px;">Card Number</label>
                        <input type="text" [attr.disabled]="id != '' ? true : null" class="form-control" formControlName="cardNumber" mask="0000-0000-0000-0000"
                            [ngClass]="{ 'is-invalid': submitted && f.cardNumber.errors }" placeholder="xxxx xxxx xxxx xxxx">
                        <div *ngIf="submitted && f.cardNumber.errors" style="margin-left: 20px;" class="invalid-feedback">
                            <div *ngIf="f.cardNumber.errors.required">card number is required</div>
                        </div>
                    </div>
                    <div [hidden]="f.paymentType.value !== 'Card'" fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                        <label for="expiry" style="margin-right: 20px; min-width: 105px;">Expiry</label>
                        <input type="tel" style="max-width: 100px; margin-right: 10px;" class="form-control" formControlName="expiry" mask="00/00" placeholder="MM/YY"
                            [ngClass]="{ 'is-invalid': submitted && f.expiry.errors }">
                        <div *ngIf="submitted && f.expiry.errors" style="margin-right: 10px;" class="invalid-feedback">
                            <div *ngIf="f.expiry.errors.required">expiry date is required</div>
                        </div>
                        <label for="cvv" style="margin-right: 10px;">CVV</label>
                        <input type="tel" class="form-control" formControlName="cvv"
                            [ngClass]="{ 'is-invalid': submitted && f.cvv.errors }" placeholder="Enter credit card cvv number">
                        <div *ngIf="submitted && f.cvv.errors" style="margin-left: 10px;" class="invalid-feedback">
                            <div *ngIf="f.cvv.errors.required">cvv number is required</div>
                        </div>
                    </div>
                    <div fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                        <label for="address" style="margin-right: 20px; min-width: 105px;">Address</label>
                        <input type="text" class="form-control" formControlName="address"
                            [ngClass]="{ 'is-invalid': submitted && f.address.errors }" placeholder="Enter address">
                        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                            <div *ngIf="f.address.errors.required">address is required</div>
                        </div>
                    </div>
                    <div fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                        <label for="city" style="margin-right: 20px; min-width: 105px;">City</label>
                        <input type="text" class="form-control" formControlName="city"
                            [ngClass]="{ 'is-invalid': submitted && f.city.errors }" placeholder="Enter city">
                        <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                            <div *ngIf="f.city.errors.required">city is required</div>
                        </div>
                    </div>
                    <div fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                        <label for="state" style="margin-right: 20px; min-width: 105px;">State</label>
                        <input type="text" style="margin-right: 10px;" class="form-control" formControlName="state"
                            [ngClass]="{ 'is-invalid': submitted && f.state.errors }" placeholder="Enter state">
                        <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                            <div *ngIf="f.state.errors.required">state is required</div>
                        </div>
                        <label for="zip" style="margin-right: 10px;">Zip</label>
                        <input type="text" class="form-control" formControlName="zip"
                            [ngClass]="{ 'is-invalid': submitted && f.zip.errors }" placeholder="Enter zip">
                        <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                            <div *ngIf="f.zip.errors.required">required</div>
                        </div>
                    </div>
                    <div fxLayout="row" style="margin-bottom: 10px;" fxLayoutAlign="start center">
                        <label for="nickname" style="margin-right: 20px; min-width: 105px;">Nickname</label>
                        <input type="text" class="form-control" formControlName="nickname"
                            [ngClass]="{ 'is-invalid': submitted && f.nickname.errors }" placeholder="Enter nickname">
                        <div *ngIf="submitted && f.nickname.errors" style="margin-left:20px;" class="invalid-feedback">
                            <div *ngIf="f.nickname.errors.required">nickname is required</div>
                        </div>
                    </div>
                </div>
                <div [hidden]="f.paymentType.value != 'Card'" fxLayout="column" fxLayoutAlign="start center">
                    <p style="padding-left: 20px; padding-right: 20px;">Franchise Focused Marketing accepts all major credit cards including:</p>
                    <img style="max-width: 125px;" src="../../../../assets/images/MasterCard_Logo.svg.png" alt="Image Alt Text">
                    <img style="max-width: 125px;" src="../../../../assets/images/VISA-logo.png" alt="Image Alt Text">
                    <img style="max-width: 125px;" src="../../../../assets/images/Discover-logo.png" alt="Image Alt Text">
                    <img style="max-width: 125px;" src="../../../../assets/images/American-Express-logo.png" alt="Image Alt Text">
                </div>
                <div [hidden]="f.paymentType.value != 'ACH'" fxLayout="column" fxLayoutAlign="start center">
                    <p style="padding-left: 20px; padding-right: 20px;">To pay directly from your bank account, fill out the information on this page.</p>
                    <img style="max-width: 125px;padding-top:50px" src="../../../../assets/images/achprocess.png" alt="Image Alt Text">
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="cursor: move; border-top: 1px solid #e9e9ef; padding: 16px; background-color: #EBE9EB;" cdkDragHandle>
            <button type="button" class="btn btn-orange" (click)="activeModal.close('Close click')">Cancel</button>
            <button *ngIf="doWhat.canCreate || doWhat.canWrite" type="submit" class="orange-button">SAVE</button>
        </div>
    </form>
</div>