import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { SortablejsModule } from 'ngx-sortablejs'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatatableModule } from '../../shared/components/datatable/datatable.module';
import { P_AppDateFormat } from '../../shared/shared.pipe';
import { NotesModule } from '../../shared/components/notes/notes.module';
import { SharedModule } from '../../shared/shared.module';
import { ProductTileComponent } from './product-tile/product-tile.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { ShopRoutingModule } from './shop-routing.module';
import { ShopApiService } from './shop.apiservice';
import { CartWizardComponent } from './cart-wizard/cart-wizard.component';
import { ProductInputComponent } from './product-input/product-input.component';
import { OrderCompleteComponent } from './order-complete/order-complete.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    ProductTileComponent,
    ProductsListComponent,
    CartWizardComponent,
    ProductInputComponent,
    OrderCompleteComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    DragDropModule,
    MatCardModule,
    SortablejsModule,
    MatCheckboxModule,
    NgSelectModule,
    DatatableModule,
    NotesModule,
    SharedModule,
    ShopRoutingModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule
  ],
  providers: [ShopApiService, P_AppDateFormat]
})
export class ShopModule { }
