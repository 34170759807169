<div class="row" style="margin-right: 0px !important; margin-left: 0px !important;">
    <div class="col-md-12" style="background-color: white; padding: 8px 16px;">
        <div fxLayout="row" fxLayoutAlign="end center" style="padding-bottom: 16px;">
            <button class="blue-button" style="float: right;" type="button" (click)="onOpenTag()">
                ADD TAG
            </button>
            <div class="text-md-right float-md-end pagination-rounded">
                <ngb-pagination [maxSize]="1" [rotate]="true" [ellipses]="true"
                    [collectionSize]="tagsData.length" [page]="pageNumber" [boundaryLinks]="true"
                    (pageChange)="onPageChange($event)" [pageSize]="pageSize">
                </ngb-pagination>
            </div>
        </div>
        <!-- <h1 style="letter-spacing: 0.2rem;
        font-family: 'Gotham-Book'; margin: 20px 0px 10px 10px;">TAGS</h1> -->
        <table class="table">
            <thead style="border-bottom: 2px solid #e9e9ef;">
                <th style="width: 15%;">Name</th>
                <th>Description</th>
                <th>Categories</th>
                <th>Actions</th>
            </thead>
            <tbody>
                <tr *ngIf="tagsData.length==0">
                    <td style="text-align: center;" colspan="4">
                        No data found
                    </td>
                </tr>
                <tr *ngFor="let tag of tempTagsData; let i=index">
                    <td class="color-anchor">
                        {{tag.name}}
                    </td>
                    <td>
                        {{tag.description}}
                    </td>
                    <td>
                        {{tag.categories}}
                    </td>
                    <td>
                        <a *ngIf="iCanWrite" class="a-btn" style="margin-right: 8px;" [routerLink]=""
                            (click)="onDeleteTag(tag.id);$event.stopPropagation();">
                            <i-feather name="x"></i-feather>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div fxLayout="row" fxLayoutAlign="end center">
            <div class="text-md-right float-md-end pagination-rounded">
                <ngb-pagination [maxSize]="1" [rotate]="true" [ellipses]="true"
                    [collectionSize]="tagsData.length" [page]="pageNumber" [boundaryLinks]="true"
                    (pageChange)="onPageChange($event)" [pageSize]="pageSize">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>