import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { IPermission } from '../../../core/models/auth.models';
import { ICampaign } from '../../../core/models/campaigns.model';
import { IReportingPeriod } from '../../../core/models/reporting.models';
import { AppService } from '../../../store/app.service';
import { ReportsApiService } from '../reports.apiservice';

@Component({
  selector: 'app-reports-raw-campaigns-data',
  templateUrl: './reports-raw-campaigns-data.component.html',
  styleUrls: ['./reports-raw-campaigns-data.component.scss']
})
export class ReportsRawCampaignsDataComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;
  columns = [
    { title: 'Name', field: 'name', sortable: true, searchable: true },
    { title: 'Client', field: 'clientName', sortable: true, searchable: true },
    { title: 'Platform', field: 'platform', sortable: true, searchable: true },
    { title: 'DMS', field: 'dmsName', sortable: true, searchable: true },
    { title: 'Stage', field: 'stageId', sortable: true, pipe: 'P_CampaignStageTitle', searchable: true },
    { title: 'Active Subscription', field: 'subscriptionActive', pipe: 'P_Boolean', searchable: true },
  ]
  campaignsData: Array<ICampaign>
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private fb: FormBuilder,
    private reportsService: ReportsApiService,) {
    this.campaignsData = new Array<ICampaign>();
  }

  reportForm: FormGroup = this.fb.group({
    key: [null, [Validators.required]],
    startDate: [null, [Validators.required]],
    stopDate: [null, [Validators.required]],
  });
  get startDate() { return this.reportForm.get('startDate') as FormControl; }
  get stopDate() { return this.reportForm.get('stopDate') as FormControl; }

  ngOnInit(): void {
    this.appService.setPageTitle$('Reports');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Reports);
    this.appService.getAllCampaigns$()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: Array<ICampaign>) => {
        this.campaignsData = [...response];
        this.campaignsData = this.campaignsData.filter(f => f.platform == 'Google');
        this.campaignsData = this.campaignsData.filter(f => f.platformAccountId || f.platformCampaignId);
      });
    const savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
    this.appService.fetchAllCampaigns({ SavedSearchId: savedSearchId });

    this.getSupportedReportingPeriods();
  }

  viewReportOnNewTab(evt: any) {
    this.validateFormAndOpen(evt, true);
  }

  reportformsubmitted = false;
  DateStart = '' as string | null;
  DateStop = '' as string | null;
  validateFormAndOpen(evt: any, isExternal: boolean): void {
    this.reportformsubmitted = true;
    if (this.reportForm.invalid)
      return;

    if (this.reportForm.touched == true) {
      this.DateStart = this.startDate.value;
      this.DateStop = this.stopDate.value;
    }

    let campaign = this.campaignsData.find(f => f.id == evt.id);

    let AccountID = campaign?.platformAccountId;
    let CampaignID = campaign?.platformCampaignId;
    let Platform = campaign?.platform;

    if (AccountID) AccountID = AccountID.replace(/\ /g, '').replace(/\-/g, '');

    let params = { accountId: AccountID, campaignId: CampaignID, platform: Platform, dateStart: this.DateStart, dateStop: this.DateStop };
    // console.log(this.DateStart, this.DateStop);
    // console.log(this.reportForm);
    if (AccountID || CampaignID) {
      if (isExternal) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/', 'dashboard', 'raw-campaigns-data'], { queryParams: params })
        );
        window.open(environment.apiBaseUrl + url, '_blank');
      }
      else {
        this.router.navigate(['/', 'dashboard', 'raw-campaigns-data'], { queryParams: params });
      }
    }
    else {
      Swal.fire(
        'Cannot view report!',
        'Please update selected campaign and set either platform campaign/account id.',
        'error'
      )
    }
  }

  reportPeriods: Array<IReportingPeriod> = [];
  getSupportedReportingPeriods() {
    this.reportsService.getSupportedReportingPeriods()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: Array<IReportingPeriod>) => {
        // console.log(response);
        this.reportPeriods = response;

        this.onReportPeriodChange("Past Week");
      });
  }

  get selectedReportPeriod(): string | undefined | null { return this.reportForm?.controls?.key?.value; }
  get datesReadonly(): boolean { return !(this.selectedReportPeriod == 'Custom'); }
  onReportPeriodChange(selectedPeriod: string) {
    let period = this.reportPeriods.find(f => f.key == selectedPeriod);

    // console.log(period);

    this.DateStart = period?.startDate as string;
    this.DateStop = period?.stopDate as string;

    this.reportForm = this.fb.group({
      key: [selectedPeriod, [Validators.required]],
      startDate: [this.DateStart, [Validators.required]],
      stopDate: [this.DateStop, [Validators.required]],
    });
  }
}