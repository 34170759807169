<div class="modal-box" cdkDrag>
  <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div class="modal-header" style="cursor: move; justify-content: space-between;" cdkDragHandle>
      <h4 class="modal-title">{{ !(ReportingConfig?.id) || ReportingConfig.id === '' ?
        'Add' :'Edit'}} Reporting Config</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row mb-2">
        <label class="col-sm-4" for="campaignId">Client</label>
        <div class="col-sm-8">
          <input type="text" class="form-control"
            value="{{ReportingConfig.client?.firstName}} {{ReportingConfig.client?.lastName}}" readonly>
        </div>
      </div>

      <div class="row mb-2">
        <label class="col-sm-4" for="subActive">To Email</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="toEmail"
            [ngClass]="{ 'is-invalid': submitted && f.toEmail.errors }">
          <div *ngIf="submitted && f.toEmail.errors" class="invalid-feedback">
            <div *ngIf="f.toEmail.errors.required">Valid email is required.</div>
            <div *ngIf="f.toEmail.errors.email">Please enter a valid email.</div>
          </div>
        </div>
      </div>
      <div class="row mb-0">
        <label class="col-sm-4" for="subActive">CC Emails</label> 
        <div class="col-sm-8 px-0" formArrayName="rmReportingConfigCcemails">
          <ul class="collection col-12 row s6">
            <li *ngFor="let cc of rmReportingConfigCcemails.controls; let i=index" class="collection-item col-md-6 px-0">
              <span [formGroupName]="i">
                <div class="row mt-1">
                  <div class="col col-10 p-0">
                    <input type="text" class="form-control" formControlName="ccEmail"
                      [ngClass]="{ 'is-invalid': submitted && cc.get('ccEmail')?.errors }">
                    <div *ngIf="submitted && cc.get('ccEmail')?.errors" class="invalid-feedback">
                      <div *ngIf="cc.get('ccEmail')?.errors?.required">Valid email is required.</div>
                      <div *ngIf="cc.get('ccEmail')?.errors?.email">Please enter a valid email.</div>
                    </div>
                  </div>
                  <div class="col col-1 p-0 pr-3">
                    <a class="a-btn text-danger" style="cursor: pointer;" (click)="removeReportingCC(i)">
                      <i-feather name="trash-2" style="margin-top:5px;"> </i-feather>
                    </a>
                  </div>
                </div>
              </span>
            </li> 
          </ul>

          <a class="a-btn ms-1" style="cursor: pointer;" (click)="addNewCC()">
            <i-feather name="plus"> </i-feather>
          </a>
        </div> 
      </div>

      <div class="row mb-2">
        <label class="col-sm-4" for="name">Is Rollup</label>
        <div class="col-sm-8">
          <input type="checkbox" formControlName="isRollup" class="form-check-input" />
        </div>
      </div>

      <div class="row mb-2">
        <label class="col-sm-4" for="name">Report type</label>
        <div class="col-sm-8">
          <div class="radio-inline">
            <label class="radio-label">
              <input type="radio" value="Simple" formControlName="reportType" (click)="clearEditFormCampaigns()"
                [attr.disabled]="!(ReportingConfig?.id) || ReportingConfig.id === '' ? null : true"
                [ngClass]="{ 'is-invalid': submitted && f.reportType.errors}">
              <span> Simple</span>
            </label>
            &nbsp; &nbsp;
            <label class="radio-label">
              <input type="radio" value="Advanced" formControlName="reportType" (click)="clearEditFormCampaigns()"
                [attr.disabled]="!(ReportingConfig?.id) || ReportingConfig.id === '' ? null : true"
                [ngClass]="{ 'is-invalid': submitted && f.reportType.errors }">
              <span> Advanced</span>
            </label>
          </div>
          <div *ngIf="submitted && f.reportType?.errors" style="font-size: 80%; color: #fd625e;">
            <div *ngIf="f.reportType?.errors?.required">Report type is required.</div>
          </div>
        </div>
      </div>

      <div class="row mb-3"
        *ngIf="f.reportType.value === 'Simple' || getEditFormCampaignsByPlatform('Facebook').length >= 0 || getEditFormCampaignsByPlatform('Google').length >= 0">
        <label class="col-sm-4" for="name" *ngIf="f.reportType.value === 'Advanced'">Add Campaigns. </label>
        <label class="col-sm-4" for="name" *ngIf="f.reportType.value === 'Simple'">Add platform campaigns.</label>
        <div class="col col-8" *ngIf="f.reportType.value">

          <ng-container *ngFor="let platform of ['Facebook', 'Google']">
            <app-client-reporting-update-select-campaigns-modal
              *ngIf="f.reportType.value === 'Simple' || getEditFormCampaignsByPlatform(platform).length >= 0"
              [ReportType]="f.reportType.value" [Platform]="platform" [CampaignsData]="CampaignsData"
              (oSaveClicked)="addCampaignToConfig($event)" [CurrentlySelected]="getEditFormCampaignsByPlatform(platform)">
            </app-client-reporting-update-select-campaigns-modal>
          </ng-container>

        </div>


        <div class="col col-8" *ngIf="submitted && f.rmReportingConfigCampaigns?.errors"
          style="font-size: 80%; color: #fd625e;">
          <div *ngIf="f.rmReportingConfigCampaigns?.errors?.required">Please add at least 1 (one) campaign.</div>
        </div>
      </div>

      <div formArrayName="rmReportingConfigCampaigns" class="row">
        <div class="col col-md-6 px-1" *ngFor="let crc of rmReportingConfigCampaigns.controls; let i=index">
          <div class="card">
            <div class="card-body bg-light text-black" [formGroupName]="i">

              <div class="row">
                <label class="col col-5 px-1">Platform</label>
                <div class="col col-7">
                  <h6 class="card-subtitle mb-2 text-muted">{{ crc.get('platform')?.value }}</h6>
                </div>
              </div>

              <div class="row">
                <label class="col col-5 px-1">Campaign Name</label>
                <div class="col col-7">
                  <h6 class="card-subtitle mb-2 text-muted">{{ crc.get('campaignName')?.value }}</h6>
                </div>
              </div>
              
              <div class="row">
                <label class="col col-8 px-1">Ad Account Info ({{ crc.get('platform')?.value }}):</label>
                <div class="">
                    <table id=adAcccounts style="width: 100%;" *ngIf="crc.get('adAccounts')?.value.length != 0">
                        <tr>
                          <td style="width: 35%;"><strong>Ad Account ID</strong></td>
                          <td style="width: 35%;"><strong>Ad Campaign ID</strong></td>
                          <td style="width: 30%;"><strong>Ad Label</strong></td>
                        </tr>
                        <tr *ngFor="let ad of crc.get('adAccounts')?.value;">
                          <td>{{ad.platformAccountID}}</td>
                          <td>{{ad.platformCampaignID}}</td>
                          <td>{{ad.adLabel}}</td>
                        </tr>
                    </table>
                  </div>
              </div>

              <!-- <div class="row">
                <label class="col col-5 px-1" style="padding-right: 0 !important;">Platform Campaign Id</label>
                <div class="col col-7">
                  <h5 class="card-title">{{ crc.get('platformCampaignId')?.value }}</h5>
                </div>
              </div>

              <div class="row">
                <label class="col col-5 px-1" style="padding-right: 0 !important;">Platform Account Id</label>
                <div class="col col-7">
                  <h5 class="card-title">{{ crc.get('platformAccountId')?.value }}</h5>
                </div>
              </div> -->

              <div class="row">
                <label class="col col-5"></label>
                <div class="col col-7">
                  <a class="a-btn text-danger" style="cursor: pointer;" (click)="removeReportingConfigCampaign(i)">
                    <i-feather name="trash-2" style="margin-top:5px;"> </i-feather>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between; align-content: center; align-items: center; flex-wrap: wrap;" cdkDragHandle>
      <button type="button" class="btn btn-orange" (click)="activeModal.close('Close click')">Cancel</button>
      <button type="submit" class="blue-button">Save</button>
    </div>
  </form>
</div>