import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROLES } from '../core/helpers/constants';
import { PageNotFoundComponent } from '../shared/components/404/404.component';
import { CampaignDetailsComponent } from './campaigns/campaign-details/campaign-details.component';
import { CampaignsMainComponent } from './campaigns/campaigns-main/campaigns-main.component';
import { EditCampaignComponent } from './campaigns/edit-campaign/edit-campaign.component';
import { ClientDetailsComponent } from './clients/client-details/client-details.component';
import { ClientsMainComponent } from './clients/clients-main/clients-main.component';
import { ProspectDetailsComponent } from './clients/prospect-details/prospect-details.component';
import { ProspectsMainComponent } from './clients/prospects-main/prospects-main.component';
import { CompanyMainComponent } from './companies/company-main/company-main.component';
import { CouponsMainComponent } from './coupons/coupons-main/coupons-main.component';
import { EditCouponComponent } from './coupons/edit-coupon/edit-coupon.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { LeadsDetailsComponent } from './leads/leads-details/leads-details.component';
import { LeadsMainComponent } from './leads/leads-main/leads-main.component';
import { SearchesMainComponent } from './searches/searches-main/searches-main.component';
import { EditTagComponent } from './tags/edit-tag/edit-tag.component';
import { TagsMainComponent } from './tags/tags-main/tags-main.component';
import { TasksListComponent } from './tasks/tasks-list/tasks-list.component';
import { UsersMainComponent } from './users/users-main/users-main.component';
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'clients',
    component: ClientsMainComponent
  },
  {
    path: 'prospects',
    component: ProspectsMainComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code, ROLES.Staff.Code] }
  },
  {
    path: 'prospect-details/:id',
    component: ProspectDetailsComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code, ROLES.Staff.Code] }
  },
  {
    path: 'client-details/:id',
    component: ClientDetailsComponent
  },
  {
    path: 'campaigns',
    component: CampaignsMainComponent
  },
  {
    path: 'edit-campaign',
    component: EditCampaignComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code] }
  },
  {
    path: 'edit-campaign/:id',
    component: EditCampaignComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code] }
  },
  {
    path: 'campaign-details/:id',
    component: CampaignDetailsComponent
  },
  {
    path: 'global-search/:keyword',
    component: GlobalSearchComponent,
  },
  {
    path: 'tags',
    component: TagsMainComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code, ROLES.Staff.Code] }
  },
  {
    path: 'edit-tag',
    component: EditTagComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code] }
  },
  {
    path: 'edit-tag/:id',
    component: EditTagComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code] }
  },
  {
    path: 'companies',
    component: CompanyMainComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code, ROLES.Staff.Code] }
  },
  {
    path: 'users',
    component: UsersMainComponent
  },
  {
    path: 'searches',
    component: SearchesMainComponent
  },
  {
    path: 'searches/:id',
    component: SearchesMainComponent
  },
  {
    path: 'tasks',
    component: TasksListComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.SalesRep.Code, ROLES.ShopManager.Code, ROLES.Staff.Code] }
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(mod => mod.ReportsModule)
  },
  {
    path: 'leads',
    component: LeadsMainComponent
  },
  {
    path: 'leads/:id',
    component: LeadsMainComponent
  },
  {
    path: 'lead-details/:id',
    component: LeadsDetailsComponent
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then(mod => mod.ProductsModule),
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code] }
  },
  {
    path: 'coupons',
    component: CouponsMainComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code] }
  },
  {
    path: 'edit-coupon',
    component: EditCouponComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code] }
  },
  {
    path: 'edit-coupon/:id',
    component: EditCouponComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code] }
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./subscriptions/subscription.module').then(mod => mod.SubscriptionModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then(mod => mod.ShopModule)
  },
  {
    path: 'myaccount',
    loadChildren: () => import('./my-account/my-account.module').then(mod => mod.MyAccountModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then(mod => mod.OrdersModule)
  },
  {
    path: 'emails',
    loadChildren: () => import('./emails/emails.module').then(m => m.EmailModule),
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code] }
  },
  {

    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
    data: { roles: [ROLES.Admin.Code, ROLES.TechServices.Code, ROLES.DMS.Code, ROLES.ShopManager.Code, ROLES.SalesRep.Code] }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
