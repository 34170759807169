import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ReplaySubject } from 'rxjs';
import { ReportsApiService } from '../reports.apiservice';
import { IRollupReportDataResponse } from '../../../core/models/reports.models';
import { ICampaign } from '../../../core/models/campaigns.model';

@Component({
  selector: 'app-reports-rollup-report-view-progress',
  templateUrl: './reports-rollup-report-view-progress.component.html',
  styleUrls: ['./reports-rollup-report-view-progress.component.scss']
})
export class ReportsRollupReportViewProgressComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() iRollupReportDataResponse: IRollupReportDataResponse | null = null;
  @Output() oDataLoadCompleted = new EventEmitter<boolean>();

  Error = '';
 
  get completed(): Array<ICampaign> { return this.iRollupReportDataResponse?.campaigns.filter(f => f.bundle == "Faulted" || f.bundle == "Completed") ?? []; }
  get totalCompleted() { return this.completed.length; }
  get totalCampaigns() { return this.iRollupReportDataResponse?.campaigns ? this.iRollupReportDataResponse?.campaigns?.length : 0; }
  get combinedStatus() { return this.totalCampaigns == 0 ? 0 : 100 * this.totalCompleted / this.totalCampaigns; };

  constructor(private reportsService: ReportsApiService, private router: Router, private ngxService: NgxUiLoaderService) {
  }

  ngOnInit(): void {
    // console.log(this.iRollupReportDataResponse);

    if (this.iRollupReportDataResponse?.orderId) {
      this.startTimer();
    }
  }

  interval: any;
  startTimer() {
    this.interval = setInterval(() => {

      this.getRollupReportProgress();
      this.getRollupReportCampaignsProgress();

      this.ngxService.stopAll();

    }, 1000)
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  onDataLoadCompleted() {
    this.stopTimer();
    this.oDataLoadCompleted.emit(true);

    console.log("data load completed.");
  }

  ngOnDestroy(): void {
    this.stopTimer();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getRollupReportProgress() {
    this.reportsService.getRollupReportProgress(this.iRollupReportDataResponse?.orderId as string).subscribe(
      res => {
        if (res.data.status == 'Completed') {
          this.onDataLoadCompleted();
        }
      },
      error => { /* DoNothing  console.log(error);*/ }
    );
  }

  getRollupReportCampaignsProgress() {
    this.reportsService.getRollupReportCampaignsProgress(this.iRollupReportDataResponse?.orderId as string).subscribe(
      res => {
        //  console.log(res);
        for (var initial of res.data) {
          var campaign = this.iRollupReportDataResponse?.campaigns.find(f => f.id == initial.campaignId);
          if (campaign) {
            if (initial.status == "Faulted" || initial.status == "Completed") {
              campaign.bundle = initial.status;
              campaign.opportunityNotes = initial.message;
            }
            else if (initial.status == "Started" && !(campaign.bundle == "Faulted" || campaign.bundle == "Completed")) {
              campaign.bundle = initial.status;
              campaign.opportunityNotes = initial.message;
            }
          }
        }

        if (this.iRollupReportDataResponse) {
          this.iRollupReportDataResponse.campaigns = this.iRollupReportDataResponse.campaigns.sort((a, b) => a.bundle == 'Started' && b.bundle !== 'Started' ? -1 : 1); 
        }
      },
      error => { /* DoNothing  console.log(error);*/ }
    );
  }


  MathFloor(combinedStatus: number): number {
    return Math.floor(combinedStatus);
  }
  MathRound(combinedStatus: number): number {
    return Math.round(combinedStatus);
  }

}
