<div style="padding: 8px; background-color: white;">
    <form [formGroup]="reportForm">
        <div fxLayout="row wrap" fxLayoutAlign="start center" style="min-height: 70px; background-color: white;">
            <div class="col-auto" style="margin-right: 16px;">
                <label class="mb-2">Please select report period:</label>
            </div>
            <div class="col-auto" style="margin-right: 16px;">
                <select class="form-select mb-2" aria-label="Please select period." (change)="onReportPeriodChange(t.value)"
                    #t>
                    <option selected disabled>Please select period.</option>
                    <option type="button" ngbDropdownItem *ngFor="let p of reportPeriods"
                        [selected]="selectedReportPeriod==p.key">
                        {{ p.key }}
                    </option>
                </select>
            </div>
            <div class="col-auto" style="display: flex; align-items: center;">
                <label class="sr-only" for="startDate">Start</label>
                <input type="date" [readonly]="datesReadonly" formControlName="startDate"
                    class="form-control form-date date-custom mb-2" style="margin-right: 16px;"
                    [ngClass]="{ 'is-invalid': reportformsubmitted && reportForm.controls.startDate.errors }" />
                <div *ngIf="reportformsubmitted && reportForm.controls.startDate.errors" class="invalid-feedback">
                    <div *ngIf="reportForm.controls.startDate.errors.required">Please select period.</div>
                </div>
                <label class="mb-2" style="margin-right: 16px;">-</label>
                <label class="sr-only" for="stopDate">End</label>
                <input type="date" [readonly]="datesReadonly" formControlName="stopDate"
                    class="form-control form-date date-custom mb-2"
                    [ngClass]="{ 'is-invalid': reportformsubmitted && reportForm.controls.stopDate.errors }"
                    formControlName="stopDate" />
                <div *ngIf="reportformsubmitted && reportForm.controls.stopDate.errors" class="invalid-feedback">
                    <div *ngIf="reportForm.controls.stopDate.errors.required">Please select period.</div>
                </div>
            </div>
        </div>
    </form>
    <app-campaign-datatable [iCanRead]="doWhat.canRead" [iCanWrite]="doWhat.canWrite" [iCanDelete]="doWhat.canDelete"
        [iColumns]="columns" [iData$]="campaignsData" [iShowViewAction]="true" [iShowExternalAction]="true"
        [iShowActions]="false" [iShowExport]="false" [iShowSelect]="false" [iShowEditAction]="false"
        [iShowDeleteAction]="false" (oViewRow)="viewReport($event)" (oShowExternal)="viewReportOnNewTab($event)"
        (oLoadDataFromServer)="onLoadingData($event)" [iIsServerLoading]="true" [iTotalCount]="dataTotalCount">
    </app-campaign-datatable>
</div>