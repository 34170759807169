import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ICampaign } from '../../../core/models/campaigns.model';
import { ICompany, ISalesRep } from '../../../core/models/datafeed.models';
import { DataFeedService } from '../../api/datafeed.service';
import { CAMPAIGN_STAGES, PAGE_NAMES, ROLES, ROLES_TYPE } from '../../../core/helpers/constants';
import { IClient } from '../../../core/models/clients.models';
import { AppService } from '../../../store/app.service';
import { getRolesForPage } from '../../../core/helpers/utility';
import { ClientsApiService } from '../../../pages/clients/clients.apiservice';
import { CampaignsApiService } from '../../../pages/campaigns/campaigns.apiservice';

@Component({
  selector: 'app-merge-campaign',
  templateUrl: './merge-campaign.component.html',
  styleUrls: ['./merge-campaign.component.scss']
})
export class MergeCampaignsModal implements OnInit, AfterViewInit, OnDestroy {
  step = 1;
  public CAMPAIGN_STAGES = CAMPAIGN_STAGES;
  public allPlatforms = ['Campaign Consulting', 'Facebook', 'Google', 'TikTok', 'National Add On', 'Other', 'Website', 'YouTube'];
  public defaultCRMNames = [
    { title: 'ClubReady', value: 'clubready' },
    { title: 'MindBody', value: 'mindbody' },
    { title: 'Loyalsnap', value: 'loyalsnap' },
    { title: 'Glofox', value: 'glofox' },
    { title: 'Other', value: 'Other' },
  ];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oMergeComplete = new EventEmitter<any>();
  @Input() set campaignsToMerge(value: Array<ICampaign>) {
    this.campaigns = value;
  }
  campaigns: Array<ICampaign> = [];

  allDms: Array<ISalesRep> = new Array<ISalesRep>();
  allClients: Array<IClient> = new Array<IClient>();

  isAdmin: boolean = false;
  isExternal: boolean = false;
  canDelete: boolean = false;
  canRead: boolean = false;
  canWrite: boolean = false;
  options: any = null;

  isCustomCrmName = false;
  addCampaignForm!: FormGroup;
  submitted = false;
  constructor(
    private dataFeedService: DataFeedService,
    public activeModal: NgbActiveModal,
    private appService: AppService,
    private campaignsService: CampaignsApiService,
    private clientService: ClientsApiService,
    private formBuilder: FormBuilder) {
    this.addCampaignForm = this.formBuilder.group({
      id: [null],
      name: ['', [Validators.required]],
      platform: [''],
      dmsId: ['', [Validators.required]],
      stageId: [null, [Validators.required]],
      clientId: ['', [Validators.required]],
      opportunityNotes: [''],
      crmId: [''],
      crmName: [''],
      street: ['', [Validators.maxLength(200)]],
      city: ['', [Validators.maxLength(200)]],
      state: ['', [Validators.maxLength(200)]],
      zipcode: ['', [Validators.maxLength(10)]],
      locationPhone: ['', [Validators.maxLength(20)]],
      locationEmail: ['', [Validators.email, Validators.maxLength(50)]],
      //nextActionNotes: [''], https://atlastechdev.atlassian.net/browse/REAC-59
      //nextActionDate: [new Date()], https://atlastechdev.atlassian.net/browse/REAC-59
      //nextActionTime: ['00:00'], https://atlastechdev.atlassian.net/browse/REAC-59
      platformCampaignId: [''],
      platformAccountId: [''],
    });

    // this.addClientForm = this.formBuilder.group({
    //   id: [null],
    //   firstName: ['', [Validators.required]],
    //   lastName: ['', [Validators.required]],
    //   brandAddress: [''],
    //   brandId: [null],
    //   dmsId: [''],
    //   salesRepId: [null],
    //   phone: [''],
    //   email: ['', [Validators.required, Validators.email]],
    // });
  }
  get f() { return this.addCampaignForm.controls; }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {

    this.f.crmName.valueChanges
      .pipe(takeUntil(this.destroyed$)
      ).subscribe(value => {
        if (value === "Other") {
          this.f.crmName.setValue('');
          this.isCustomCrmName = true;
        }
      })

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isExternal = u.type == ROLES_TYPE.External;
        var r = getRolesForPage(u.roles, PAGE_NAMES.Campaigns);
        this.isAdmin = r.isAdmin;
        this.canRead = r.canRead;
        this.canWrite = r.canWrite;
        this.canDelete = r.canDelete;
        if (r.options)
          this.options = JSON.parse(r.options!)
      })

    this.dataFeedService.getAllUsersWithRole(ROLES.DMS.Code)
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allDms = [...response.data];
          // this.addClientForm.patchValue({ brandId: response.data[0] });
        }
      });

      this.dataFeedService.getAllClients()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allClients = [...response.data];
          // this.addClientForm.patchValue({ brandId: response.data[0] });
        }
      });
  }

  getStageTitle(id: number){
    return CAMPAIGN_STAGES.find(x => x.value === id)?.title
  }

  setPrimaryRecord(id: string) {
    let selectedCampaign = this.campaigns.filter(f => f.id === id)[0];
    this.addCampaignForm.patchValue(selectedCampaign);
    this.isCustomCrmName = !(this.defaultCRMNames.find(x => x.value === selectedCampaign.crmName));
  }

  refreshValues() {
    //   this.addClientForm.get("firstname").setValue("Saurav")
  }

  updateProspectClientDetails() {
    let clientId = this.f.clientId.value;
    this.f.dmsId.setValue('');
    if (clientId) {
      this.clientService.getClientDetails(clientId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          if (response.data.dmsId)
            this.f.dmsId.setValue(response.data.dmsId);
        });
    }
  }

  onShowDropdown() {
    this.isCustomCrmName = false;
    this.addCampaignForm.patchValue({ crmName: this.defaultCRMNames[0].value })
  }

  onSubmit() {
    Swal.fire({
      title: 'Are you sure you want to merge these records?',
      text: "You won't be able to revert this action!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, merge them!'
    }).then((result) => {
      if (result.isConfirmed) { 

        var primaryCampaign = this.addCampaignForm?.get("id")?.value;
        var payload = this.addCampaignForm.value;
        var campaignsIdsToMerge = this.campaigns.map(m => m.id);

        this.campaignsService.mergeCampaigns(payload, primaryCampaign, campaignsIdsToMerge)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            if (response.good) {
              Swal.fire(
                'Merge successful!',
                'Campaign details have been successfully merged!',
                'success'
              )
 
              this.activeModal.dismiss(response)
              this.oMergeComplete.next(response);
            }
            else{
              Swal.fire(
                'Cannot merge!',
                response.error,
                'error'
              )
            }
          }); 
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  nextStep() { this.step += 1; }
  previousStep() { this.step -= 1; }
}
