import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReplaySubject } from 'rxjs';
import { PRODUCT_TYPES } from '../../../core/helpers/constants';
import { IProductMain } from '../../../core/models/products.models';

@Component({
  selector: 'app-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss']
})

/**
 * Page Title Component
 */
export class ProductTileComponent implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  PRODUCT_TYPES = PRODUCT_TYPES;
  @Input() product: IProductMain = {} as IProductMain;
  constructor(
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }
}
